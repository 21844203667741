import { useTranslation } from "react-i18next";

export const useTranslatedLabel = <T, K extends keyof T>(
  obj: T | undefined,
  field: K
) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  if (!obj) {
    return "";
  }

  let text = obj[field];

  if (language === "si") {
    text = obj[`${String(field)}Si`];
  } else if (language === "ta") {
    text = obj[`${String(field)}Ta`];
  }

  return text;
};
