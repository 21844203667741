import { FullWidthImage } from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { useState } from "react";
import { getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";

const CardWrapper = styled.div`
  /*padding: 10px 10px 12px 10px !important;*/
  border-radius: 16px;
  background: white;
  border: none;
  box-shadow: ${styles.boxShadow};
  margin: 10px 0 0;
  position: relative;
  width: auto;
  cursor: pointer;
  flex: 1;

  @media screen and (min-width: ${styles.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .name {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image-container {
    height: 150px;
    border-radius: 15px;
    overflow: hidden;
  }

  .price {
    color: ${colors.orange};
  }
`;

export interface Item {
  id: number;
  total: number;
  image: string;
  title: string;
  titleSi: string;
  titleTa: string;
}

export const CardProduct = (props: {
  item: Item;
  onClick: (id: number) => void;
}) => {
  const [x] = useState(Math.round(Math.random() * 10));
  const productTitle = useTranslatedLabel(props.item, "title");

  return (
    <CardWrapper onClick={() => props.onClick(props.item.id)}>
      <div className="ourProducts-item">
        <div className="image-container">
          <FullWidthImage src={props.item.image}></FullWidthImage>
        </div>
        <h5 className="name mt-3 mb-0 fw-bold" title={productTitle}>
          {productTitle}
        </h5>
        <p className="price mb-0 fw-bold">
          {getPriceFromNumber(props.item.total)}
        </p>
      </div>
    </CardWrapper>
  );
};
