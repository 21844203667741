import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { Campaign } from "models/Campaign";
import { Product } from "models/Product";
import { AddToCart, Cart } from "models/Cart";
import { Wishlist } from "models/Wishlist";
import { User } from "models/User";
import { ChangePassword } from "models/AuthUser";
import { Address } from "models/Address";
import { CheckoutData } from "models/CheckoutData";
import { Winner } from "models/Winner";

const getTotalForCampaign = (campaign: Campaign) => {
  return campaign.product?.price || 0;
};

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["Wishlist", "Cart", "User", "UserAddresses", "Orders", "SoldOut"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders(headers, { getState }) {
      const token = (getState() as RootState).auth.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchCampaigns: builder.query<Campaign[], void>({
        query() {
          return {
            url: "/campaign/open",
            method: "GET",
          };
        },
        transformResponse: (response: Campaign[]) => {
          return response.map((c) => ({
            ...c,
            total: getTotalForCampaign(c),
          }));
        },
      }),
      fetchSoldOutCampaigns: builder.query<Campaign[], void>({
        query() {
          return {
            url: "/campaign/sold-out",
            method: "GET",
          };
        },
        providesTags: ["SoldOut"],
      }),
      fetchClosingSoonCampaigns: builder.query<Campaign[], void>({
        query() {
          return {
            url: "/campaign/closing-soon",
            method: "GET",
          };
        },
        transformResponse: (response: Campaign[]) => {
          return response.map((c) => ({
            ...c,
            total: getTotalForCampaign(c),
          }));
        },
      }),
      fetchCampaign: builder.query<Campaign, number>({
        query(id) {
          return {
            url: `/campaign/${id}`,
            method: "GET",
          };
        },
        transformResponse: (response: Campaign) => {
          return { ...response, total: getTotalForCampaign(response) };
        },
      }),
      fetchWishlist: builder.query<Wishlist[], void>({
        query() {
          return {
            url: `/wishlist`,
            method: "GET",
          };
        },
        providesTags: ["Wishlist"],
      }),
      addToWishlist: builder.mutation<void, number>({
        query(id) {
          return {
            url: `/wishlist`,
            method: "POST",
            body: {
              campaignId: id,
            },
          };
        },
        invalidatesTags: ["Wishlist"],
      }),
      removeWishlist: builder.mutation<void, number>({
        query(id) {
          return {
            url: `/wishlist`,
            method: "DELETE",
            body: {
              wishlistId: id,
            },
          };
        },
        invalidatesTags: ["Wishlist"],
      }),
      addWishlistedCampaignToCart: builder.mutation<void, number>({
        query(id) {
          return {
            url: `/cart/addWishlistToCart`,
            method: "POST",
            params: {
              wishlistId: id,
            },
          };
        },
        invalidatesTags: ["Wishlist", "Cart"],
      }),
      fetchCart: builder.query<Cart[], void>({
        query() {
          return {
            url: `/cart`,
            method: "GET",
          };
        },
        providesTags: ["Cart"],
      }),
      addToCart: builder.mutation<void, AddToCart>({
        query(payload) {
          return {
            url: `/cart/`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: (result, err) => (err ? [] : ["Cart"]),
      }),
      removeFromCart: builder.mutation<void, number>({
        query(id) {
          return {
            url: `/cart/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, err) => (err ? [] : ["Cart"]),
      }),
      fetchProducts: builder.query<Product[], void>({
        query() {
          return {
            url: `/product`,
            method: "GET",
          };
        },
      }),
      fetchUserDetails: builder.query<User, void>({
        query() {
          return {
            url: `/user`,
            method: "GET",
          };
        },
        providesTags: ["User"],
      }),
      updateUserDetails: builder.mutation<{ phoneChanged: boolean }, User>({
        query(user) {
          return {
            url: `/user`,
            method: "PUT",
            body: user,
          };
        },
        invalidatesTags: ["User"],
      }),
      changeUserPassword: builder.mutation<void, ChangePassword>({
        query(payload) {
          return {
            url: `/auth/password-change`,
            method: "PUT",
            body: payload,
          };
        },
      }),
      fetchUserAddresses: builder.query<Address[], void>({
        query() {
          return {
            url: `/user/addresses`,
            method: "GET",
          };
        },
        providesTags: ["UserAddresses"],
      }),
      addUserAddress: builder.mutation<void, Address>({
        query(payload) {
          return {
            url: `/user/address`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["UserAddresses"],
      }),
      updateUserAddress: builder.mutation<void, Address>({
        query(payload) {
          return {
            url: `/user/address`,
            method: "PUT",
            body: payload,
          };
        },
        invalidatesTags: ["UserAddresses"],
      }),
      deleteUserAddress: builder.mutation<void, number>({
        query(payload) {
          return {
            url: `/user/address/${payload}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["UserAddresses"],
      }),
      uploadProfileImage: builder.mutation<void, FormData>({
        query(payload) {
          return {
            url: `/user/profile-image`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["User"],
      }),
      processCheckout: builder.mutation<CheckoutData, void>({
        query(payload) {
          return {
            url: `/order`,
            method: "POST",
          };
        },
      }),
      fetchWinners: builder.query<Winner[], void>({
        query() {
          return {
            method: "GET",
            url: "/ticket/winners",
          };
        },
      }),
    };
  },
});

export const {
  useFetchCampaignsQuery,
  useFetchSoldOutCampaignsQuery,
  useFetchClosingSoonCampaignsQuery,
  useFetchCampaignQuery,
  useFetchWishlistQuery,
  useAddToWishlistMutation,
  useRemoveWishlistMutation,
  useFetchCartQuery,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useFetchProductsQuery,
  useFetchUserDetailsQuery,
  useAddWishlistedCampaignToCartMutation,
  useChangeUserPasswordMutation,
  useFetchUserAddressesQuery,
  useUpdateUserDetailsMutation,
  useAddUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
  useUploadProfileImageMutation,
  useProcessCheckoutMutation,
  useFetchWinnersQuery,
} = apiSlice;
