import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { colors } from "./variables";

const StyledDropdownMenu = styled.a<{ placeholderVisible: boolean }>`
  text-decoration: none;
  &:hover {
    color: ${(props) =>
      props.placeholderVisible ? colors.lightGrey : "unset !important"};
  }

  ${({ placeholderVisible }) =>
    placeholderVisible &&
    `
     color: ${colors.lightGrey}
  `}
`;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef<
  HTMLAnchorElement,
  React.HTMLProps<HTMLAnchorElement> & { placeholderVisible: boolean }
>(({ children, onClick, placeholderVisible }, ref) => (
  <StyledDropdownMenu
    placeholderVisible={placeholderVisible}
    className="form-control form-dropdown-toggle-button"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick && onClick(e);
    }}
  >
    {children}
  </StyledDropdownMenu>
));

interface CustomMenuProps {
  children: React.ReactNode;
  style: React.CSSProperties | undefined;
  className: string | undefined;
  "aria-labelledby": string | undefined;
  searchText: string;
  setSearchText: (text: string) => void;
}

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
  (
    {
      "aria-labelledby": labeledBy,
      children,
      className,
      style,
      searchText,
      setSearchText,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

export const StyleDropdownListWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;

  .loading-indicator {
    font-weight: 600;
    background-color: #8080800f;
  }
`;

export interface DropdownItem {
  id: string | number;
  text: string;
}

export interface DropdownSearchProps {
  items: DropdownItem[];
  selectedItem: DropdownItem | null;
  searchText: string;
  count: number;
  hasMore: boolean;
  placeholder: string;
  fetchNext: (page: number) => void;
  setSelectedItem: (item: DropdownItem) => void;
  setSearchText: (text: string) => void;
  onChange: (item: DropdownItem | null) => void;
  onBlur: () => void;
}

export const DropdownSearch = React.forwardRef<
  HTMLDivElement,
  DropdownSearchProps
>((props, ref) => {
  const [page, setPage] = useState(0);

  const fetchData = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    props.fetchNext(nextPage);
  };

  const onSelectItem = (item: DropdownItem, event) => {
    props.setSelectedItem(item);
    props.onChange(event);
  };

  const onSearchTermChange = (term: string) => {
    props.setSearchText(term);
  };

  const onVisibilityChange = (show: boolean) => {
    if (!show) {
      props.onBlur();
    }
  };

  return (
    <Dropdown onToggle={(e) => onVisibilityChange(e)}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        placeholderVisible={!props.selectedItem?.text}
      >
        {props.selectedItem?.text || props.placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        setSearchText={onSearchTermChange}
        searchText={props.searchText}
      >
        <StyleDropdownListWrapper id="dropdown-list-wrapper">
          <InfiniteScroll
            dataLength={props.items?.length}
            next={fetchData}
            hasMore={props.hasMore} // Replace with a condition based on your data source
            loader={
              <p className="loading-indicator dropdown-item">Loading...</p>
            }
            endMessage={
              <p className="loading-indicator dropdown-item">
                No more data to load.
              </p>
            }
            scrollableTarget="dropdown-list-wrapper"
          >
            {props.items.map((item) => (
              <Dropdown.Item
                key={item.id}
                eventKey={item.id}
                onClick={(event) => onSelectItem(item, event)}
              >
                {item.text}
              </Dropdown.Item>
            ))}
          </InfiniteScroll>
        </StyleDropdownListWrapper>
      </Dropdown.Menu>
    </Dropdown>
  );
});
