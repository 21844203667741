import { useAppDispatch } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as Eye } from "assets/images/remove_red_eye.svg";
import { passwordStrength } from "check-password-strength";
import { apiSlice } from "features/api-slice";
import { loginUser, registerUser } from "features/auth/auth-slice";
import { UserRegistration } from "models/AuthUser";
import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  StyledButton,
  StyledModal,
} from "./common-styled-components/styled-components";
import { colors } from "./variables";
import { VerifyOTPComponent } from "./verify-otp";
import { ForgotPasswordComponent } from "./forgot-password";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

const ShowPasswordToggle = styled.div<{ isToggled: boolean }>`
  position: absolute;
  top: 0;
  right: 15px;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;

  --eye-color-toggled: #0c16ad;

  svg {
    transition: all 0.1s linear;

    ${(props) =>
      props.isToggled
        ? `
          g {
            fill: var(--eye-color-toggled);
        }

        path {
            stroke: var(--eye-color-toggled);
            fill: transparent;
        }        
        `
        : ``}
  }
`;

const StyledInput = styled(InputGroup)`
  .dropdown-toggle {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-right: none;
    border-color: #ced4da;
    background-color: ${colors.white};
    color: ${colors.black};
  }

  .form-control {
    border-left: none;

    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
`;

const StyledAuthWrapper = styled.div`
  position: relative;
  h2 {
    font-weight: 500;
    font-family: "Rubik", sans-serif;
    font-size: 1.6rem;
  }

  p {
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    margin: 0;
    padding: 0 0 15px 0;
    line-height: 1.2;
    font-size: 14px;
  }
  input.form-control,
  .input-group button {
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    height: 50px;
  }

  label {
    font-weight: 200;
    font-size: 13px;
    font-family: "Rubik", sans-serif;
  }

  .submit {
    height: 4rem;
  }

  .link {
    color: ${colors.BuyRed};
    cursor: pointer;
    font-size: 13px;
  }

  .link-login {
    text-align: center;
    margin-top: 1rem;
    padding: 0;
    margin: 15px 0 0 0;

    .link {
      font-size: 14px;
    }
  }

  .strength {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      height: 0.45rem;
      width: 20%;
      background-color: ${colors.accent};
      border-radius: 10px;

      &.satisfied {
        background-color: ${colors.lightGreen};
      }
    }
  }
`;

export const AuthModal = (props: {
  isLogin: boolean;
  show: boolean;
  onHide: () => void;
  onToggle: (isLogin: boolean) => void;
}) => {
  const { isLogin, ...modalProps } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { isLoggedIn, error, success, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const [showOTP, setShowOTP] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [registrationPayload, setRegistrationPayload] =
    useState<UserRegistration>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      props.onHide();
    }
  }, [success, isLoggedIn]);

  const onLogin = async (data: UserRegistration) => {
    dispatch(apiSlice.util.invalidateTags(["User"]));
    await dispatch(loginUser(data)).unwrap();
    dispatch(apiSlice.util.invalidateTags(["SoldOut"]));
    props.onHide();
  };

  const onRegister = async (data: UserRegistration) => {
    dispatch(apiSlice.util.invalidateTags(["User"]));
    await dispatch(registerUser(data)).unwrap();
    setRegistrationPayload(data);
    setShowOTP(true);
  };

  const { register, handleSubmit, formState, watch, reset } =
    useForm<UserRegistration>({ mode: "all" });
  useEffect(() => {
    setPasswordVisible(false);
    reset();
  }, [props.isLogin]);

  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const password = watch("password", "");
  useEffect(() => {
    if (!props.isLogin) {
      const ps = passwordStrength(password);

      let combinedStrength = 0;

      if (ps.length >= 8 && ps.id !== 0) {
        combinedStrength = ps.contains.length;
      }

      setPasswordStrengthValue(combinedStrength);
    } else {
      setPasswordStrengthValue(0);
    }
  }, [password]);

  if (showOTP) {
    return (
      <VerifyOTPComponent
        show={true}
        registrationPayload={registrationPayload!}
        onHide={() => {
          // setShowOTP(false);
          // props.onHide();
        }}
      ></VerifyOTPComponent>
    );
  }

  if (showForgotPassword) {
    return (
      <ForgotPasswordComponent
        show={true}
        registrationPayload={registrationPayload!}
        onHide={() => {
          setShowForgotPassword(false);
          props.onHide();
        }}
        onToggle={() => {
          setShowForgotPassword(false);
        }}
      ></ForgotPasswordComponent>
    );
  }

  return (
    <StyledModal
      {...modalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {props.isLogin ? (
          <StyledAuthWrapper>
            {/* Login */}
            <h2>{t(Translations.LogIn)}</h2>
            <p>{t(Translations.CreateAccount)}</p>
            <Form onSubmit={handleSubmit(onLogin)}>
              <Form.Group className="mb-3" controlId="email">
                <StyledFormControl
                  type="email"
                  placeholder={t(Translations.Email)}
                  {...register("emailAddress", { required: true })}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="password"
                style={{ position: "relative" }}
              >
                <StyledFormControl
                  type={passwordVisible ? "text" : "password"}
                  placeholder={t(Translations.Password)}
                  {...register("password", { required: true })}
                />
                <ShowPasswordToggle
                  isToggled={passwordVisible}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <Eye />
                </ShowPasswordToggle>
              </Form.Group>
              <p
                className="link-forgot-password link text-end"
                onClick={() => setShowForgotPassword(true)}
              >
                {t(Translations.ForgotPassword)}
              </p>

              <div className="form-group submit">
                <StyledButton
                  color={colors.white}
                  backgroundColor={colors.colorMain}
                  borderRadius="10px"
                  type="submit"
                  disabled={!formState.isValid}
                >
                  {t(Translations.LogIn)}
                </StyledButton>
              </div>

              <p className="link-login">
                {t(Translations.NoAccount)}{" "}
                <span
                  className="link"
                  onClick={() => {
                    props.onToggle(false);
                  }}
                >
                  {" "}
                  {t(Translations.Register)}{" "}
                </span>
              </p>
            </Form>
          </StyledAuthWrapper>
        ) : (
          props.show && ( // Do not show the form when closing the login form
            <StyledAuthWrapper>
              {/* Register */}
              <h2>{t(Translations.SignUp)}</h2>
              <p>{t(Translations.CreateAccount)}</p>
              <Form onSubmit={handleSubmit(onRegister)}>
                <Form.Group className="mb-3" controlId="f-name">
                  <StyledFormControl
                    type="text"
                    maxLength={100}
                    placeholder={t(Translations.FirstName)}
                    {...register("firstName", {
                      required: true,
                      minLength: 2,
                    })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="l-name">
                  <StyledFormControl
                    type="text"
                    maxLength={100}
                    placeholder={t(Translations.LastName)}
                    {...register("lastName", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <StyledFormControl
                    type="email"
                    placeholder={t(Translations.Email)}
                    {...register("emailAddress", {
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                    })}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="password"
                  style={{ position: "relative" }}
                >
                  <StyledFormControl
                    maxLength={100}
                    type={passwordVisible ? "text" : "password"}
                    placeholder={t(Translations.Password)}
                    {...register("password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                  <ShowPasswordToggle
                    isToggled={passwordVisible}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    <Eye />
                  </ShowPasswordToggle>
                </Form.Group>

                <div
                  className={`strength mb-3 strength-${passwordStrengthValue}`}
                >
                  <div
                    className={`${
                      passwordStrengthValue >= 1 ? "satisfied" : ""
                    }`}
                  ></div>
                  <div
                    className={`${
                      passwordStrengthValue >= 2 ? "satisfied" : ""
                    }`}
                  ></div>
                  <div
                    className={`${
                      passwordStrengthValue >= 3 ? "satisfied" : ""
                    }`}
                  ></div>
                  <div
                    className={`${
                      passwordStrengthValue >= 4 ? "satisfied" : ""
                    }`}
                  ></div>
                </div>

                <StyledInput className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title="(+94)"
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Item href="#">+94</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    type="text"
                    placeholder={t(Translations.PhoneNumber)}
                    maxLength={9}
                    {...register("phone", {
                      pattern: /^\d{9}$/,
                      required: true,
                    })}
                  />
                </StyledInput>

                <Form.Group className="mb-3" controlId="nic">
                  <StyledFormControl
                    type="text"
                    placeholder={t(Translations.NIC)}
                    {...register("nic", {
                      required: true,
                    })}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="terms-checkbox">
                  <Form.Check
                    type="checkbox"
                    label={t(Translations.AgreeToTerms)}
                    {...register("terms", { required: true })}
                  />
                </Form.Group>

                <div className="form-group submit">
                  <StyledButton
                    color={colors.white}
                    backgroundColor={colors.colorMain}
                    borderRadius="10px"
                    disabled={!formState.isValid}
                  >
                    {t(Translations.GetRegistered)}
                  </StyledButton>
                </div>

                <p className="link-login">
                  {t(Translations.AlreadyHaveAccount)}{" "}
                  <span
                    className="link"
                    onClick={() => {
                      props.onToggle(true);
                    }}
                  >
                    {" "}
                    {t(Translations.LogIn)}{" "}
                  </span>
                </p>
              </Form>
            </StyledAuthWrapper>
          )
        )}
      </Modal.Body>
    </StyledModal>
  );
};
