import img1 from "assets/images/home/Slider1.jpg";
import img2 from "assets/images/home/Slider2.jpg";
import img3 from "assets/images/home/Slider3.jpg";
import m_img1 from "assets/images/home/Slider-m1.jpg";
import m_img2 from "assets/images/home/Slider-m2.jpg";
import m_img3 from "assets/images/home/Slider-m3.jpg";
import ad1 from "assets/images/home/add1.jpg";
import ad2 from "assets/images/home/add2.jpg";

import { colors, styles } from "components/variables";
import { Carousel } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import { ClosingSoon } from "./closing-soon";
import { GreatDeals } from "./great-deals";
import { OurProducts } from "./our-products";
import { SoldOut } from "./sold-out";
import { Winners } from "./winners";
import { useFetchClosingSoonCampaignsQuery } from "features/api-slice";
import { FullWidthImage } from "components/common-styled-components/styled-components";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledHomeWrapper = styled.div``;

const SectionTitle = styled.h3`
  text-align: left;
`;

const StyledCarousedContainer = styled.div`
  border-radius: 30px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

const StyledCarouselSlide = styled(Carousel.Item)`
  height: 400px;
  border-radius: 30px;
  overflow: hidden;

  img {
    height: 100%;
    @media screen and (min-width: ${styles.breakpoints.medium}) {
      object-fit: contain;
    }
  }
`;

const StyledAdSection = styled.section`
  gap: 10px;

  .ad {
    border-radius: 20px;
    overflow: hidden;
    flex: 1;

    &:first-child {
      flex: 1 45%;
    }
  }
`;

export const Home = () => {
  const { data: closingSoon = [], isFetching: closingSoonLoading } =
    useFetchClosingSoonCampaignsQuery();
  const { t } = useTranslation();

  return (
    <StyledHomeWrapper>
      <StyledCarousedContainer className="main-home-slider">
        <Carousel className="d-none d-md-block">
          <StyledCarouselSlide>
            <img src={img1}></img>
          </StyledCarouselSlide>
          <StyledCarouselSlide>
            <img src={img2}></img>
          </StyledCarouselSlide>
          <StyledCarouselSlide>
            <img src={img3}></img>
          </StyledCarouselSlide>
        </Carousel>
        <Carousel className="d-block d-md-none">
          <StyledCarouselSlide>
            <img src={m_img1}></img>
          </StyledCarouselSlide>
          <StyledCarouselSlide>
            <img src={m_img2}></img>
          </StyledCarouselSlide>
          <StyledCarouselSlide>
            <img src={m_img3}></img>
          </StyledCarouselSlide>
        </Carousel>
      </StyledCarousedContainer>

      {closingSoon.length > 0 ? (
        <div className="closing-soon mb-3">
          <SectionTitle className="main-title">Closing soon</SectionTitle>
          <div className="carousel-anchor">
            <ClosingSoon campaigns={closingSoon}></ClosingSoon>
          </div>
        </div>
      ) : null}

      <StyledAdSection className="d-none d-md-flex mb-5">
        <div className="ad">
          <FullWidthImage src={ad1}></FullWidthImage>
        </div>
        <div className="ad">
          <FullWidthImage src={ad2}></FullWidthImage>
        </div>
      </StyledAdSection>

      <div className="great-deals mb-5">
        <SectionTitle className="main-title">
          {t(Translations.MoreGreatDeals)}
        </SectionTitle>
        <GreatDeals></GreatDeals>
      </div>

      <div className="sold-out mb-5">
        <SoldOut background={colors.lightRed}></SoldOut>
      </div>

      <div className="our-products mb-5 d-none d-md-block">
        <OurProducts background="transparent"></OurProducts>
      </div>

      <div className="winners mb-5">
        <Winners background={colors.lightPurple}></Winners>
      </div>

      <div className="our-products mb-5 d-block d-md-none">
        <OurProducts background="transparent"></OurProducts>
      </div>
    </StyledHomeWrapper>
  );
};
