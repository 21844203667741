import styled from "styled-components";
import { colors } from "./variables";
import { ReactNode } from "react";

const StyledIcon = styled.div<{
  $isActive?: boolean;
  hoverColor?: string;
  children: React.ReactNode;
}>`
  background-color: ${(props) =>
    props.$isActive ? colors.colorMain : colors.white};
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid ${(props) => props.hoverColor ?? colors.lightRed};
    background-color: ${(props) => props.hoverColor ?? colors.lightRed};
    svg {
      filter: brightness(110);
    }
  }
  svg {
    path {
      stroke: ${(props) => (props.$isActive ? colors.white : "")};
    }
  }
`;

export const Icon = (props: {
  isActive?: boolean;
  hoverColor?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <StyledIcon
      $isActive={props.isActive}
      hoverColor={props.hoverColor}
      onClick={props.onClick}
      className={props.className}
    >
      {props.children}
    </StyledIcon>
  );
};
