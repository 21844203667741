import i18next from "i18next";
import { initReactI18next } from "react-i18next";

export enum Translations {
  NeedHelp = "NeedHelp",
  Register = "Register",
  LogIn = "LogIn",
  MoreGreatDeals = "MoreGreatDeals",
  DealClosingIn = "DealClosingIn",
  SoldOut = "SoldOut",
  SoldOutCampaigns = "SoldOutCampaigns",
  OurProducts = "OurProducts",
  Winners = "Winners",
  WinnersSection = "WinnersSection",
  QuickLinks = "QuickLinks",
  About = "About",
  MyAccount = "MyAccount",
  ActiveDeals = "ActiveDeals",
  CustomerService = "CustomerService",
  ContactUs = "ContactUs",
  FAQs = "FAQs",
  HowItWorks = "HowItWorks",
  Terms = "Terms",
  PrivacyPolicy = "PrivacyPolicy",
  SignUp = "SignUp",
  CreateAccount = "CreateAccount",
  FirstName = "FirstName",
  LastName = "LastName",
  Email = "Email",
  Password = "Password",
  PhoneNumber = "PhoneNumber",
  NIC = "NIC",
  AgreeToTerms = "AgreeToTerms",
  GetRegistered = "GetRegistered",
  AlreadyHaveAccount = "AlreadyHaveAccount",
  Login = "Login",
  ForgotPassword = "ForgotPassword",
  NoAccount = "NoAccount",
  ResetPassword = "ResetPassword",
  ResetPasswordDescription = "ResetPasswordDescription",
  SendLink = "SendLink",
  BackToLogin = "BackToLogin",
  ItemDetails = "ItemDetails",
  AddToCart = "AddToCart",
  MaxClosingDate = "MaxClosingDate",
  Wishlist = "Wishlist",
  Welcome = "Welcome",
  PersonalDetails = "PersonalDetails",
  MobilePhone = "MobilePhone",
  MICPassportNumber = "MICPassportNumber",
  DateOfBirth = "DateOfBirth",
  Gender = "Gender",
  Male = "Male",
  Female = "Female",
  Other = "Other",
  UpdatedProfile = "UpdatedProfile",
  MyWishlist = "MyWishlist",
  Remove = "Remove",
  PaymentsActiveTickets = "PaymentsActiveTickets",
  Settings = "Settings",
  ChangePassword = "ChangePassword",
  CurrentPassword = "CurrentPassword",
  NewPassword = "NewPassword",
  ConfirmNewPassword = "ConfirmNewPassword",
  UpdatePassword = "UpdatePassword",
  Logout = "Logout",
  AddMore = "AddMore",
  Price = "Price",
  InclusiveOfVAT = "InclusiveOfVAT",
  Subtotal = "Subtotal",
  PaymentMethod = "PaymentMethod",
  ClickToPayByCard = "ClickToPayByCard",
  OrderCompletionMessage = "OrderCompletionMessage",
  ConfirmationEmailMessage = "ConfirmationEmailMessage",
  Product = "Product",
  Quantity = "Quantity",
  PurchasedTickets = "PurchasedTickets",
  IssuedOn = "IssuedOn",
  DrawOn = "DrawOn",
  TicketNumber = "TicketNumber",
  AboutUs = "AboutUs",
  TransformedShoppingExperience = "TransformedShoppingExperience",
  WinFantasticPrizes = "WinFantasticPrizes",
  StartShoppingJourney = "StartShoppingJourney",
  ContactUsSection = "ContactUsSection",
  ContactFormDescription = "ContactFormDescription",
  Message = "Message",
  SendMessage = "SendMessage",
  CallUsNow = "CallUsNow",
  WriteUsEmail = "WriteUsEmail",
  VerifyMobileNumber = "VerifyMobileNumber",
  EnterVerificationCode = "EnterVerificationCode",
  Verify = "Verify",
  WeAccept = "WeAccept",
  DidNotBuy = "DidNotBuy",
  YouBought = "You bought this deal",
  DealCoseDate = "DealCoseDate",
}

const resources = {
  en: {
    translation: {
      NeedHelp: "Need help? Contact us",
      Register: "Register",
      LogIn: "Log in",
      MoreGreatDeals: "More great deals",
      DealClosingIn: "DEAL CLOSING IN",
      SoldOut: "Sold Out",
      SoldOutCampaigns:
        "All our sold out campaigns along with their corresponding draw dates are listed here",
      OurProducts: "Our Products",
      Winners: "Winners",
      WinnersSection: "All our winners are announced in this section.",
      QuickLinks: "Quick Links",
      About: "About",
      MyAccount: "My Account",
      ActiveDeals: "Active deals",
      CustomerService: "Customer Service",
      ContactUs: "Contact us",
      FAQs: "FAQs",
      HowItWorks: "How it works",
      Terms: "Terms",
      PrivacyPolicy: "Privacy Policy",
      SignUp: "Sign up",
      CreateAccount:
        "Create your account - enjoy our services with most important features",
      FirstName: "First Name",
      LastName: "Last Name",
      Email: "Email",
      Password: "Password",
      PhoneNumber: "Phone number",
      NIC: "NIC",
      AgreeToTerms: "I agree to the Usage terms and Privacy policy",
      GetRegistered: "Get registered",
      AlreadyHaveAccount: "Already have an account ?",
      Login: "Login",
      ForgotPassword: "Forgot password",
      NoAccount: "Don't have an account?",
      ResetPassword: "Reset Password",
      ResetPasswordDescription:
        "Provide your account email address to receive an email to reset your password.",
      SendLink: "Send Link",
      BackToLogin: "Back to Login",
      ItemDetails: "Item Details",
      AddToCart: "Add to cart",
      MaxClosingDate: "Max closing date",
      Wishlist: "Wishlist",
      Welcome: "Welcome",
      PersonalDetails: "Personal details",
      MobilePhone: "Mobile Phone",
      MICPassportNumber: "MIC / Passport Number",
      DateOfBirth: "Date of Birth",
      Gender: "Gender",
      Male: "Male",
      Female: "Female",
      Other: "Other",
      UpdatedProfile: "Updated Profile",
      MyWishlist: "My wishlist",
      Remove: "Remove",
      PaymentsActiveTickets: "Payments/Active Tickets",
      Settings: "Settings",
      ChangePassword: "Change Password",
      CurrentPassword: "Current Password",
      NewPassword: "New Password",
      ConfirmNewPassword: "Confirm New Password",
      UpdatePassword: "Update password",
      Logout: "Logout",
      AddMore: "Add More",
      Price: "Price",
      InclusiveOfVAT: "Inclusive of VAT",
      Subtotal: "Subtotal",
      PaymentMethod: "Payment Method",
      ClickToPayByCard: "Click to Pay by Credit / Debit Card",
      OrderCompletionMessage:
        "Thank you for Shopping at Lankadealz.com Your order is complete!",
      ConfirmationEmailMessage:
        "You will be receiving a confirmation email with order details",
      Product: "Product",
      Quantity: "Quantity",
      PurchasedTickets: "Purchased Tickets",
      IssuedOn: "Issued on",
      DrawOn: "Draw on",
      TicketNumber: "Ticket No.",
      AboutUs: "About Us",
      TransformedShoppingExperience:
        "We've transformed the online shopping experience!",
      WinFantasticPrizes:
        "Purchase any of our products and stand a chance to win fantastic prizes. Skeptical? Check out our past winners and join the excitement of winning!",
      StartShoppingJourney:
        "Start your shopping journey now and get ready to experience the thrill of incredible deals!",
      ContactUsSection: "Contact Us",
      ContactFormDescription:
        "Kindly fill in the form below, and one of our dedicated team members will contact you within 24 hours.",
      Message: "Message",
      SendMessage: "Send Message",
      CallUsNow: "Call us now",
      WriteUsEmail: "Write us an email",
      VerifyMobileNumber: "Verify Mobile Number",
      EnterVerificationCode:
        "Please enter the 6 digit code sent to your mobile number. The code will expire in 5 minutes",
      Verify: "Verify",
      WeAccept: "We Accept",
      DidNotBuy: "You did not buy this deal",
      YouBought: "You bought this deal",
      DealCoseDate: "Deal closed date",
    },
  },
  si: {
    translation: {
      NeedHelp: "උදව් අවශ්‍යද ? අප අමතන්න",
      Register: "ලියාපදිංචි කරන්න",
      LogIn: "ඇතුළුවන්න",
      MoreGreatDeals: "තවත් අගනා අවස්තා",
      DealClosingIn: "ලඟදි අවසන්වන අවස්ථා",
      SoldOut: "විකිණී අවසන්",
      SoldOutCampaigns:
        "අපගේ සියලුම අලෙවි වූ ව්‍යාපාර සහ ඒවායේ අනුරූප දිනුම් ඇදීම් මෙහි ලැයිස්තුගත කර ඇත",
      OurProducts: "අපගේ නිෂ්පාදන",
      Winners: "ජයග්‍රාහකයින්",
      WinnersSection:
        "අපගේ සියලුම ජයග්‍රාහකයින් මෙම කොටසින් ප්‍රකාශයට පත් කෙරේ.",
      QuickLinks: "ඉක්මන් සබැඳි",
      About: "අපි ගැන",
      MyAccount: "මගේ ගිණුම",
      ActiveDeals: "ක්රියාකාරී ගනුදෙනු",
      CustomerService: "පාරිභෝගික සේවාව",
      ContactUs: "අපව අමතන්න",
      FAQs: "නිතර අසන පැන",
      HowItWorks: "එය ක්රියා කරන ආකාරය",
      Terms: "කොන්දේසි",
      PrivacyPolicy: "රහස්යතා ප්රතිපත්තිය",
      SignUp: "ලියාපදිංචි වන්න",
      CreateAccount:
        "ඔබගේ ගිණුම සාදන්න - වඩාත්ම වැදගත් විශේෂාංග සමඟ අපගේ සේවාවන් භුක්ති විඳින්න",
      FirstName: "මුල් නම",
      LastName: "අවසන් නම",
      Email: "විද්යුත් තැපෑල",
      Password: "මුරපදය",
      PhoneNumber: "දුරකථන අංකය",
      NIC: "ජාතික හැඳුනුම්පත් අංකය",
      AgreeToTerms: "මම භාවිත නියමයන් සහ රහස්‍යතා ප්‍රතිපත්තියට එකඟ වෙමි",
      GetRegistered: "ලියාපදිංචි වන්න",
      AlreadyHaveAccount: "දැනටමත් ගිණුමක් තිබේද?",
      Login: "පිවිසුම් වන්න",
      ForgotPassword: "මුරපදය අමතක වුණා ද",
      NoAccount: "ගිණුමක් නැද්ද?",
      ResetPassword: "මුරපදය නැවත සකසන්න",
      ResetPasswordDescription:
        "ඔබගේ මුරපදය යළි පිහිටුවීම සඳහා විද්‍යුත් තැපෑලක් ලබා ගැනීමට ඔබගේ ගිණුමේ ඊමේල් ලිපිනය ලබා දෙන්න.",
      SendLink: "සබැඳිය යවන්න",
      BackToLogin: "නැවත පිවිසුම් පිටුවට",
      ItemDetails: "පිළිබඳ විස්තර",
      AddToCart: "ගෙවීමට සූදානම්",
      MaxClosingDate: "උපරිම අවසන් දිනය",
      Wishlist: "පැතුම් ලැයිස්තුව",
      Welcome: "සාදරයෙන් පිළිගනිමු",
      PersonalDetails: "පුද්ගලික තොරතුරු",
      MobilePhone: "ජංගම දුරකථන",
      MICPassportNumber: "ජාතික හැඳුනුම්පත් අංකය / ගමන් බලපත්ර අංකය",
      DateOfBirth: "උපන්දිනය",
      Gender: "ස්ත්රී පුරුෂ භාවය",
      Male: "පිරිමි",
      Female: "ගැහැණු",
      Other: "අනික්",
      UpdatedProfile: "යාවත්කාලීන කරනලද ගිණුම",
      MyWishlist: "මගේ පැතුම් ලැයිස්තුව",
      Remove: "ඉවත් කරන්න",
      PaymentsActiveTickets: "ගෙවීම්/ක්‍රියාකාරී ප්‍රවේශපත්",
      Settings: "සැකසුම්",
      ChangePassword: "මුරපදය වෙනස් කරන්න",
      CurrentPassword: "වත්මන් මුර පදය",
      NewPassword: "නව මුරපදය",
      ConfirmNewPassword: "නව මුරපදය තහවුරු කරන්න",
      UpdatePassword: "මුරපදය යාවත්කාලීන කරන්න",
      Logout: "පිටවීම",
      AddMore: "තව එකතු කරන්න",
      Price: "මිල",
      InclusiveOfVAT: "වැට් බද්ද ඇතුළුව",
      Subtotal: "උප එකතුව",
      PaymentMethod: "ගෙවීම් ක්රමය",
      ClickToPayByCard: "ණයවර පත / හරපත මගින් ගෙවීමට ක්ලික් කරන්න",
      OrderCompletionMessage:
        "Lankadealz.com හි සාප්පු සවාරි ගියාට ස්තුතියි ඔබේ ඇණවුම සම්පූර්ණයි!",
      ConfirmationEmailMessage:
        "ඔබට ඇණවුම් විස්තර සහිත තහවුරු කිරීමේ විද්‍යුත් තැපෑලක් ලැබෙනු ඇත",
      Product: "නිෂ්පාදන",
      Quantity: "ප්රමාණය",
      PurchasedTickets: "මිලදී ගත් ටිකට්පත්",
      IssuedOn: "මත නිකුත් කරන ලදී",
      DrawOn: "දිනුම් ඇදීමේ දිනය",
      TicketNumber: "ටිකට් අංකය",
      AboutUs: "අපි ගැන",
      TransformedShoppingExperience:
        "අපි සබැඳි සාප්පු සවාරි අත්දැකීම පරිවර්තනය කර ඇත!",
      WinFantasticPrizes:
        "අපගේ ඕනෑම නිෂ්පාදනයක් මිල දී ගෙන අපූරු ත්‍යාග දිනා ගැනීමට අවස්ථාවක් ලබා ගන්න. සැක සහිතද? අපගේ අතීත ජයග්‍රාහකයින් පරීක්ෂා කර ජයග්‍රහණයේ උද්දීපනයට එක්වන්න!",
      StartShoppingJourney:
        "ඔබේ සාප්පු සවාරි ගමන දැන් අරඹා ඇදහිය නොහැකි ගනුදෙනුවල ප්‍රීතිය අත්විඳීමට සූදානම් වන්න!",
      ContactUsSection: "අපව අමතන්න",
      ContactFormDescription:
        "කරුණාකර පහත පෝරමය පුරවන්න, සහ අපගේ කැපවූ කණ්ඩායමේ සාමාජිකයෙක් පැය 24ක් ඇතුළත ඔබව සම්බන්ධ කරගනු ඇත.",
      Message: "පණිවුඩය",
      SendMessage: "පණිවුඩය යවන්න",
      CallUsNow: "දැන් අප අමතන්න",
      WriteUsEmail: "අපට විද්‍යුත් තැපෑලක් ලියන්න",
      VerifyMobileNumber: "ජංගම දුරකථන අංකය තහවුරු කරන්න",
      EnterVerificationCode:
        "කරුණාකර ඔබගේ ජංගම දුරකථන අංකයට එවන ලද ඉලක්කම් 6ක කේතය ඇතුලත් කරන්න. කේතය විනාඩි 5 කින් කල් ඉකුත් වේ.",
      Verify: "තහවුරු කරන්න",
      WeAccept: "අපි පිළිගන්නේ",
      DidNotBuy: "ඔබ මෙම ගනුදෙනුව මිලදී ගත්තේ නැත",
      YouBought: "ඔබ මෙම ගනුදෙනුව මිලදී ගත්තා",
      DealCoseDate: "ගනුදෙනුව වසා දැමූ දිනය",
    },
  },
  ta: {
    translation: {
      NeedHelp: "உதவி தேவை? எங்களை தொடர்பு கொள்ள",
      Register: "பதிவு",
      LogIn: "உள்நுழைக",
      MoreGreatDeals: "மேலும் விலைமதிப்பற்ற தருணங்கள்",
      DealClosingIn: "வாய்ப்புகள் நெருங்கிவிட்டன",
      SoldOut: "விற்றுத் தீர்ந்துவிட்டது",
      SoldOutCampaigns:
        "எங்களின் அனைத்து விற்பனையான பிரச்சாரங்களும் அவற்றின் தொடர்புடைய  தேதிகளும் இங்கே பட்டியலிடப்பட்டுள்ளன",
      OurProducts: "எங்கள் தயாரிப்புகள்",
      Winners: "வெற்றியாளர்கள்",
      WinnersSection:
        "எங்கள் வெற்றியாளர்கள் அனைவரும் இந்தப் பிரிவில் அறிவிக்கப்பட்டுள்ளனர்.",
      QuickLinks: "விரைவு இணைப்புகள்",
      About: "எங்களை பற்றி",
      MyAccount: "என் கணக்கு",
      ActiveDeals: "செயலில் உள்ள ஒப்பந்தங்கள்",
      CustomerService: "வாடிக்கையாளர் சேவை",
      ContactUs: "எங்களை தொடர்பு கொள்ள",
      FAQs: "அடிக்கடி கேட்கப்படும் கேள்விகள்",
      HowItWorks: "இது எப்படி செயல்படுகிறது",
      Terms: "விதிமுறை",
      PrivacyPolicy: "தனியுரிமைக் கொள்கை",
      SignUp: "பதிவு செய்யவும்",
      CreateAccount:
        "உங்கள் கணக்கை உருவாக்கவும் - மிக முக்கியமான அம்சங்களுடன் எங்கள் சேவைகளை அனுபவிக்கவும்",
      FirstName: "முதல் பெயர்",
      LastName: "கடைசி பெயர்",
      Email: "மின்னஞ்சல்",
      Password: "கடவுச்சொல்",
      PhoneNumber: "தொலைபேசி எண்",
      NIC: "தேசிய அடையாள அட்டை எண்",
      AgreeToTerms:
        "பயன்பாட்டு விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கையை நான் ஏற்கிறேன்",
      GetRegistered: "பதிவு செய்யுங்கள்",
      AlreadyHaveAccount: "ஏற்கனவே ஒரு கணக்கு உள்ளதா ?",
      Login: "உள்நுழைய",
      ForgotPassword: "கடவுச்சொல்லை மறந்துவிட்டீர்களா?",
      NoAccount: "கணக்கு இல்லையா?",
      ResetPassword: "கடவுச்சொல்லை மீட்டமைக்க",
      ResetPasswordDescription:
        "உங்கள் கடவுச்சொல்லை மீட்டமைப்பதற்கான மின்னஞ்சலைப் பெற உங்கள் கணக்கு மின்னஞ்சல் முகவரியை வழங்கவும்.",
      SendLink: "இணைப்பை அனுப்பவும்",
      BackToLogin: "உள்நுழைவுக்குத் திரும்பு",
      ItemDetails: "பொருள் விவரங்கள்",
      AddToCart: "ஷாப்பிங் கார்ட்டில் சேர்க்கவும்",
      MaxClosingDate: "அதிகபட்ச இறுதி தேதி",
      Wishlist: "விருப்பப்பட்டியல்",
      Welcome: "வரவேற்பு",
      PersonalDetails: "சொந்த விவரங்கள்",
      MobilePhone: "தொலைபேசி எண்",
      MICPassportNumber: "தேசிய அடையாள அட்டை / பாஸ்போர்ட் எண்",
      DateOfBirth: "பிறந்த தேதி",
      Gender: "பாலினம்",
      Male: "ஆண்",
      Female: "பெண்",
      Other: "மற்றவைகள்",
      UpdatedProfile: "புதுப்பிக்கப்பட்ட சுயவிவரம்",
      MyWishlist: "எனது விருப்பப்பட்டியல்",
      Remove: "அகற்று",
      PaymentsActiveTickets: "கட்டணங்கள் / செயலில் உள்ள டிக்கெட்டுகள்",
      Settings: "அமைப்புகள்",
      ChangePassword: "கடவுச்சொல்லை மாற்று",
      CurrentPassword: "தற்போதைய கடவுச்சொல்",
      NewPassword: "புதிய கடவுச்சொல்",
      ConfirmNewPassword: "புதிய கடவுச்சொல்லை உறுதிப்படுத்தவும்",
      UpdatePassword: "கடவுச்சொல்லை புதுப்பிக்கவும்",
      Logout: "வெளியேறு",
      AddMore: "மேலும் சேர்க்கவும்",
      Price: "விலை",
      InclusiveOfVAT: "VAT உட்பட",
      Subtotal: "மொத்தம்",
      PaymentMethod: "பணம் செலுத்தும் முறை",
      ClickToPayByCard:
        "கிரெடிட் / டெபிட் கார்டு மூலம் பணம் செலுத்த கிளிக் செய்யவும்",
      OrderCompletionMessage:
        "Lankadealz.com இல் ஷாப்பிங் செய்ததற்கு நன்றி உங்கள் ஆர்டர் முடிந்தது!",
      ConfirmationEmailMessage:
        "ஆர்டர் விவரங்களுடன் உறுதிப்படுத்தல் மின்னஞ்சலைப் பெறுவீர்கள்",
      Product: "தயாரிப்பு",
      Quantity: "அளவு ",
      PurchasedTickets: "வாங்கிய டிக்கெட்ஸ்",
      IssuedOn: " வெளியிடப்பட்ட  தினம் ",
      DrawOn: "வரை",
      TicketNumber: "டிக்கெட் எண்.",
      AboutUs: "எங்களை பற்றி",
      TransformedShoppingExperience:
        "ஆன்லைன் ஷாப்பிங் அனுபவத்தை மாற்றியுள்ளோம்!",
      WinFantasticPrizes:
        "எங்கள் தயாரிப்புகளில் ஏதேனும் ஒன்றை வாங்கி, அருமையான பரிசுகளை வெல்லும் வாய்ப்பைப் பெறுங்கள். சந்தேகமா? எங்களின் கடந்தகால வெற்றியாளர்களைப் பார்த்து, வெற்றி பெற்ற உற்சாகத்தில் சேரவும்!",
      StartShoppingJourney:
        "உங்கள் ஷாப்பிங் பயணத்தை இப்போதே தொடங்குங்கள் மற்றும் நம்பமுடியாத ஒப்பந்தங்களின் சிலிர்ப்பை அனுபவிக்க தயாராகுங்கள்!",
      ContactUsSection: "எங்களை தொடர்பு கொள்ள",
      ContactFormDescription:
        "கீழே உள்ள படிவத்தை நிரப்பவும் மற்றும் எங்கள் குழு உறுப்பினர்களில் ஒருவர் உங்களை 24 மணி நேரத்திற்குள் தொடர்புகொள்வார்.",
      Message: "செய்தி",
      SendMessage: "செய்தி அனுப்பு",
      CallUsNow: "இப்போது எங்களை அழைக்கவும்",
      WriteUsEmail: "எங்களுக்கு ஒரு மின்னஞ்சல் எழுதவும்",
      VerifyMobileNumber: "மொபைல் எண்ணைச் சரிபார்க்கவும்",
      EnterVerificationCode:
        "உங்கள் மொபைல் எண்ணுக்கு அனுப்பப்பட்ட 6 இலக்க குறியீட்டை உள்ளிடவும். குறியீடு 5 நிமிடங்களில் காலாவதியாகிவிடும்",
      Verify: "தயவு செய்து சரி பார்க்கவும்",
      WeAccept: "நாங்கள் சம்மதிக்கிறோம்",
      DidNotBuy: "நீங்கள் இந்த ஒப்பந்தத்தை வாங்கவில்லை",
      YouBought: "நீங்கள் இந்த ஒப்பந்தத்தை வாங்கினீர்கள்",
      DealCoseDate: "கடைசி நாள்",
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
