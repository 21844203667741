import {
  useAddToCartMutation,
  useRemoveFromCartMutation,
} from "features/api-slice";
import { Cart } from "models/Cart";
import styled from "styled-components";
import { colors, styles } from "./variables";

const ItemCountWrapper = styled.div`
  flex: 0 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    height: 8rem;
    margin: auto;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    * {
      cursor: not-allowed !important;
    }
  }

  .plus {
    --b: 2px;
    width: 15px;
    aspect-ratio: 1;
    border: 3px solid ${colors.colorMain};
    background-image: conic-gradient(
      from 90deg at var(--b) var(--b),
      ${colors.colorMain} 90deg,
      #fff 0
    );
    background-size: calc(50% + var(--b)) calc(50% + var(--b));
    background-position: calc(100% + var(--b) / 2) calc(100% + var(--b) / 2);
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }

  .minus {
    --b: 2px;
    width: 15px;
    aspect-ratio: 1;
    border: 3px solid ${colors.colorMain};
    background-color: ${colors.colorMain};
    background-image: conic-gradient(
      from 90deg at 0px 0px,
      ${colors.white} 90deg,
      ${colors.colorMain}
    );
    background-position: 0;
    background-size: calc(100%) var(--b);
    background-repeat: no-repeat;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const ItemCounter = (props: { cartItem: Cart; className?: string }) => {
  const [
    addToCart,
    { isLoading: isAddingToCart, isSuccess: addToCartSuccess, reset },
  ] = useAddToCartMutation({ fixedCacheKey: "shared-cart" });
  const [removeFromCart, { isLoading: isRemovingFromCart }] =
    useRemoveFromCartMutation();

  const increment = (id: number) => {
    if (isAddingToCart || isRemovingFromCart) {
      return;
    }
    addToCart({ campaignId: id, numberOfUnits: 1 });
  };

  const decrement = (id: number) => {
    if (isAddingToCart || isRemovingFromCart) {
      return;
    }
    removeFromCart(id);
  };

  const cart = props.cartItem;

  return (
    <ItemCountWrapper className={`item-count-wrapper ${props.className}`}>
      <div
        className={`increment ${
          isAddingToCart || isRemovingFromCart ? "disabled" : ""
        }`}
        onClick={() => increment(cart.campaign.id)}
      >
        <div className="plus"></div>
      </div>
      <div className="count fw-bold">{cart.numberOfUnits}</div>
      <div
        className={`decrement ${
          cart.campaign.totalItemNumber === 0 ? "disabled" : ""
        }  ${isAddingToCart || isRemovingFromCart ? "disabled" : ""}`}
        onClick={() => decrement(cart.campaign.id)}
      >
        <div className="minus"></div>
      </div>
    </ItemCountWrapper>
  );
};
