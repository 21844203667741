import styled from "styled-components";
import { StyledButton } from "./common-styled-components/styled-components";
import { colors, styles } from "./variables";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledAddToCartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 30%;
  height: 100%;

  .btn-container {
    display: flex;
    gap: 0.5rem;
    flex: 100%;

    button {
      font-weight: 500;
      height: 50px;
      @media screen and (max-width: ${styles.breakpoints.medium}) {
        height: 64px;
      }

      &.mini {
        max-width: 60px;
        height: 50px;
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          height: 64px;
        }
      }

      &.count {
        cursor: default;
      }
    }
  }
`;

export type AddToCartProps = {
  count: number;
  className?: string;
  onRemoveFromCart: () => void;
  onAddToCart: () => void;
};

export const AddToCartButton = (props: AddToCartProps) => {
  const { t } = useTranslation();

  return (
    <StyledAddToCartWrapper className={props.className}>
      <div className="btn-container">
        {props.count ? (
          <>
            {/* Show count, minus one and add more if item is already added to cart */}
            <StyledButton
              backgroundColor={colors.darkGrey}
              borderRadius="10px"
              className="p-4 mini minus"
              onClick={(event: MouseEvent) => {
                props.onRemoveFromCart();
                event.stopPropagation();
              }}
            >
              -
            </StyledButton>
            <StyledButton
              backgroundColor={colors.darkGrey}
              borderRadius="10px"
              className="p-4 mini count"
            >
              {props.count}
            </StyledButton>
          </>
        ) : null}
        <StyledButton
          borderRadius="10px"
          backgroundColor={colors.colorMain}
          color={colors.white}
          className="p-4 add-btn"
          onClick={(event: MouseEvent) => {
            props.onAddToCart();
            event.stopPropagation();
          }}
        >
          {props.count ? t(Translations.AddMore) : t(Translations.AddToCart)}
        </StyledButton>
      </div>
    </StyledAddToCartWrapper>
  );
};
