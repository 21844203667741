import {
  AppSpinner,
  ContainerCard,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { PasswordField } from "components/password-field";
import { colors } from "components/variables";
import {
  useResetPasswordMutation,
  useValidateResetPasswordTokenQuery,
} from "features/auth/auth-slice";
import { Translations } from "i18n/i18n";
import { ResetPassword } from "models/ResetPassword";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledSettingsWrapper = styled(ContainerCard)`
  h4 {
    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 600;
  }
  input {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 14px;
    height: 50px;
  }

  input::placeholder {
    font-weight: 500;
  }

  .form-label {
    color: ${colors.lightGrey};
    margin: 0;
    font-size: 12px;
  }
`;

type ResetPasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const ResetPasswordComponent = () => {
  const { register, handleSubmit, formState } = useForm<ResetPasswordForm>();
  const { t } = useTranslation();

  const params = useParams<{ email: string; token: string }>();
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();

  const { data: tokenValidity, isLoading: validatingToken } =
    useValidateResetPasswordTokenQuery({
      email: params.email || "",
      token: params.token || "",
    });

  const onChangePassword = async (data: ResetPasswordForm) => {
    const resetPasswordPayload: ResetPassword = {
      email: params.email!,
      password: data.newPassword,
      confirmPassword: data.confirmNewPassword,
      token: params.token!,
    };
    await resetPassword(resetPasswordPayload).unwrap();
    toast.success("Password changed successfully. Please login again");
    navigate("/");
  };

  return (
    <StyledSettingsWrapper className="p-4 mt-5">
      <div className="container text-start p-0 mb-2 mt-3">
        <div className="row">
          <div className="col">
            <h4 className="text-start fw-bolder mb-3">
              {t(Translations.ResetPasswordDescription)}
            </h4>
          </div>
        </div>
      </div>

      {validatingToken ? (
        <AppSpinner show={true} />
      ) : (
        <div className="content">
          {tokenValidity?.valid ? (
            <Form onSubmit={handleSubmit(onChangePassword)}>
              <div className="container text-start p-0 mb-2">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <PasswordField
                      label={t(Translations.NewPassword)}
                      register={register("newPassword", {
                        required: true,
                        minLength: 8,
                      })}
                    ></PasswordField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <PasswordField
                      label={t(Translations.ConfirmNewPassword)}
                      register={register("confirmNewPassword", {
                        required: true,
                        minLength: 8,
                      })}
                    ></PasswordField>
                  </div>
                </div>
              </div>

              <div className="container mt-5 pt-5 mb-2">
                <div className="row justify-content-center">
                  <div className="col-6 col-md-4 form-btn">
                    <StyledButton
                      type="submit"
                      color={colors.white}
                      borderRadius="10px"
                      disabled={!formState.isValid}
                    >
                      {t(Translations.ResetPassword)}
                    </StyledButton>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <div className="invalid-token">
              <h5 className="invalid-token-error mb-5 pb-3">
                The token is invalid or expired
              </h5>
            </div>
          )}
        </div>
      )}
    </StyledSettingsWrapper>
  );
};
