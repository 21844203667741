import {
  FullWidthImage,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { useState } from "react";
import soldOut from "assets/images/sold-out.svg";
import styled from "styled-components";
import { Campaign } from "models/Campaign";
import { DateTime } from "luxon";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const CardWrapper = styled.div`
  padding: 1.25rem 2rem;
  border-radius: 15px;
  background: white;
  border: none;
  box-shadow: ${styles.boxShadow};
  margin: 10px 0 0;
  position: relative;
  width: auto;
  flex: 1;

  @media screen and (min-width: ${styles.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .name {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image-container {
    border-radius: 15px;
    overflow: hidden;
    height: 160px;
    position: relative;
    opacity: 0.4;

    .sold-out {
      position: absolute;
      top: 0;
      left: calc(50% - 40%);
      width: 80%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .closed-date {
    color: ${colors.lightGrey};

    .highlight {
      color: ${colors.darkerRed};
    }
  }

  .add-to-cart {
    button {
      font-size: 0.75rem;
      line-height: 1.5rem;
      cursor: default;
      border-radius: 25px;
      padding: 5px;
    }
  }
`;

export const CardSoldOut = (props: {
  campaign: Campaign;
  addToCart?: (id: number) => void;
}) => {
  const [x] = useState(Math.round(Math.random() * 10));
  const campaignTitle = useTranslatedLabel(props.campaign, "campaignTitle");
  const { t } = useTranslation();

  return (
    <CardWrapper className="card-sold-out">
      <div className="soldOut-item">
        <div className="image-container">
          <FullWidthImage
            src={`${process.env.REACT_APP_API_URL}/image/${props.campaign.campaignImage}`}
          ></FullWidthImage>
          <div className="sold-out">
            <img src={soldOut}></img>
          </div>
        </div>
        <h5 className="name mt-2 fw-bold">{campaignTitle}</h5>
        <p className="closed-date mb-0">
        {t(Translations.DealCoseDate)}:{" "}
          {props.campaign.closingDate
            ? DateTime.fromISO(props.campaign.closingDate).toFormat(
                "dd LLL yyyy"
              )
            : ""}
          <span className="highlight"></span>
        </p>
        <div className="add-to-cart mt-2 d-none d-md-block">
          {props.campaign.bought ? (
            <StyledButton
              borderRadius="6px"
              backgroundColor={colors.lightGreen}
              color={colors.white}
            >
              {t(Translations.YouBought)}
            </StyledButton>
          ) : (
            <StyledButton
              borderRadius="6px"
              backgroundColor={colors.subtleGrey}
            >
              {t(Translations.DidNotBuy)}
            </StyledButton>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};
