import { configureStore } from "@reduxjs/toolkit";
import authReducer from "features/auth/auth-slice";
import { apiSlice } from "features/api-slice";
import { rtkQueryErrorLogger } from "./middleware";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(rtkQueryErrorLogger);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
