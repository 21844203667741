import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { CartComponent } from "components/cart";
import { AppSpinner } from "components/common-styled-components/styled-components";
import { Footer } from "components/footer/footer";
import { colors, styles } from "components/variables";
import { useFetchCartQuery } from "features/api-slice";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import "./App.scss";
import { Header } from "./components/header";
import i18next from "i18next";

const AppWrapper = styled.div<{ isHomePage: boolean }>`
  text-align: center;
  padding: 2% 4%;
  background-color: ${colors.backgroundColor};
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }

  .app-container {
    margin: 0 auto;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 0;

    @media screen and (max-width: 1449px) {
      width: 100%;
    }

    @media screen and (min-width: 1450px) {
      max-width: 1450px;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        padding-top: 80px; // Spacing for floating header
      }
    }

    .footer {
      @media screen and (max-width: 768px) {
        box-shadow: none;
        margin-bottom: 0 !important;

        &:not(.footer-home) {
          display: none;
        }
      }
    }
  }
  .cart-container {
    position: fixed;
    right: 2%;
    bottom: 5%;
    z-index: 100;
  }
`;

function App() {
  const headerHiddenPages = ["login", "cart"];
  const location = useLocation();
  const currentPage = location?.pathname?.replace("/", "");
  const hideHeader = headerHiddenPages.includes(currentPage);
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: cartCampaigns } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });

  const isHomePage = currentPage === "";
  const isLoading = useAppSelector((state) => {
    const inProgressQueries = Object.values(state.api.queries).some((query) => {
      return query?.status === QueryStatus.pending;
    });
    const inProgressMutations = Object.values(state.api.mutations).some(
      (mutation) => {
        return mutation && mutation.status === QueryStatus.pending;
      }
    );
    return inProgressQueries || inProgressMutations;
  });

  const homePageRequests = ["fetchProducts", "fetchCampaigns"];
  const isHomePageRequestsLoading = useAppSelector((state) => {
    return Object.values(state.api.queries).some((query) => {
      return (
        query?.status === QueryStatus.pending &&
        homePageRequests.includes(query.endpointName!)
      );
    });
  });

  i18next.on("languageChanged", (lang: string) => {
    document.documentElement.lang = lang;
  });

  return (
    <AppWrapper isHomePage={isHomePage}>
      <div className="app-container">
        <AppSpinner
          show={isLoading}
          initialLoad={isHomePageRequestsLoading}
        ></AppSpinner>

        {hideHeader ? <></> : <Header showBackground={true}></Header>}

        <div className="content">
          <Outlet />
        </div>

        {!!cartCampaigns?.length && currentPage !== "checkout" ? (
          <div className="cart-container">
            <CartComponent></CartComponent>
          </div>
        ) : null}

        <div className={`footer mb-3 ${isHomePage ? "footer-home" : ""}`}>
          <Footer></Footer>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </AppWrapper>
  );
}

export default App;
