import "./styles.scss";

export const FAQ = () => {
  return (
    <div className="main-faq mt-5 mb-1">
      <h1>Frequently asked questions</h1>

      <div className="faq-row">
        <h3 className="faq-title">About Us</h3>
        <ul className="faqs">
          <li>
            <p className="question">What Is Lanka Dealz?</p>
            <div className="answer">
              <p>
                Lanka Dealz is an online store with a twist. For every purchase
                made, Lanka Dealz gives its customers a complimentary Prize Draw
                ticket where they can win a luxury prize
              </p>
            </div>
          </li>
          <li>
            <p className="question">How can I access Lankadealz.com?</p>
            <div className="answer">
              <p>
                Lanka dealz is available on desktop, smart phone, tablet and via
                our interactive kiosks.
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              I’m new to Lanka dealz, how does it work?
            </p>
            <div className="answer">
              <p>
                Our website/App features a full tutorial. You can access this
                tutorial at any time by&nbsp;
                <a href="https://lankadealz.com/how-it-works">clicking this link</a>.
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row">
        <h3 className="faq-title">Account</h3>
        <ul className="faqs">
          <li>
            <p className="question">
              Why should I register an account with Lankadealz?
            </p>
            <div className="answer">
              <p>
                To complete a purchase with Lankadealz you must have a
                registered account. This is for your security and protection
              </p>
            </div>
          </li>
          <li>
            <p className="question">How do I set up my Lankadealz account?</p>
            <div className="answer">
              <p>
                To create an account simply click ‘Login/Register’ in the top
                right hand corner of your screen and enter your details in the
                fields highlighted
              </p>
            </div>
          </li>
          <li>
            <p className="question">How can I access Lankadealz.com?</p>
            <div className="answer">
              <p>
                To edit your account, click on your name/photo icon located in
                the main menu. Tap profile section to edit and click 'Save
                changes' to complete.
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Can I use someone else’s credit card in my Lankadealz account to
              complete a purchase?
            </p>
            <div className="answer">
              <p>
                If you have the card holder’s permission, you can use any credit
                card to complete your purchase.
                <br />
                <small>*We do not save credit card information</small>
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              How do I ‘Logout’ of my Lankadealz account?
            </p>
            <div className="answer">
              <p>
                To ‘Logout’ simply click on your name/photo in the top right
                hand corner and select ‘Logout’ from the drop-down menu
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              I can’t remember my Lanka dealz account password!
            </p>
            <div className="answer">
              <p>
                At ‘Login’ select ‘Forgot Password’ and follow the on-screen
                instructions for password reset.
              </p>
            </div>
          </li>
          <li>
            <p className="question">I want to change my password!</p>
            <div className="answer">
              <p>
                To change your password, click on your name/photo in the top
                right hand corner and select ‘My Account’. From here select the
                ‘Settings’ tab to change your password
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              What Credit Cards does Lankadealz accept?
            </p>
            <div className="answer">
              <p>
                Lankadealz accepts all major credit/debits cards including (but
                not limited to): Visa,MasterCard and Amex.
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Why am I receiving ‘push notifications’ Vis SMS /emails from
              Lankadealz?
            </p>
            <div className="answer">
              <p>
                Lankadealz is not in the business of spamming or bombarding its
                users with irrelevant ‘push notifications’ (SMS)/emails. Any
                notices sent by Lankadealz have been deemed relevant to your
                user experience
              </p>
            </div>
          </li>
          <li>
            <p className="question">How do I close my Lankadealz account?</p>
            <div className="answer">
              <p>
                To close your account you must notify us in writing (from your
                registered email account) at: support@lankadealz.com. Hereafter,
                a dedicated member of our support team will assist you within 48
                hrs
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row">
        <h3 className="faq-title">Campaigns</h3>
        <ul className="faqs">
          <li>
            <p className="question">
              How frequently do new Lankadealz campaigns emerge?
            </p>
            <div className="answer">
              <p>
                New campaigns are created instantly at the close of an existing
                campaign. There is no set time limit per campaign, however, you
                can expect new campaigns to emerge frequently.
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Will the product/prize categories expand?
            </p>
            <div className="answer">
              <p>
                New products and prizes are on their way, stay tuned for some
                exciting campaigns.
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row">
        <h3 className="faq-title">Making a Purchase</h3>
        <ul className="faqs">
          <li>
            <p className="question">
              Are there any hidden charges I should be aware of?
            </p>
            <div className="answer">
              <p>
                There are no hidden charges on any Lanka dealz purchase. You
                should however CHECK with your bank to see if they apply any
                transaction/processing fees
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              What currencies can I use to purchase an Lankadealz product?
            </p>
            <div className="answer">
              <p>Lankadealz currently only accepts LKR and US Dollars.</p>
            </div>
          </li>
          <li>
            <p className="question">
              Can I cancel/refund an Lankadealz purchase order?
            </p>
            <div className="answer">
              <p>
                As per the&nbsp;
                <a href="https://lankadealz.com/terms">‘User Agreement’</a> all
                sales purchases are final and no refunds are given under any
                circumstances.
              </p>
            </div>
          </li>
          <li>
            <p className="question">Can I pay in installments?</p>
            <div className="answer">
              <p>Payment via instalments is a feature is not available</p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row">
        <h3 className="faq-title">Lankadealz Products</h3>
        <ul className="faqs">
          <li>
            <p className="question">
              Where can I collect my Lankadealz products?
            </p>
            <div className="answer">
              <p>
                All Lankadealz products can be collected directly from
                Lankadealz HQ, please read the&nbsp;
                <a href="https://lankadealz.com/terms">‘User Agreement’</a>{" "}
                prior to making/collecting a purchase.
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Can I send someone else to collect my Lankadealz products?
            </p>
            <div className="answer">
              <p>
                Only the registered account holder, or (and at our discretion),
                any individual with ‘Power of Attorney’ (POA) can collect the
                registered account holders’ Lankadealz purchase. NIC or passport
                are the only acceptable forms of identification (please ensure
                your identification is up to date).
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row">
        <h3 className="faq-title">Lankadealz Prizes</h3>
        <ul className="faqs">
          <li>
            <p className="question">Where can I collect my Lankadealz prize?</p>
            <div className="answer">
              <p>
                Details on where to collect your prize will be sent to you (aka
                the 'winner') via email / SMS. For full details please refer to
                the 'Draw Terms & Conditions'
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Can I send someone else to collect my Prize?
            </p>
            <div className="answer">
              <p>
                Only the registered account holder, or (and at our discretion),
                any individual with ‘Power of Attorney’ (POA) can collect the
                registered account holders’ Lankadealz Prize. NIC or passport
                are the only acceptable forms of identification (please ensure
                your identification is up to date).
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              What if I don’t want people to know I’ve won the Lankadealz prize
              draw?
            </p>
            <div className="answer">
              <p>
                As per the ‘Draw Terms & Conditions', Lankadealz has the right
                to use your image, name and any statements made across their
                marketing channels (physical/digital)
              </p>
            </div>
          </li>
          <li>
            <p className="question">
              Can I view past winners of the Lankadealz campaign draws?
            </p>
            <div className="answer">
              <p>
                You will be able to view past winners of Lankadealz campaigns
                via our social media channels, as well as our up and coming
                'news' segment
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="faq-row bb-0">
        <h3 className="faq-title">Security</h3>
        <ul className="faqs">
          <li>
            <p className="question">
              Are my personal details secure with Idealz?
            </p>
            <div className="answer">
              <p>
                Lankadealz does not store nor save any sensitive Credit Card
                information on its servers. Credit Card details that are
                registered with us are stored securely through the ‘Checkout’
                secure online payment gateway.
              </p>
            </div>
          </li>
          <li>
            <p className="question">Will I be spammed by Idealz?</p>
            <div className="answer">
              <p>Nope!</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
