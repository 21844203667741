import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import location from "assets/images/icons/location.svg";
import logoutImg from "assets/images/icons/logout.svg";
import settings from "assets/images/icons/settings.svg";
import userImg from "assets/images/icons/user.svg";
import heart from "assets/images/icons/heart.svg";
import ticket from "assets/images/icons/ticket.svg";
import creditCard from "assets/images/icons/credit-card.svg";
import cameraIcon from "assets/images/icons/camera_alt.svg";
import {
  FullWidthImage,
  MaskedImage,
} from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import {
  apiSlice,
  useFetchUserDetailsQuery,
  useUploadProfileImageMutation,
} from "features/api-slice";
import { logout } from "features/auth/auth-slice";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { toSentenceCase } from "shared/utils/utils";
import styled from "styled-components";
import { UserNavigation } from "./user-navigation";
import { NavLinkItem } from "models/NavLinkItem";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 3rem;
  max-height: 100%;
  overflow: hidden;
`;

const UserSidebar = styled.div`
  display: flex;
  flex: 1 20%;
  flex-direction: column;
  gap: 2rem;

  .user-details {
    background-color: ${colors.white};
    box-shadow: ${styles.boxShadow};
    border-radius: 20px;
    padding: 1.5rem;

    .image-container {
      width: 145px;
      height: 145px;
      margin: auto;
      margin-bottom: 1rem;
      position: relative;

      .image {
        width: 100%;
        height: 100%;
        background-color: ${colors.darkGrey};
        border-radius: 50%;
        border: 2px solid ${colors.mainRed};
      }

      .upload-image {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: ${colors.mainRed};
        border-radius: 50%;
        cursor: pointer;
        display: flex;
      }
    }

    .details {
      h5 {
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 600;
        padding: 0;
        margin: 0;
      }
      div {
        font-family: "Rubik", sans-serif;
      }
    }
  }
`;

const UserView = styled.div`
  flex: 1 70%;
`;

export const UserPage = () => {
  const fileUpload = useRef<HTMLInputElement>(null);
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: user } = useFetchUserDetailsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState("");
  const [
    uploadImage,
    { isLoading: isUploadingImage, isSuccess: isImageUploaded },
  ] = useUploadProfileImageMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onLogout = () => {
    dispatch(apiSlice.util.invalidateTags(["SoldOut"]));
    dispatch(logout());
    navigate("/");
  };

  const getUserName = () => {
    const firstName = toSentenceCase(user?.firstName!);
    const lastName = toSentenceCase(user?.lastName!);

    return `${firstName} ${lastName}`;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;
    if (files) {
      setFile(files[0]);
      uploadUserImage(files[0]);
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);
      reader.onloadend = (e: any) => {
        setFileUrl(reader.result as string);
      };
    }
  };

  const uploadUserImage = (file: Blob) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadImage(formData);
  };

  useEffect(() => {
    if (user?.profilePicture) {
      setFileUrl(user.profilePicture);
    }
  }, [user]);

  const navLinks: NavLinkItem[] = [
    {
      to: "edit",
      title: t(Translations.PersonalDetails),
      icon: userImg,
    },
    {
      to: "wishlist",
      title: t(Translations.Wishlist),
      icon: heart,
    },
    {
      to: "tickets",
      title: t(Translations.PaymentsActiveTickets),
      icon: ticket,
    },
    // {
    //   to: "payments",
    //   title: "Payments",
    //   icon: creditCard,
    // },
    {
      to: "settings",
      title: t(Translations.Settings),
      icon: settings,
    },
    // {
    //   to: "shipping",
    //   title: "Shipping Addresses",
    //   icon: location,
    // },
    {
      title: t(Translations.Logout),
      icon: logoutImg,
      onClick: onLogout,
    },
  ];

  return (
    <UserContainer>
      <UserSidebar className="d-none d-md-flex">
        <div className="user-details">
          <div className="image-container">
            {!!fileUrl ? (
              <FullWidthImage
                className="image"
                src={`${process.env.REACT_APP_API_URL}/image/${fileUrl}`}
              ></FullWidthImage>
            ) : (
              <div className="image"></div>
            )}

            <div
              className="upload-image p-2"
              onClick={() => {
                fileUpload.current?.click();
              }}
            >
              <FullWidthImage src={cameraIcon}></FullWidthImage>
            </div>

            <input
              className="d-none"
              type="file"
              onChange={(e) => handleChange(e)}
              ref={fileUpload}
            />
          </div>
          <div className="details">
            <h5 className="name">{getUserName()}</h5>
            <div className="email">{user?.email}</div>
          </div>
        </div>
        <UserNavigation items={navLinks}></UserNavigation>
      </UserSidebar>
      <UserView className="mb-4">
        <Outlet></Outlet>
      </UserView>
    </UserContainer>
  );
};
