import { ReactComponent as Eye } from "assets/images/remove_red_eye.svg";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

const StyledPasswordWrapper = styled.div<{ showPassword: boolean }>`
  --eye-color-toggled: #0c16ad;

  .input-group-text {
    cursor: pointer;
    svg {
      transition: all 0.1s linear;

      ${(props) =>
        props.showPassword
          ? `
          g {
            fill: var(--eye-color-toggled);
        }

        path {
            stroke: var(--eye-color-toggled);
            fill: transparent;
        }        
        `
          : ``}
    }
  }
`;

export const PasswordField = (props: { label: string; register }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledPasswordWrapper showPassword={showPassword}>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder={props.label}
          {...props.register}
        />
        <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
          <Eye />
        </InputGroup.Text>
      </InputGroup>
    </StyledPasswordWrapper>
  );
};
