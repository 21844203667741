import { Campaign } from "models/Campaign";
import styled from "styled-components";
import { ImageComponent } from "./image";
import { BuyText } from "./buy";
import { colors } from "./variables";

const StyledCampaignDisplayImage = styled.div`
  width: 100%;
  height: 380px;
  max-height: 380px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;

  .overlay-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: rgb(237, 23, 23);
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 25px;
    background: linear-gradient(0deg, rgb(0 0 0 / 77%) 0%, rgb(0 0 0 / 11%) 78%, rgb(255 255 255 / 0%) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .campaign-title {
      color: ${colors.white};
    }
  }
`;

export const CampaignDisplayImage = (props: {
  campaign: Campaign;
  className?: string;
}) => {
  return (
    <StyledCampaignDisplayImage
      className={`campaign-display-image ${props.className ?? ""}`}
    >
      <ImageComponent
        src={`${process.env.REACT_APP_API_URL}/image/${props.campaign.mainOffer.offerImage}`}
      ></ImageComponent>

      <div className="overlay-container">
        <BuyText></BuyText>
        <div className="campaign-title fs-3 fw-semibold">
          {props.campaign.mainOffer.offerTitle}
        </div>
      </div>
    </StyledCampaignDisplayImage>
  );
};
