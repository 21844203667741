import { colors, styles } from "components/variables";
import { memo } from "react";
import { Form, Modal, Spinner, SpinnerProps } from "react-bootstrap";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: inherit;
  object-fit: cover;
`;

const FullWidthImage = memo((props: React.HTMLProps<HTMLImageElement>) => {
  return (
    <StyledImage
      src={props.src}
      className={props.className}
      onClick={props.onClick}
    />
  );
});

const StyledButton = styled.button<{
  backgroundColor?: string;
  color?: string;
  border?: string;
  borderRadius?: string;
  lineHeight?: string;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.color || colors.black};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || 0};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  line-height: ${(props) => props.lineHeight ?? "3rem"};

  &:disabled {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    filter: saturate(0.9);
  }
`;

const SyledFormCheckOrRadio = styled(Form.Check)`
  input[type="checkbox"],
  input[type="radio"] {
    background-color: ${colors.black};
    border-color: ${colors.black};
    transition: all 0.3s ease-in;

    &:not(:checked) {
      background-color: ${colors.white};
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem ${colors.lightGrey};
    }
  }

  label {
    cursor: pointer;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    box-shadow: 2px 2px 20px 0px #000;

    .modal-body {
      padding: 1.4rem 2.5rem 2.5rem;
    }
  }
`;

const StyledCarouselWrapper = styled.div`
  @media screen and (max-width: ${styles.breakpoints.medium}) {
    padding: 0;

    .slick-arrow {
      &.slick-prev {
        /* margin-left: 25px; */
      }
      &.slick-next {
        /* margin-right: 25px; */
      }
    }

    .slick-list {
      padding: 1.5rem 0 !important;

      .slick-track {
        justify-content: space-between;
        gap: 10px;

        .slick-slide {
          flex: 1;
          min-height: 0;
          display: flex !important;
          height: auto !important;
          margin-left: 0 !important;

          > div {
            display: flex;
            flex: 1;

            > div {
              flex: 1;
              display: flex;

              @media screen and (max-width: ${styles.breakpoints.medium}) {
                display: block;
              }

              .closing-soon-item {
                flex: 1;
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    padding: 0;
  }

  .slick-track {
    display: flex;
    > .slick-slide:not(:first-child) {
      margin-left: 20px;
    }

    > .slick-slide {
      flex: 1 0 auto;
    }
  }

  .slick-arrow {
    z-index: 99;

    &.slick-prev {
      left: 0 !important;
    }

    &.slick-next {
      right: 0 !important;
      transform: translate(-50%, -50%);
    }

    &::before {
      font-size: 2rem;
      color: darkblue;
    }
  }
`;

const StyledSpinner = (props: SpinnerProps) => {
  return (
    <div className="spinner-container">
      <Spinner></Spinner>
    </div>
  );
};

const StyledFullScreenSpinnerContainer = styled.div<{
  show: boolean;
  initialLoad?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.show ? "block" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: all 0.5s linear;
  background: ${(props) => (props.initialLoad ? colors.white : "#c2c5cd8f")};
  z-index: 1057;

  .spinner-container {
    position: sticky;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const AppSpinner = (
  props: SpinnerProps & { show: boolean; initialLoad?: boolean }
) => {
  return (
    <StyledFullScreenSpinnerContainer
      show={props.show}
      initialLoad={props.initialLoad}
      className="spinner-cont"
    >
      <StyledSpinner {...props} />
    </StyledFullScreenSpinnerContainer>
  );
};

const StyledMaskedImageWrapper = styled.div<{ src: any }>`
  width: 24px;
  height: 24px;
  mask-image: url(${(props) => props.src});
  mask-size: contain;
  mask-repeat: no-repeat;
`;

const MaskedImage = (props: { src: any }) => {
  return (
    <StyledMaskedImageWrapper
      className="mask-container"
      src={props.src}
    ></StyledMaskedImageWrapper>
  );
};

const ContainerCard = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 2px 15px -5px ${colors.accent};
  border-radius: 15px;
  font-weight: 600;
`;

const ProductCardWrapper = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    flex-direction: row;
    padding: 0 !important;
    border-radius: 20px;
    overflow: hidden;
  }
`;

const ProductImageWrapper = styled.div`
  height: auto;
  border-radius: 15px;
  overflow: hidden;
  min-height: 100px;
  flex: 0 25%;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    max-width: 125px;
    width: 125px;
    max-height: 135px;
    min-height: 135px;
    flex: 1;
    border-radius: 18px;
  }
`;

export {
  FullWidthImage,
  StyledButton,
  StyledModal,
  StyledCarouselWrapper,
  AppSpinner,
  MaskedImage,
  SyledFormCheckOrRadio,
  ContainerCard,
  ProductCardWrapper,
  ProductImageWrapper,
};
