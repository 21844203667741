import { useEffect, useState } from "react";
import { Form, Modal, ModalProps } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledButton,
  StyledModal,
} from "./common-styled-components/styled-components";
import { colors } from "./variables";
import { UserRegistration } from "models/AuthUser";
import { useForm } from "react-hook-form";
import {
  loginUser,
  useVerifyRegistrationOTPMutation,
} from "features/auth/auth-slice";
import { toast } from "react-toastify";
import { useAppDispatch } from "app/hooks";
import { apiSlice } from "features/api-slice";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

export const VerifyOTPComponent = (
  props: { registrationPayload: UserRegistration } & ModalProps
) => {
  const { registrationPayload, ...modalProps } = props;
  let [showResend, setShowResend] = useState(false);
  const { formState, register, handleSubmit } = useForm<{ otp: string }>();
  const [verifyOTP] = useVerifyRegistrationOTPMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setShowResend(true);
    }, 60000);
  }, []);

  const onVerify = async (data: { otp: string }) => {
    const res = await verifyOTP({
      email: props.registrationPayload.emailAddress,
      OTP: data.otp,
    }).unwrap();

    if (res === true) {
      dispatch(apiSlice.util.invalidateTags(["User"]));
      dispatch(
        loginUser({
          emailAddress: props.registrationPayload.emailAddress,
          password: props.registrationPayload.password,
        })
      );
    } else {
      toast.error("Invalid OTP. Please try again");
    }
  };

  return (
    <StyledModal
      {...modalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="fw-semibold">{t(Translations.VerifyMobileNumber)}</h5>

        <p>{t(Translations.EnterVerificationCode)}</p>

        <Form onSubmit={handleSubmit(onVerify)}>
          <Form.Group className="mb-3" controlId="otp">
            <StyledFormControl
              type="text"
              placeholder="OTP"
              maxLength={6}
              {...register("otp", {
                required: true,
                minLength: 6,
                maxLength: 6,
              })}
            />
          </Form.Group>
          <div className="form-group submit">
            <StyledButton
              type="submit"
              color={colors.white}
              backgroundColor={colors.colorMain}
              borderRadius="10px"
              disabled={!formState.isValid}
            >
              {t(Translations.Verify)}
            </StyledButton>
          </div>
        </Form>

        {showResend ? (
          <p className="link-login">
            Didn't receive the code? <span className="link">Resend</span>
          </p>
        ) : null}
      </Modal.Body>
    </StyledModal>
  );
};
