import close from "assets/images/icons/close-x.svg";
import { Campaign } from "models/Campaign";
import { Product } from "models/Product";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { FullWidthImage } from "./common-styled-components/styled-components";
import { ImageComponent } from "./image";
import { colors } from "./variables";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";

const StyledModalContainer = styled(Modal.Body)`
  --border-radius: 7px;

  .campaign-container {
    position: relative;
    min-height: 250px;

    .image-container {
      margin: auto;
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      align-items: end;
      gap: 10px;

      .action {
        width: 50px;
        border: 1px solid ${colors.accent};
        border-radius: 10px;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  .offer-and-product {
    .offer-details-tab {
      text-align: center;

      .closing-info {
        width: 15rem;
        display: flex;
        align-items: center;
        border: 1px solid ${colors.accent};
        border-radius: 10px;

        .image-container {
          width: 30px;
          height: 30px;
        }

        .info-container {
          .title {
            font-size: 0.7rem;
            font-weight: 500;
          }
          .date {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;

const ProductDetails = (props: { product: Product }) => {
  const productName = useTranslatedLabel(props.product, "productName");
  const productDescription = useTranslatedLabel(props.product, "description");

  return (
    <>
      <h4 className="mb-2 fw-bold">{productName}</h4>
      <p className="mb-4">{productDescription}</p>
    </>
  );
};

export const ProductDetailsModalComponent = (props: {
  product: Product;
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledModalContainer>
        <div className="campaign-container mb-3">
          <div className="image-container">
            <ImageComponent
              src={`${process.env.REACT_APP_API_URL}/image/${props.product.imageName}`}
            ></ImageComponent>
          </div>

          <div className="actions">
            <div className="close action" onClick={props.onHide}>
              <FullWidthImage src={close}></FullWidthImage>
            </div>
          </div>
        </div>

        <div className="offer-and-product">
          <div className="offer-details-tab">
            <ProductDetails product={props.product}></ProductDetails>
          </div>
        </div>
      </StyledModalContainer>
    </Modal>
  );
};
