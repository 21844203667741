import React from "react";
import styled from "styled-components";
import "../../styles/_variables.scss";
import { colors } from "../../components/variables";

const ProgressAnchor = styled.div`
  position: relative;
  width: 100%;
`;
// Progress from 0 - 1
const Progress = styled.div<{ progress?: number; position?: string }>`
  height: 10px;
  width: ${(props) => (props.progress ? props.progress * 100 + "%" : "100%")};
  background-color: ${(props) =>
    props.progress
      ? getColorForProgress(props.progress)
      : colors.backgroundColor};
  position: ${(props) => props.position || "initial"};
  top: 0;
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.progress
        ? getColorForProgress(props.progress)
        : colors.backgroundColor};
`;

export const LinearProgress = (props: { progress: number }) => {
  return (
    <ProgressAnchor className="linear-progress">
      <Progress></Progress>
      <Progress position="absolute" progress={props.progress}></Progress>
    </ProgressAnchor>
  );
};

// returns green -> red from 0 -> 1
function getColorForProgress(progress: number) {
  var hue = ((1 - progress) * 120).toString(10);
  return ["hsl(", hue, ",70%,40%)"].join("");
}
