import { TicketCard } from "components/ticket-card";
import { colors, styles } from "components/variables";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { Order } from "models/Oder";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledAccordionHeader = styled(Accordion.Header)`
  background-color: transparent;
  position: relative;

  .accordion-button {
    color: ${colors.black};
    font-weight: 600;
    &:not(.collapsed) {
      box-shadow: unset;
      background-color: ${colors.backgroundColor};
    }
  }

  .ticket-count {
    position: absolute;
    right: 10%;
    font-size: 0.75rem;

    @media screen and (min-width: ${styles.breakpoints.medium}) {
      right: 5%;
    }
  }
`;

const StyledAccordionBody = styled(Accordion.Body)`
  background-color: ${colors.backgroundColor};
  padding-top: 0;
`;

const StyledTicketGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;

  .ticket {
    margin-bottom: 1rem;
    flex: 1;

    @media screen and (min-width: ${styles.breakpoints.medium}) {
      flex: 0 calc(50% - 1rem);
    }
  }
`;

export const UserTicketAccordion = (props: {
  order: Order;
  cartId: number;
  className?: string;
}) => {
  const campaign = props.order.cart.find(
    (c) => c.id === props.cartId
  )?.campaign;
  const product = campaign?.product;
  const { t } = useTranslation();
  const productName = useTranslatedLabel(product, "productName");

  const tickets = props.order.tickets
    .filter((t) => t.productId === product?.id)
    .map((t) => ({ ...t, product: product }));

  return (
    <Accordion defaultActiveKey="0" className="mb-4">
      <Accordion.Item eventKey={props.cartId.toString()}>
        <StyledAccordionHeader>
          {productName}{" "}
          <span className="ticket-count">{tickets.length} Tickets</span>
        </StyledAccordionHeader>
        <StyledAccordionBody>
          <StyledTicketGrid>
            {tickets.map((t, i) => (
              <div className="ticket" key={i}>
                <TicketCard
                  product={t.product!}
                  tickets={[t]}
                  offer={campaign!.mainOffer}
                ></TicketCard>
              </div>
            ))}
          </StyledTicketGrid>
        </StyledAccordionBody>
      </Accordion.Item>
    </Accordion>
  );
};
