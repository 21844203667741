import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { StyledCarouselWrapper } from "components/common-styled-components/styled-components";
import { ProductCardSmall } from "components/product-card-small";
import { sliderResponsiveBreakPoints } from "components/variables";
import {
  useAddToCartMutation,
  useFetchCartQuery,
  useRemoveFromCartMutation,
} from "features/api-slice";
import { Campaign } from "models/Campaign";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const ClosingSoon = (props: { campaigns: Campaign[] }) => {
  const sliderSettings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    responsive: sliderResponsiveBreakPoints,
  };

  const [addToCart, { isLoading: addingToCart }] = useAddToCartMutation({
    fixedCacheKey: "shared-cart",
  });
  const [removeFromCart, { isLoading: removingFromCart }] =
    useRemoveFromCartMutation();

  const onAddToCart = (id: number) => {
    if (!(addingToCart || removingFromCart)) {
      addToCart({ campaignId: id, numberOfUnits: 1 });
    }
  };

  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: cart } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [campaignsInCart, setCampaignsInCart] = useState<Map<number, number>>(
    new Map<number, number>([])
  );

  useEffect(() => {
    const map = new Map<number, number>();
    cart?.forEach((item) => {
      map.set(item.campaignId, item.numberOfUnits);
    });
    setCampaignsInCart(map);
  }, [cart]);

  return (
    <StyledCarouselWrapper>
      <Slider {...sliderSettings}>
        {/* filter by item limit */}
        {props.campaigns.map((campaign, index) => (
          <ProductCardSmall
            key={"product-card-container" + index}
            campaign={campaign}
            inCartCount={campaignsInCart.get(campaign.id) || 0}
            removefromCart={removeFromCart}
            addToCart={onAddToCart}
          ></ProductCardSmall>
        ))}
      </Slider>
    </StyledCarouselWrapper>
  );
};
