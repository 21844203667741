import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .loader {
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageComponent = (props: { src: string; className?: string }) => {
  const { src } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  return (
    <StyledImageWrapper className={`${props.className ?? ""}`}>
      {loaded ? null : (
        <div className="loader">
          <Spinner animation="grow" variant="warning" />
        </div>
      )}
      <img
        className="image"
        style={loaded ? {} : { display: "none" }}
        src={src}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(true)}
      />
    </StyledImageWrapper>
  );
};
