import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as Menu } from "assets/images/icons/menu-icon.svg";
import { ContainerCard } from "components/common-styled-components/styled-components";
import { apiSlice, useFetchUserDetailsQuery } from "features/api-slice";
import { UserNavigation } from "pages/user/user-navigation";
import { UserProfileImage } from "pages/user/user-profile-image";
import { useEffect, useState } from "react";
import styled from "styled-components";

import locationImg from "assets/images/icons/location.svg";
import logoutImg from "assets/images/icons/logout.svg";
import settings from "assets/images/icons/settings.svg";
import userImg from "assets/images/icons/user.svg";
import heart from "assets/images/icons/heart.svg";
import creditCard from "assets/images/icons/credit-card.svg";
import { NavLinkItem } from "models/NavLinkItem";
import { logout } from "features/auth/auth-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, styles } from "components/variables";
import { MobileAuth } from "./mobile-auth";
import ticket from "assets/images/icons/ticket.svg";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledMobileMenuWrapper = styled.div`
  .menu-container {
    touch-action: none;
    position: absolute;
    top: calc(100% + 25px); // header height + margin
    width: 100%;
    left: 110%;
    z-index: 100;
    height: calc(
      100svh - 100% - 50px
    ); // full viewport height - header height - margin
    transition: left 0.2s ease-in-out;

    &.visible {
      left: 0;
      transition: left 0.2s ease-in-out;

      .menu-mask {
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
    }

    .menu-mask {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: calc(100% + 25px); // header height + margin
      left: 0;
      z-index: -1;
      background: ${colors.backgroundColor};
      opacity: 0;
      transition: opacity 0.2s ease-in;
    }

    .menu-content {
      height: 100%;
      padding: 1rem 0;
      box-shadow: ${styles.boxShadow};
      overflow-y: auto;
    }
  }
`;

const StyledNavComponent = styled(UserNavigation)`
  padding: 1rem 0 !important;

  .link {
    font-weight: 600;
    &.active {
      background-color: unset !important;
      color: ${colors.colorMain} !important;

      .mask-container {
        background-color: ${colors.colorMain};
      }
    }
  }
`;

export const MobileMenu = (props: {
  className?: string;
  menuToggled?: boolean;
  onMenuToggle: (opened?: boolean) => void;
}) => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: user } = useFetchUserDetailsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onMenuToggle = (isToggled: boolean) => {
    props.onMenuToggle(isToggled);
    // Add overflow hidden to prevent background scrolling when the menu is opened
    if (isToggled) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  // If user clicks on a navigation item, hide the menu
  useEffect(() => {
    onMenuToggle(false);
  }, [location]);

  const onLogout = () => {
    dispatch(apiSlice.util.invalidateTags(["SoldOut"]));
    dispatch(logout());
    navigate("/");
    onMenuToggle(false);
  };

  const navLinks: NavLinkItem[] = [
    {
      to: "/user/edit",
      title: t(Translations.PersonalDetails),
      icon: userImg,
    },
    {
      to: "/user/wishlist",
      title: t(Translations.Wishlist),
      icon: heart,
    },
    {
      to: "/user/tickets",
      title: t(Translations.PaymentsActiveTickets),
      icon: ticket,
    },
    {
      to: "/user/settings",
      title: t(Translations.Settings),
      icon: settings,
    },
    {
      title: t(Translations.Logout),
      icon: logoutImg,
      onClick: onLogout,
    },
  ];

  return (
    <StyledMobileMenuWrapper>
      <div className="menu-btn-container">
        <Menu onClick={() => onMenuToggle(!props.menuToggled)}></Menu>
      </div>

      <div className={`menu-container ${props.menuToggled ? "visible" : ""}`}>
        <div className="menu-mask"></div>
        <ContainerCard className="menu-content">
          {isLoggedIn ? (
            <>
              <div className="profile-image">
                <UserProfileImage></UserProfileImage>
              </div>

              <div className="nav-links mx-3 mt-4">
                <StyledNavComponent items={navLinks}></StyledNavComponent>
              </div>
            </>
          ) : (
            <MobileAuth onMenuToggle={onMenuToggle} />
          )}
        </ContainerCard>
      </div>
    </StyledMobileMenuWrapper>
  );
};
