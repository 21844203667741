import { useForm } from "react-hook-form";
import "./styles.scss";
import { usePostFeedackMutation } from "features/user/user-slice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

export type Feedback = {
  name: string;
  email: string;
  message: string;
};

export const ContactUs = () => {
  const { register, handleSubmit, formState } = useForm<Feedback>();
  const [postFeedack] = usePostFeedackMutation();
  const { t } = useTranslation();

  const onPostFeedback = async (data: Feedback) => {
    await postFeedack(data).unwrap();
    toast.success("Feedback submitted");
  };

  return (
    <div className="main-contact mt-5 mb-5">
      <div className="contact-us-main">
        <div className="contact-us-half">
          <h1>{t(Translations.ContactUs)}</h1>
          <p>{t(Translations.ContactFormDescription)}</p>
          <form className="contact-us" onSubmit={handleSubmit(onPostFeedback)}>
            <div className="form-column">
              <div className="input-field">
                <label className="form-control-label" data-label="Name">
                  Name
                </label>
                <input
                  id="contact-name"
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  {...register("name", { maxLength: 250, required: true })}
                />
              </div>
              <div className="input-field">
                <label className="form-control-label" data-label="Email">
                  {t(Translations.Email)}
                </label>
                <input
                  id="contact-email"
                  className="form-control"
                  type="email"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="input-field">
                <label className="form-control-label" data-label="Message">
                  {t(Translations.Message)}
                </label>
                <textarea
                  id="contact-message"
                  className="materialize-textarea form-control "
                  rows={5}
                  cols={5}
                  aria-required="true"
                  {...register("message", { required: true, maxLength: 5000 })}
                ></textarea>
              </div>
            </div>
            <div className="btn-section">
              <button
                className="btn-contact-us"
                type="submit"
                disabled={!formState.isValid}
              >
                {t(Translations.SendMessage)}
              </button>
            </div>
          </form>
        </div>
        <div className="contact-us-half withBg">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15842.588076660715!2d79.8436359!3d6.9327244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae259e4bed8a237%3A0x165bfc5fa3414740!2sCloud%20Syntex%20(Pvt)%20Ltd.!5e0!3m2!1sen!2slk!4v1699891864273!5m2!1sen!2slk"
              width="100%"
              height="280"
              allowFullScreen={undefined}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contact-us-details drop-shadow">
            <h2 className="contact-title">Lanka Dealz Headquarters</h2>
            <a
              className="contact-address"
              href="https://maps.app.goo.gl/QA7gUz86vhLZJJTm9"
              target="_blank"
            >
              Level 35, West Tower - World Trade Center,
              <br /> Colombo 01, Sri Lanka.
            </a>
            <ul className="contact-links clearfix">
              <li>
                <a className="icon-call-number" href="tel:+94123456789">
                  {" "}
                  <span>{t(Translations.CallUsNow)}</span> +94 123 456 789
                </a>
              </li>
              <li>
                <a
                  className="icon-email-address"
                  href="mailto:help@lankadealz.com"
                >
                  {" "}
                  <span>{t(Translations.WriteUsEmail)}</span>help@lankadealz.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
