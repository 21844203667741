import { ContainerCard } from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { WishlistPoductCard } from "components/wishlist-product-card";
import { useFetchWishlistQuery } from "features/api-slice";
import { Translations } from "i18n/i18n";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledWishlistProductCard = styled(WishlistPoductCard)`
  margin-bottom: 1.875rem;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    margin: 0 !important;
    margin-bottom: 1rem !important;
    padding: 0.8rem !important;
    background-color: ${colors.white};
  }
`;

const StyledContainerCard = styled(ContainerCard)`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    padding: 0 0.5rem !important;
    background-color: transparent;
    box-shadow: none;
  }

  .container {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }
`;

export const Wishlist = () => {
  const { data: wishlist } = useFetchWishlistQuery();
  const { t } = useTranslation();

  return (
    <StyledContainerCard className="p-4">
      <h4 className="text-start fw-bolder mb-3">
        {t(Translations.MyWishlist)}
      </h4>

      <div className="container px-0">
        {(wishlist || []).map((w) => (
          <StyledWishlistProductCard
            className="w-auto me-4"
            item={w}
            key={w.campaignId}
          ></StyledWishlistProductCard>
        ))}
      </div>
    </StyledContainerCard>
  );
};
