import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLinkComponent = styled(Link)`
  color: unset !important;
  text-decoration: none;
`;

export const StyledLink = (props: { to: string; children: ReactNode }) => {
  return (
    <StyledLinkComponent to={props.to}>{props.children}</StyledLinkComponent>
  );
};
