import amex from "assets/images/icons/amex.svg";
import genie from "assets/images/icons/genie.svg";
import mastercard from "assets/images/icons/mastercard.svg";
import visa from "assets/images/icons/visa.svg";
import styled from "styled-components";

const StyledIconWrapper = styled.div``;

export const PaymentMethods = (props) => {
  return (
    <StyledIconWrapper {...props}>
      <div className="payment">
        <img src={genie}></img>
      </div>
      <div className="payment">
        <img src={visa}></img>
      </div>
      <div className="payment">
        <img src={mastercard}></img>
      </div>
      <div className="payment">
        <img src={amex}></img>
      </div>
    </StyledIconWrapper>
  );
};
