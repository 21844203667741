import { FullWidthImage } from "components/common-styled-components/styled-components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import cameraIcon from "assets/images/icons/camera_alt.svg";
import {
  useFetchUserDetailsQuery,
  useUploadProfileImageMutation,
} from "features/api-slice";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import styled from "styled-components";
import { colors, styles } from "components/variables";
import { toSentenceCase } from "shared/utils/utils";

const StyledUserDetailsWrapper = styled.div`
  .image-container {
    width: 145px;
    height: 145px;
    margin: auto;
    margin-bottom: 1rem;
    position: relative;

    .image {
      width: 100%;
      height: 100%;
      background-color: ${colors.darkGrey};
      border-radius: 50%;
      border: 2px solid ${colors.mainRed};
    }

    .upload-image {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: ${colors.mainRed};
      border-radius: 50%;
      cursor: pointer;
      display: flex;
    }
  }

  .details {
    h5 {
      font-family: "Rubik", sans-serif;
      font-size: 20px;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    div {
      font-family: "Rubik", sans-serif;
    }
  }
`;

export const UserProfileImage = (props) => {
  const fileUpload = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: user } = useFetchUserDetailsQuery(undefined, {
    skip: !isLoggedIn,
  });

  const [
    uploadImage,
    { isLoading: isUploadingImage, isSuccess: isImageUploaded },
  ] = useUploadProfileImageMutation();

  const uploadUserImage = (file: Blob) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadImage(formData);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;
    if (files) {
      setFile(files[0]);
      uploadUserImage(files[0]);
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);
      reader.onloadend = (e: any) => {
        // setFileUrl(reader.result as string);
      };
    }
  };

  const getUserName = () => {
    const firstName = toSentenceCase(user?.firstName!);
    const lastName = toSentenceCase(user?.lastName!);

    return `${firstName} ${lastName}`;
  };

  return (
    <StyledUserDetailsWrapper {...props.className}>
      <div className="image-container">
        {!!user?.profilePicture ? (
          <FullWidthImage
            className="image"
            src={`${process.env.REACT_APP_API_URL}/image/${user.profilePicture}`}
          ></FullWidthImage>
        ) : (
          <div className="image"></div>
        )}

        <div
          className="upload-image p-2"
          onClick={() => {
            fileUpload.current?.click();
          }}
        >
          <FullWidthImage src={cameraIcon}></FullWidthImage>
        </div>

        <input
          className="d-none"
          type="file"
          onChange={(e) => handleChange(e)}
          ref={fileUpload}
        />
      </div>
      <div className="details">
        <h5 className="name">{getUserName()}</h5>
        <div className="email">{user?.email}</div>
      </div>
    </StyledUserDetailsWrapper>
  );
};
