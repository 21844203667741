import {
  ContainerCard,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { colors } from "components/variables";
import { VerifyPhoneChangeOTPComponent } from "components/verify-phone-change-otp";
import {
  useFetchUserDetailsQuery,
  useUpdateUserDetailsMutation,
} from "features/api-slice";
import { useChangePhoneMutation } from "features/user/user-slice";
import { Translations } from "i18n/i18n";
import { DateTime } from "luxon";
import { User } from "models/User";
import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledPersonalDetailsWrapper = styled(ContainerCard)`
  h4 {
    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 600;
  }
  input {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 14px;
    height: 50px;
  }
  .dropdown-toggle.btn {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 15px;
    border-color: #ced4da;
  }

  input::placeholder {
    font-weight: 500;
  }

  .form-label {
    color: ${colors.lightGrey};
    margin: 0;
    font-size: 12px;
  }

  .btn.show {
    background-color: ${colors.white} !important;
  }

  .form-select {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 14px;
    height: 50px;
  }

  .form-check-input:checked {
    background-color: ${colors.black} !important;
    border-color: ${colors.black} !important;
  }

  .form-check {
    padding-top: 5px;
    margin-right: 40px;

    input {
      padding: 0 !important;
      position: relative;
      top: -1px;
      height: 14px;
      width: 14px;
    }
  }
`;

interface UserDetailsForm extends User {
  dateOfBirthObject: DOB;
}

type DOB = {
  day: string;
  month: string;
  year: string;
};

function getDaysInMonth(month: number, year: number) {
  var date = new Date(year, month, 1);
  var days: number[] = [];
  while (date.getMonth() === month) {
    days.push(new Date(date).getDate());
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export const PersonalDetails = () => {
  const { data: user, isLoading } = useFetchUserDetailsQuery();
  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const [updateUserPhone] = useChangePhoneMutation();
  const { t } = useTranslation();

  const { register, setValue, handleSubmit, getValues, watch } =
    useForm<UserDetailsForm>({});

  const [showOTP, setShowOTP] = useState(false);

  const today = new Date();
  const [validDates, setValidDates] = useState<number[]>(
    getDaysInMonth(today.getMonth(), today.getDate())
  );

  const onSubmit = async (form: UserDetailsForm) => {
    const userData: User = { ...form };
    if (
      form.dateOfBirthObject.year !== "-1" &&
      form.dateOfBirthObject.month !== "-1" &&
      form.dateOfBirthObject.day !== "-1"
    ) {
      const date = new Date(
        Number(form.dateOfBirthObject.year),
        Number(form.dateOfBirthObject.month),
        Number(form.dateOfBirthObject.day)
      );

      const dateFormatted = DateTime.fromJSDate(date).toFormat("yyyy-LL-dd");
      userData.dob = dateFormatted;
    }
    const res = await updateUserDetails(userData).unwrap();

    if (res.phoneChanged) {
      setShowOTP(true);
    }
  };

  const dateOfBirthStartYear = new Date().getFullYear() - 150;
  const dateOfBirthYears = Array.from(
    { length: 150 },
    (_, i) => i + dateOfBirthStartYear
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === "dateOfBirthObject.month" ||
        name === "dateOfBirthObject.year"
      ) {
        const year = getValues("dateOfBirthObject.year");
        const month = getValues("dateOfBirthObject.month");

        const days = getDaysInMonth(Number(month), Number(year));
        setValidDates(days);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (user) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      setValue("email", user.email);
      setValue("countryCode", user.countryCode);
      setValue("gender", user.gender);
      setValue("phoneNumber", user.phoneNumber);
      setValue("nic", user.nic);

      if (user.dob) {
        const dateOfBirth = new Date(user.dob);

        setValue(
          "dateOfBirthObject.year",
          dateOfBirth.getFullYear().toString()
        );
        setValue("dateOfBirthObject.month", dateOfBirth.getMonth().toString());
        setValue("dateOfBirthObject.day", dateOfBirth.getDate().toString());
      }
    }
  }, [user]);

  const verifyPhoneChangeOtp = async (form: { otp: string }) => {
    const phone = getValues("phoneNumber");

    await updateUserPhone({ phone, otp: form.otp }).unwrap();
    toast.success("Phone updated successfully");
    setShowOTP(false);
  };

  return (
    <StyledPersonalDetailsWrapper className="p-4">
      <h4 className="text-start mb-3">Personal details</h4>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div className="container text-start p-0 mb-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label>{t(Translations.FirstName)}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(Translations.FirstName)}
                    {...register("firstName", { required: true })}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="lName">
                  <Form.Label>{t(Translations.LastName)}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(Translations.LastName)}
                    {...register("lastName", { required: true })}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="container text-start p-0 mb-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Label htmlFor="mobile-number">
                  {t(Translations.MobilePhone)}
                </Form.Label>
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title="+94"
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Item href="#">+94</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    id="mobile-number"
                    placeholder={t(Translations.MobilePhone)}
                    aria-label={t(Translations.MobilePhone)}
                    maxLength={9}
                    {...register("phoneNumber", {
                      required: true,
                      maxLength: 9,
                      minLength: 9,
                    })}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="nic">
                  <Form.Label>{t(Translations.MICPassportNumber)}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(Translations.MICPassportNumber)}
                    {...register("nic", {
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                    })}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="container text-start p-0 mb-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>{t(Translations.DateOfBirth)}</Form.Label>

                  <div className="row">
                    <div className="col-4">
                      <Form.Select
                        {...register("dateOfBirthObject.year", {
                          required: true,
                        })}
                        defaultValue="-1"
                      >
                        <option disabled value={"-1"}>
                          Year
                        </option>
                        {dateOfBirthYears
                          .map((year) => (
                            <option value={year} key={year}>
                              {year}
                            </option>
                          ))
                          .reverse()}
                      </Form.Select>
                    </div>
                    <div className="col-4">
                      <Form.Select
                        {...register("dateOfBirthObject.month", {
                          required: true,
                        })}
                        defaultValue="-1"
                      >
                        <option disabled value={"-1"}>
                          Month
                        </option>
                        {months.map((month, index) => (
                          <option value={index} key={index}>
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="col-4">
                      <Form.Select
                        {...register("dateOfBirthObject.day", {
                          required: true,
                        })}
                        defaultValue="-1"
                      >
                        <option disabled value={"-1"}>
                          Day
                        </option>
                        {validDates.map((date) => (
                          <option value={date} key={date}>
                            {date}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="col-12 col-md-6">
                <Form.Label>{t(Translations.Gender)}</Form.Label>
                <div className="row">
                  <div className="col mt-2">
                    <Form.Check
                      inline
                      label={t(Translations.Male)}
                      type="radio"
                      value="Male"
                      {...register("gender")}
                    />
                    <Form.Check
                      inline
                      label={t(Translations.Female)}
                      type="radio"
                      value="Female"
                      {...register("gender")}
                    />
                    <Form.Check
                      inline
                      label={t(Translations.Other)}
                      type="radio"
                      value="Other"
                      {...register("gender")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 pt-5 mb-2">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 form-btn">
                <StyledButton color={colors.white} borderRadius="7px">
                  {t(Translations.UpdatedProfile)}
                </StyledButton>
              </div>
            </div>
          </div>
        </fieldset>
      </Form>

      <VerifyPhoneChangeOTPComponent
        show={showOTP}
        onHide={() => {}}
        changePhoneProps={{
          verify: verifyPhoneChangeOtp,
        }}
      ></VerifyPhoneChangeOTPComponent>
    </StyledPersonalDetailsWrapper>
  );
};
