import {
  ContainerCard,
  FullWidthImage,
  ProductCardWrapper,
  ProductImageWrapper,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { PaymentMethods } from "components/payment-methods";
import { colors, styles } from "components/variables";
import {
  useAddToCartMutation,
  useFetchCartQuery,
  useFetchUserDetailsQuery,
  useProcessCheckoutMutation,
  useRemoveFromCartMutation,
} from "features/api-slice";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";
import { Totals } from "./totals";
import { ItemCounter } from "components/item-counter";
import { useNavigate } from "react-router-dom";

import { ClosingTimer } from "components/closing-timer";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";
import { Campaign } from "models/Campaign";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
const StyledCheckoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 3rem;
  min-height: 0;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    flex-direction: column;
    margin-bottom: 5rem !important;
  }
`;

const StyledCartItemsWrapper = styled.div`
  flex: 1 70%;
  display: flex;
  flex-direction: column;

  .scroll-container {
    /*overflow: auto;
    min-height: 0;
    flex: 1;
    scrollbar-gutter: stable;*/
  }
`;
const StyledRightContentWrapper = styled.div`
  flex: 1 25%;
`;
const StyledCardWrapper = styled(ContainerCard)`
  @media screen and (max-width: ${styles.breakpoints.medium}) {
    margin: 0 !important;
    margin-bottom: 1rem !important;
    padding: 0.8rem !important;
    background-color: ${colors.white};

    ${ProductImageWrapper} {
      flex: 1 auto !important;
    }
  }
`;
const StyledPaymentMethodsWrapper = styled(StyledCardWrapper)`
  @media screen and (max-width: ${styles.breakpoints.medium}) {
    margin-bottom: 3rem !important;
  }

  .payment-method-title {
    text-align: left;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .payment-methods {
    .icon-container {
      gap: 5px;
    }
  }

  .link-user-details {
    font-size: 12px;
  }
`;

const StyledPromoCodeWrapper = styled.div``;

const StyledProductCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 40%;
  text-align: left;
  justify-content: center;

  .title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.3px;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      font-size: 13px !important;
      margin-bottom: 5px;
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 500;
    margin: 0;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      font-size: 10px !important;
    }
  }

  .price {
    h4 {
      color: ${colors.colorMain};
      font-size: 1.2rem;
      font-weight: 600;
      font-family: "Rubik", sans-serif;
      letter-spacing: -0.5px;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        font-size: 12px !important;
        font-weight: 600;
      }
    }
  }
  .closing-time-container .closing-time {
    display: none !important;
  }
`;

const StyledPrductCardActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 30%;

  .btn-container {
    height: 3.5rem;
    display: flex;
    gap: 0.5rem;
    flex: 100%;

    button {
      font-weight: 700;

      &.mini {
        max-width: 50px;
      }

      &.count {
        cursor: default;
      }
    }
  }
`;

const StyledItemCounter = styled(ItemCounter)`
  padding: 1.2rem 0;
`;

const StyledPaymentButton = styled.div`
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  left: 0;
  box-shadow: ${styles.boxShadow};
  display: flex;
  justify-content: space-between;
  padding: 1rem 3.5rem;

  .total {
    font-weight: 600;
    text-align: left;

    .title {
      font-size: 10px;
    }

    .price {
      font-size: 1rem;
    }
  }

  .button-container {
  }
`;

export const CheckoutPage = () => {
  const navigate = useNavigate();
  const { data: cartItems } = useFetchCartQuery();
  const [cartTotal, setCartTotal] = useState(0);
  const [processCheckout] = useProcessCheckoutMutation();
  const [
    addToCart,
    { isLoading: isAddingToCart, isSuccess: addToCartSuccess },
  ] = useAddToCartMutation({ fixedCacheKey: "shared-cart" });
  const [removeFromCart, { isLoading: isRemovingFromCart }] =
    useRemoveFromCartMutation();
  const { data: user } = useFetchUserDetailsQuery();
  const { t } = useTranslation();

  useEffect(() => {
    const total = cartItems?.reduce((aggr, cart) => {
      return aggr + (cart.total || 0);
    }, 0);
    setCartTotal(total || 0);
  }, [cartItems]);

  const addOne = (id: number) => {
    if (isAddingToCart || isRemovingFromCart) {
      return;
    }
    addToCart({ campaignId: id, numberOfUnits: 1 });
  };

  const removeOne = (id: number) => {
    if (isAddingToCart || isRemovingFromCart) {
      return;
    }
    removeFromCart(id);
  };

  const checkout = async () => {
    const res = await processCheckout().unwrap();
    window.location.replace(res.checkoutURL);
  };

  const gotoUserPage = () => {
    navigate("/user");
  };

  return (
    <StyledCheckoutWrapper className="mb-3">
      <StyledCartItemsWrapper>
        <div className="scroll-container">
          {cartItems?.map((item) => (
            <StyledCardWrapper className="p-4 mb-3" key={item.id}>
              <ProductCardWrapper>
                <ProductImageWrapper>
                  <FullWidthImage
                    src={`${process.env.REACT_APP_API_URL}/image/${item.campaign.campaignImage}`}
                  ></FullWidthImage>
                </ProductImageWrapper>

                <StyledProductCardDetailsWrapper className="ms-4 pe-4">
                  <div className="title-and-desc">
                    <TranslatedTitleAndDescription
                      campaign={item.campaign}
                    ></TranslatedTitleAndDescription>
                  </div>
                  <div className="price mt-3">
                    <h4>{getPriceFromNumber(item.total)}</h4>
                  </div>
                  <div className="closing-time-container">
                    <ClosingTimer
                      closingDate={item.campaign.closingDate}
                      title="Deal closing in"
                    ></ClosingTimer>
                  </div>
                </StyledProductCardDetailsWrapper>

                <StyledPrductCardActionsWrapper className="d-none d-md-flex">
                  <div className="btn-container">
                    <StyledButton
                      backgroundColor={colors.darkGrey}
                      borderRadius="10px"
                      className="p-4 mini "
                      onClick={() => removeOne(item.campaignId)}
                      disabled={isAddingToCart || isRemovingFromCart}
                    >
                      -
                    </StyledButton>
                    <StyledButton
                      backgroundColor={colors.darkGrey}
                      borderRadius="10px"
                      className="p-4 mini count"
                    >
                      {item.numberOfUnits}
                    </StyledButton>
                    <StyledButton
                      backgroundColor={colors.colorMain}
                      borderRadius="10px"
                      color={colors.white}
                      disabled={isAddingToCart || isRemovingFromCart}
                      onClick={() => addOne(item.campaignId)}
                    >
                      {t(Translations.AddMore)}
                    </StyledButton>
                  </div>
                </StyledPrductCardActionsWrapper>

                <StyledItemCounter
                  className="d-flex d-md-none"
                  cartItem={item}
                ></StyledItemCounter>
              </ProductCardWrapper>
            </StyledCardWrapper>
          ))}
        </div>
      </StyledCartItemsWrapper>
      <StyledRightContentWrapper>
        <StyledCardWrapper className="mb-3">
          <Totals total={cartTotal} subtotal={cartTotal}></Totals>
        </StyledCardWrapper>

        <StyledCardWrapper className="mb-3" hidden>
          <StyledPromoCodeWrapper className="container">
            <div className="row">
              <div className="col-8">
                <Form.Control type="text"></Form.Control>
              </div>
              <div className="col-3">
                <StyledButton
                  border={`1px solid ${colors.colorMain}`}
                  borderRadius="7px"
                  lineHeight="normal"
                >
                  Apply
                </StyledButton>
              </div>
            </div>
          </StyledPromoCodeWrapper>
        </StyledCardWrapper>

        <StyledPaymentMethodsWrapper className="mb-3 p-3">
          <h3 className="payment-method-title mb-3">
            {t(Translations.PaymentMethod)}
          </h3>

          {!user?.nic || !user.phoneNumber ? (
            <StyledButton
              border={`1px solid ${colors.BuyRed}`}
              backgroundColor={colors.lightRed}
              color={colors.mainRed}
              borderRadius="7px"
              lineHeight="2"
              className="link-user-details mb-2 py-2"
              onClick={gotoUserPage}
            >
              Please fill in your NIC details to continue. Click here
            </StyledButton>
          ) : null}

          <StyledButton
            border={`1px solid ${colors.bluishGrey}`}
            backgroundColor={colors.backgroundColor}
            borderRadius="7px"
            lineHeight="normal"
            className="p-4 mb-3"
          >
            <div className="payment-methods">
              <PaymentMethods className="icon-container d-flex justify-content-center"></PaymentMethods>
            </div>
          </StyledButton>

          <StyledButton
            backgroundColor={colors.checkoutGreen}
            color={colors.white}
            borderRadius="7px"
            lineHeight="2.5"
            className="p-1 checkout-button d-none d-md-block"
            onClick={checkout}
            disabled={!user?.nic || !user.phoneNumber}
          >
            {t(Translations.ClickToPayByCard)}
          </StyledButton>
        </StyledPaymentMethodsWrapper>
      </StyledRightContentWrapper>

      <StyledPaymentButton className="d-block d-md-none">
        <div className="total">
          <div className="title">Total amount</div>
          <div className="price">{getPriceFromNumber(cartTotal)}</div>
        </div>
        <div className="button-container">
          <StyledButton
            className="px-4"
            color={colors.white}
            backgroundColor={colors.colorMain}
            borderRadius="10px"
            onClick={checkout}
            disabled={!user?.nic || !user.phoneNumber}
          >
            Proceed to payment →
          </StyledButton>
        </div>
      </StyledPaymentButton>
    </StyledCheckoutWrapper>
  );
};

const TranslatedTitleAndDescription = ({
  campaign,
}: {
  campaign: Campaign;
}) => {
  const campaignTitle = useTranslatedLabel(campaign, "campaignTitle");
  const campaignDescription = useTranslatedLabel(
    campaign,
    "campaignDescription"
  );
  return (
    <>
      <h3 className="title">{campaignTitle}</h3>
      <h4 className="desc">{campaignDescription}</h4>
    </>
  );
};
