import { CampaignPage } from "pages/campaign";
import { ProtectedPage } from "pages/protected-page";
import { Settings } from "pages/user/settings";
import { PersonalDetails } from "pages/user/personal-details";
import { UserPage } from "pages/user/user";
import { Wishlist } from "pages/user/wishlist";
import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/error/error-page";
import { Home } from "./pages/home/home";
import { Products } from "./pages/products/products";
import { Addresses } from "pages/user/addresses";
import { CheckoutPage } from "pages/checkout/checkout";
import { OrderConfirmation } from "pages/order-status/order-confirmation";
import { UserTickets } from "pages/user/user-tickets";
import { ResetPasswordComponent } from "pages/reset-password/reset-password";
import { PrivacyPolicy } from "pages/privacy-policy/privacy-policy";
import { UserAgreement } from "pages/user-agreement/user-agreement";
import { AboutUs } from "pages/about-us/about-us";
import { ContactUs } from "pages/contact-us/contact-us";
import { FAQ } from "pages/faq/faq";
import { HowItWorks } from "pages/how-it-works/how-it-works";

export const router = createBrowserRouter([
  {
    path: "",
    element: <App></App>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "",
        element: <Home></Home>,
      },
      {
        path: "products",
        element: <Products></Products>,
      },
      {
        path: "campaign/:id",
        element: <CampaignPage></CampaignPage>,
      },
      {
        path: "user",
        element: (
          <ProtectedPage>
            <UserPage></UserPage>
          </ProtectedPage>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="edit" replace></Navigate>,
          },
          {
            path: "edit",
            element: <PersonalDetails />,
          },
          {
            path: "wishlist",
            element: <Wishlist />,
          },
          {
            path: "tickets",
            element: <UserTickets />,
          },
          {
            path: "payments",
            element: <></>,
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "shipping",
            element: <Addresses />,
          },
        ],
      },
      {
        path: "reset-password/:email/:token",
        element: <ResetPasswordComponent></ResetPasswordComponent>,
      },
      {
        path: "checkout",
        element: (
          <ProtectedPage>
            <CheckoutPage></CheckoutPage>
          </ProtectedPage>
        ),
      },
      {
        path: "order",
        children: [
          {
            path: "",
            element: <Navigate to="/" />,
          },
          {
            path: "confirmation/:orderId",
            element: (
              <ProtectedPage>
                <OrderConfirmation></OrderConfirmation>
              </ProtectedPage>
            ),
          },
        ],
      },
      {
        path: "privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms",
        element: <UserAgreement />,
      },
      {
        path: "about-us",
        element: <AboutUs></AboutUs>,
      },
      {
        path: "contact-us",
        element: <ContactUs></ContactUs>,
      },
      {
        path: "faq",
        element: <FAQ></FAQ>,
      },
      {
        path: "how-it-works",
        element: <HowItWorks></HowItWorks>,
      },
    ],
  },
]);
