import {
  FullWidthImage,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { useState } from "react";
import soldOut from "assets/images/sold-out.svg";
import styled from "styled-components";
import { Winner } from "models/Winner";
import { DateTime } from "luxon";

const CardWrapper = styled.div`
  /*padding: 10px 10px 12px 10px !important;*/
  border-radius: 18px;
  background: white;
  border: none;
  box-shadow: ${styles.boxShadow};
  position: relative;
  width: auto;
  margin-bottom: 1.5rem;
  flex: 1;

  .name {
    color: ${colors.lightPurple};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: ${styles.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 10px 0 0;

    .name {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: ${styles.breakpoints.large}) {
    /*width: 265px;*/
  }

  .image-container {
    border-radius: 15px;
    overflow: hidden;
    height: 160px;
    position: relative;
  }
`;

export const CardWinner = (props: {
  winner: Winner;
  addToCart?: (id: number) => void;
}) => {
  const [x] = useState(Math.round(Math.random() * 10));
  const image = `${process.env.REACT_APP_API_URL}/image/${props.winner.offer.offerImage}`;

  return (
    <CardWrapper>
      <div className="winners-item">
        <div className="image-container">
          <FullWidthImage src={image}></FullWidthImage>
        </div>
        <h5 className="name mt-2 fw-bold mb-0">{`${props.winner.user.firstName} ${props.winner.user.lastName}`}</h5>
        <p className="won-item mb-2">
          On winning{" "}
          <span className="fw-bold">{props.winner.offer.offerTitle}</span>
        </p>
        <p className="subtext promo-code mb-0">
          Promotions code : {props.winner.ticketNumber}
        </p>
        <p className="subtext announced mb-0">
          Announced :{" "}
          {DateTime.fromISO(props.winner.announcedDate).toFormat(
            "dd LLLL yyyy"
          )}
        </p>
      </div>
    </CardWrapper>
  );
};
