import styled from "styled-components";
import { colors } from "./variables";

const BuyTextContainer = styled.div`
  width: 100%;
  .buy {
    font-family: "Rubik", sans-serif;
    font-size: 65px !important;
    line-height: 45px;
    letter-spacing: -2px;
    transform: none !important;
    color: ${colors.BuyRed} !important;
    font-style: italic;    
    margin: 0;
    font-weight: 800;
    margin: 0 0 10px 0;
  }
`;

export const BuyText = (props: { className?: string }) => {
  return (
    <BuyTextContainer
      className={`${props?.className ?? ""} buy-text-container`}
    >
      <h1 className="buy">Win</h1>
    </BuyTextContainer>
  );
};
