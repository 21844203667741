import {
  FullWidthImage,
  ProductCardWrapper,
  ProductImageWrapper,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import {
  useAddToCartMutation,
  useAddWishlistedCampaignToCartMutation,
  useRemoveWishlistMutation,
} from "features/api-slice";
import { Wishlist } from "models/Wishlist";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LinearProgress } from "./progress-linear/progress-linear";
import { Timer } from "models/Timer";
import { ClosingTimer } from "./closing-timer";
import { getPriceFromNumber } from "shared/utils/utils";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const ProductDetailsWraper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 2rem;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    flex-direction: column;
    margin-left: 1rem;
    padding: 0 !important;
  }

  .details {
    @media screen and (min-width: ${styles.breakpoints.medium}) {
      flex: 1;
    }

    .product-name {
      font-size: 22px !important;
      @media screen and (max-width: ${styles.breakpoints.medium}) {
        font-size: 13px !important;
        margin-bottom: 5px;
      }
    }

    .product-Description {
      font-family: "Rubik", sans-serif;
      font-size: 14px;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        font-size: 10px !important;
      }
    }

    .price {
      color: ${colors.colorMain};
      font-weight: 600;
      font-family: "Rubik", sans-serif;
      letter-spacing: -0.5px;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        font-size: 12px !important;
      }
    }

    .progress-container {
      width: 40%;
      margin-top: auto;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        width: 100%;
      }

      .item-sold-text {
        font-size: 11px;
        color: ${colors.lightGrey};
        font-weight: 400;
      }
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      flex-direction: row !important;
      margin-left: 0;
      margin-top: auto !important;
    }
  }
`;

const ProductActionsAndStatus = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 16%;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    flex: 1;
    margin-top: auto;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      flex: 1;
      margin-left: 0;
      height: auto !important;
      margin-top: 0 !important;
    }

    .action {
      font-family: "Rubik", sans-serif;
      height: 42px !important;
      border-radius: 10px;
      font-weight: 500;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        white-space: nowrap;
        padding: 0.2rem 0.45rem !important;
        font-size: 12px;
        height: 35px !important;
        border-radius: 6px;
      }
    }
  }
`;

const timerInitialState: Timer = {
  days: "00",
  hours: "00",
  minutes: "00",
  seconds: "00",
  total: 0,
};

export const WishlistPoductCard = (props: {
  item: Wishlist;
  className?: string;
}) => {
  const { item } = props;
  const { t } = useTranslation();

  const campaignTitle = useTranslatedLabel(item.campaign, "campaignTitle");
  const campaignDescription = useTranslatedLabel(
    item.campaign,
    "campaignDescription"
  );
  const [addWishlistItemToCart, { isLoading: addingToCart }] =
    useAddWishlistedCampaignToCartMutation({
      fixedCacheKey: "shared-cart",
    });
  const [removeWishlist, { isLoading: removingFromWishlist }] =
    useRemoveWishlistMutation();

  const onAddToCart = () => {
    addWishlistItemToCart(item.id);
  };

  const onRemoveWishlist = () => {
    removeWishlist(item.id);
  };

  return (
    <ProductCardWrapper className={props.className}>
      <ProductImageWrapper>
        <FullWidthImage
          src={`${process.env.REACT_APP_API_URL}/image/${item.campaign.campaignImage}`}
        ></FullWidthImage>
      </ProductImageWrapper>

      <ProductDetailsWraper className="deal-centerBody d-flex py-2">
        <div className="details pe-4">
          <h2 className="product-name mb-2">{campaignTitle}</h2>
          <h6 className="product-Description">{campaignDescription}</h6>
          <h5 className="price">
            {getPriceFromNumber(item.campaign.product?.price)}
          </h5>

          {item.campaign.type === "1" ? (
            <div className="progress-container">
              <LinearProgress
                progress={
                  (item.campaign.soldItemNumber || 0) /
                  (item.campaign.totalItemNumber || 1)
                }
              ></LinearProgress>
              <p className="item-sold-text mb-0">
                <span className="fw-bold">
                  {item.campaign.soldItemNumber} sold
                </span>{" "}
                out of {item.campaign.totalItemNumber}
              </p>
            </div>
          ) : (
            <div className="closing-time-container">
              <ClosingTimer
                closingDate={item.campaign.closingDate}
                title="Deal closing in"
              ></ClosingTimer>
            </div>
          )}
        </div>
        <div className="actions">
          <ProductActionsAndStatus>
            <div className="actions">
              <StyledButton
                className="action"
                color={colors.white}
                backgroundColor={colors.colorMain}
                borderRadius="7px"
                onClick={onAddToCart}
                disabled={addingToCart}
              >
                {t(Translations.AddToCart)}
              </StyledButton>
              <StyledButton
                className="action"
                color={colors.black}
                backgroundColor="transparent"
                border={`1px solid ${colors.black}`}
                borderRadius="7px"
                onClick={onRemoveWishlist}
              >
                {t(Translations.Remove)}
              </StyledButton>
            </div>
          </ProductActionsAndStatus>
        </div>
      </ProductDetailsWraper>
    </ProductCardWrapper>
  );
};
