import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "./variables";
const ProgressBar = require("progressbar.js");

const StyledProgressWrapper = styled.div`
  position: relative;
  width: 150px;

  .stats {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 0.75rem;
    text-align: center;

    .amount {
      font-weight: 600;
      font-size: 0.85rem;
    }

    .separator {
      width: 2px;
      height: 50%;
      background-color: ${colors.accent};
    }
  }
`;

function getColorForProgress(progress: number) {
  var hue = ((1 - progress) * 120).toString(10);
  return ["hsl(", hue, ",70%,40%)"].join("");
}

export const ProgressRound = (props: { total: number; sold: number }) => {
  const [id] = useState("id" + Math.random().toString(16).slice(2));
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const prog = props.sold / props.total;
    setProgress(prog);
    var bar = new ProgressBar.Path(`#${id}`, {
      easing: "easeInOut",
      duration: 1500,
    });

    bar.set(0);
    bar.animate(prog);
  }, [props.sold]);

  return (
    <StyledProgressWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 120 60"
      >
        <path
          id="background"
          d="M 90, 10 C 120 10, 120 50, 90 50 h-60 C 0 50, 0 10, 30 10 z"
          fill="none"
          stroke={colors.accent}
          strokeWidth="8"
          strokeLinecap="round"
        />
        <path
          id={id}
          d="M 90, 10 C 120 10, 120 50, 90 50 h-60 C 0 50, 0 10, 30 10 z"
          fill="none"
          stroke={getColorForProgress(progress)}
          strokeWidth="8"
          strokeLinecap="round"
        />
      </svg>

      <div className="stats">
        <div className="sold">
          <div className="amount">{props.sold}</div>
          <div className="text">SOLD</div>
        </div>
        <div className="separator"></div>
        <div className="total">
          <div className="text">OUT OF</div>
          <div className="amount">{props.total}</div>
        </div>
      </div>
    </StyledProgressWrapper>
  );
};
