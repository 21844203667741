import { useAppDispatch } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as Logo } from "assets/images/Logo.svg";
import logo from "assets/images/Logo.svg";
import { AuthModal } from "components/auth-modal";
import {
  FullWidthImage,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { UserAvatar } from "components/user-avatar-card";
import { colors, styles } from "components/variables";
import { useFetchUserDetailsQuery } from "features/api-slice";
import { hideAuth } from "features/auth/auth-slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getImagePath, toSentenceCase } from "shared/utils/utils";
import styled from "styled-components";
import { MobileMenu } from "./mobile-menu";
import { useTranslation } from "react-i18next";
import i18next, { Translations } from "i18n/i18n";

const HeaderWrapper = styled.div<{ showBackground: boolean }>`
  .header-content {
    font-family: "Montserrat", sans-serif !important;
    display: flex;
    align-items: stretch;
    border-radius: 15px;
    padding: 5px ${(props) => (props.showBackground ? "20px" : "0px")};
    background-color: ${(props) =>
      props.showBackground ? colors.white : "transparent"};
    box-shadow: ${(props) =>
      props.showBackground ? styles.boxShadow : "none"};
    transition: all 0.2s ease-in;
    position: relative;
    flex-shrink: 0;

    @media screen and (max-width: 768px) {
      border-radius: 12px;
      position: fixed;
      width: calc(92%);
      z-index: 100;
    }

    .logo {
      img {
        object-fit: contain;
      }
    }
  }

  .background-mask {
    width: 100vw;
    height: 0;
    position: fixed;
    z-index: 1;
    background-color: ${colors.backgroundColor};
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in;

    &.visible {
      opacity: 1;
      height: 100vh;
      transition: opacity 0.2s ease-in;
    }
  }

  .logo {
    height: 72px;
    width: 150px;
    margin-right: 25px;
    display: flex;
    align-items: center;
  }

  .links {
    flex: 1;
    display: flex;
    align-items: center;

    .link {
      margin-right: 25px;
      font-weight: 600;

      a {
        text-decoration: none;
        color: ${colors.black};
        font-family: "Rubik", sans-serif;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .actions {
    display: flex;
    padding: 0.7rem 0;
    align-items: center;
    gap: 1rem;

    a {
      text-decoration: none;
      button {
        font-size: 0.85rem;
        font-weight: 500;
        cursor: pointer;
        font-family: "Rubik", sans-serif;
        padding: 0;
        margin: 0 10px 0 0;
      }
    }

    > button {
      font-family: "Rubik", sans-serif;
      width: 140px;
      height: 45px;
      line-height: initial !important;
      font-size: 14px;

      &.wishlist {
        width: auto;
      }
    }

    .contact-us {
      font-size: 0.85rem;
      font-weight: 500;
      cursor: pointer;
      font-family: "Rubik", sans-serif;
    }

    .separator {
      height: 50%;
      width: 2px;
      background-color: #f3f3f3;
      margin: 0 3px;
    }
  }
`;

export const Header = (props: { showBackground: boolean }) => {
  const { t } = useTranslation();
  const [showLogin, setLogin] = useState(false); // Show login modal
  const [showRegister, setRegister] = useState(false); // Show register modal
  const { isLoggedIn, showAuthModal } = useSelector(
    (state: RootState) => state.auth
  );
  const [menuOpened, setMenuOpened] = useState(false);

  const dispatch = useAppDispatch();
  const { data: user } = useFetchUserDetailsQuery(undefined, {
    skip: !isLoggedIn,
  });

  const toggleAuthModal = (isLogin: boolean) => {
    setLogin(isLogin);
    setRegister(!isLogin);
  };

  const getUserName = () => {
    const firstName = toSentenceCase(user?.firstName!);
    const lastName = toSentenceCase(user?.lastName!);

    return `${firstName} ${lastName}`;
  };

  useEffect(() => {
    setLogin(showAuthModal);
  }, [showAuthModal]);

  const onMenuToggle = (isMenuOpened?: boolean) => {
    setMenuOpened(!!isMenuOpened);
  };

  return (
    <HeaderWrapper showBackground={props.showBackground}>
      <div className="header-content">
        <Link to={`/`}>
          <div className="logo">
            <FullWidthImage src={logo}></FullWidthImage>
          </div>
        </Link>

        <div className="links d-none d-lg-flex">
          <div className="link product d-none">
            <Link to={`order/123/confirmation`}>{t(Translations.Product)}</Link>
          </div>
          <div className="link promotions d-none">
            <Link to={`winners`}>{t(Translations.Winners)}</Link>
          </div>
        </div>

        {/* Mobile */}
        <div className="mobile-menu d-flex d-md-none ms-auto align-items-center">
          <MobileMenu
            menuToggled={menuOpened}
            onMenuToggle={onMenuToggle}
          ></MobileMenu>
        </div>

        {/* Tabs and above */}
        <div className="actions d-none d-md-flex ms-auto">
          <p
            className="mb-0 contact-us d-none d-lg-block"
            onClick={() => i18next.changeLanguage("en")}
          >
            English
          </p>

          <p
            className="mb-0 contact-us d-none d-lg-block"
            onClick={() => i18next.changeLanguage("si")}
          >
            සිංහල
          </p>

          <p
            className="mb-0 contact-us d-none d-lg-block"
            onClick={() => i18next.changeLanguage("ta")}
          >
            தமிழ்
          </p>
          <div className="separator"></div>
          <p className="mb-0 contact-us d-none d-lg-block">
            {t(Translations.ContactUs)}
          </p>

          {isLoggedIn ? (
            <>
              <div className="separator"></div>
              <Link to="user/wishlist">
                <StyledButton
                  border="none"
                  color={colors.black}
                  borderRadius="10px"
                  backgroundColor="transparent"
                  className="wishlist"
                >
                  {t(Translations.Wishlist)}
                </StyledButton>
              </Link>

              {!!user ? (
                <UserAvatar
                  userName={getUserName()}
                  userImage={getImagePath(user.profilePicture)}
                />
              ) : null}
            </>
          ) : (
            <>
              <StyledButton
                border={`1px solid ${colors.colorMain}`}
                color={colors.colorMain}
                borderRadius="10px"
                onClick={() => setRegister(true)}
              >
                {t(Translations.Register)}
              </StyledButton>
              <StyledButton
                backgroundColor={colors.colorMain}
                color={colors.white}
                borderRadius="10px"
                onClick={() => setLogin(true)}
              >
                {t(Translations.LogIn)}
              </StyledButton>
            </>
          )}
        </div>
      </div>

      <div className={`background-mask ${menuOpened ? "visible" : ""}`}></div>

      {showLogin || showRegister ? (
        <AuthModal
          isLogin={showLogin}
          show={showLogin || showRegister}
          onHide={() => {
            setLogin(false);
            setRegister(false);
            dispatch(hideAuth());
            setMenuOpened(false);
          }}
          onToggle={(isLogin: boolean) => toggleAuthModal(isLogin)}
        ></AuthModal>
      ) : null}
    </HeaderWrapper>
  );
};
