import { colors, styles } from "components/variables";
import styled from "styled-components";
import logo from "assets/images/Logo.svg";
import amex from "assets/images/icons/amex.svg";
import genie from "assets/images/icons/genie.svg";
import mastercard from "assets/images/icons/mastercard.svg";
import visa from "assets/images/icons/visa.svg";
import fb from "assets/images/icons/facebook.svg";
import ig from "assets/images/icons/instagram.svg";
import linkedin from "assets/images/icons/linkedin.svg";
import tiktok from "assets/images/icons/tiktok.svg";
import twitter from "assets/images/icons/twitter.svg";
import youtube from "assets/images/icons/Youtube.svg";
import positivessl from "assets/images/icons/positivessl_trust_seal.png";
import { FullWidthImage } from "components/common-styled-components/styled-components";
import { Link } from "react-router-dom";
import { StyledLink } from "components/styled-link";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const FooterContainer = styled.div`
  .footer-main {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    box-shadow: ${styles.boxShadow};
    border-radius: 10px;
    background-color: ${colors.white};

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      flex-direction: column;
      width: calc(100% + 8%);
      margin-left: -4%;
      border-radius: 0;
      box-shadow: none;
    }

    > div {
      text-align: left;
    }

    .company {
      flex: 0 0 30%;
    }

    .social {
      flex: 0 0 32%;
      display: flex;
      flex-direction: column;

      .social-links {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: ${styles.breakpoints.medium}) {
          justify-content: center;
        }

        .icon {
          width: 45px;
          height: 45px;
          @media screen and (max-width: ${styles.breakpoints.medium}) {
            width: 15%;
            max-width: 50px;
            height: auto;
          }

          &:not(:last-child) {
            margin-right: 0.7rem;
          }
        }
      }
    }

    .quick-links,
    .customer-service {
      flex: 0 0 15%;
      flex-direction: column;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        margin-top: 1rem;
      }
    }

    .social {
      @media screen and (max-width: ${styles.breakpoints.medium}) {
        margin-top: 1rem;
      }
    }

    .company {
      .logo-container {
        width: 185px;
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      > p {
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          text-align: center;
        }
      }
    }
    .ssl {
      text-align: right;
      img {
        max-width: 222px;
      }
    }
  }

  .footer-sub {
    display: flex;
    box-shadow: ${styles.boxShadow};
    border-radius: 10px;
    background-color: ${colors.white};

    @media screen and (max-width: 768px) {
      margin-bottom: 0 !important;
      flex-direction: column;
      font-weight: 600;
      width: calc(100% + 8%);
      margin-left: -4%;
      border-radius: 0;
      box-shadow: none;
      .copyright {
        margin-bottom: 0.5rem;
      }

      .terms,
      .copyright {
        margin: 10px auto 0px auto;
        gap: 1.5rem;
      }
    }

    .copyrights-and-terms {
      flex: 1;
      display: flex;
    }

    .terms,
    .copyright {
      display: flex;
      margin-left: auto;
      gap: 2rem;
    }

    .link {
      text-decoration: none;
      color: ${colors.black};
    }

    .payment-methods {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: ${styles.breakpoints.medium}) {
        justify-content: center;
        flex-wrap: wrap;

        > p {
          align-self: flex-end;
          flex-basis: 100%;
          text-align: center;
        }
      }

      .payment {
        width: 30px;
        height: 20px;

        @media screen and (max-width: ${styles.breakpoints.medium}) {
          width: 25px;
          height: 20px;
        }

        &:not(:last-child) {
          margin-right: 0.2rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
`;

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <div className="footer-main mb-0 mb-md-3">
        <div className="company">
          <div className="logo-container mb-3">
            <FullWidthImage src={logo}></FullWidthImage>
          </div>
          <p>{t(Translations.StartShoppingJourney)}</p>
        </div>
        <div className="quick-links d-flex align-items-center">
          <h5 className="mb-4">{t(Translations.QuickLinks)}</h5>

          <StyledLink to="about-us">
            <p className="mb-1">{t(Translations.About)}</p>
          </StyledLink>
          <StyledLink to="user/edit">
            <p className="mb-1">{t(Translations.MyAccount)}</p>
          </StyledLink>
          <StyledLink to="user/tickets">
            <p className="mb-1">{t(Translations.ActiveDeals)}</p>
          </StyledLink>
        </div>
        <div className="customer-service d-flex align-items-center">
          <h5 className="mb-4">{t(Translations.CustomerService)}</h5>

          <StyledLink to="contact-us">
            <p className="mb-1">{t(Translations.ContactUs)}</p>
          </StyledLink>

          <StyledLink to="faq">
            <p className="mb-1">{t(Translations.FAQs)}</p>
          </StyledLink>

          <StyledLink to="how-it-works">
            <p className="mb-1">{t(Translations.HowItWorks)}</p>
          </StyledLink>
        </div>
        <div className="social">
          <div className="social-links mb-4">
            <div className="icon">
              <Link to="https://www.facebook.com/lkdealz" target="_blank">
                <FullWidthImage src={fb}></FullWidthImage>
              </Link>
            </div>
            <div className="icon">
              <Link to="https://twitter.com/lankadealz" target="_blank">
                <FullWidthImage src={twitter}></FullWidthImage>
              </Link>
            </div>
            <div className="icon">
              <Link
                to="https://www.linkedin.com/company/lankadealz"
                target="_blank"
              >
                <FullWidthImage src={linkedin}></FullWidthImage>
              </Link>
            </div>
            <div className="icon">
              <Link to="https://www.instagram.com/lk_dealz/" target="_blank">
                <FullWidthImage src={ig}></FullWidthImage>
              </Link>
            </div>
            <div className="icon">
              <Link to="https://www.youtube.com/@lankadealz" target="_blank">
                <FullWidthImage src={youtube}></FullWidthImage>
              </Link>
            </div>
            <div className="icon">
              <Link to="https://www.tiktok.com/@lankadealz" target="_blank">
                <FullWidthImage src={tiktok}></FullWidthImage>
              </Link>
            </div>
          </div>
          <div className="ssl">
            <FullWidthImage src={positivessl}></FullWidthImage>
          </div>
        </div>
      </div>

      <div className="footer-sub">
        <div className="copyrights-and-terms px-3 py-4 flex-column flex-md-row">
          <div className="payment-methods">
            <p className="mb-0 me-0 me-md-3">{t(Translations.WeAccept)}</p>
            <div className="payment">
              <img src={genie}></img>
            </div>
            <div className="payment">
              <img src={visa}></img>
            </div>
            <div className="payment">
              <img src={mastercard}></img>
            </div>
            <div className="payment">
              <img src={amex}></img>
            </div>
          </div>
          <div className="copyright fw-semibold">
            ©️ 2023 LankaDealz.lk, All Rights Reserved.
          </div>
          <div className="terms">
            <Link to="terms" className="link">
              <div>{t(Translations.Terms)}</div>
            </Link>
            <Link to="privacy" className="link">
              <div>{t(Translations.PrivacyPolicy)}</div>
            </Link>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};
