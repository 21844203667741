import "./styles.scss";

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated 15th September 2023</p>
      <p>
        Your privacy is important to us. This Privacy Policy explains how and
        why we collect, use, share, transfer, and store Personal Information,
        and provides information about your rights according to the applicable
        data protection laws.
      </p>
      <p>
        This Privacy Policy governs Personal Information we collect from users
        ("you" or "your") who visit our website and use our applications and
        services ("Platform").
      </p>
      <p>
        We may revise this Privacy Policy from time to time by posting the
        amended terms on{" "}
        <a href="https://lankadealz.com/terms">
          https://www.Lankadealz.com/privacy-policy
        </a>
        . All amended terms automatically take effect immediately upon posting.
        Your continued use after an updated Privacy Policy is posted, means that
        you accept and consent to all its revisions.
      </p>
      <p>
        For the purposes of this Privacy Policy, "Personal Information" means
        any data relating to an identified natural person, or one who can be
        identified directly or indirectly by way of linking data, using
        identifiers such as name, voice, picture, identification number, online
        identifier, geographic location, or one or more special features that
        express the physical, psychological, economic, cultural or social
        identity of such person.
      </p>
      <p>
        You provide your consent to our processing of your Personal Information
        in accordance with this Privacy Policy.
      </p>

      <ol>
        <li>
          WHO IS RESPONSIBLE FOR DATA PROCESSING & HOW TO CONTACT THEM?
          <ol>
            <li>
              For the purposes of applicable data protection law, the
              "controller" processing your Personal Information is:
              <br />
              Prime AEX (Pvt) Ltd (“Prime AEX”,"Lanka dealz", "We", "Us" or
              "Our").
            </li>
          </ol>
        </li>

        <li>
          COLLECTION OF YOUR PERSONAL INFORMATION
          <ol>
            <li>
              The type of information We collect from you is either personal to
              you, or is general in nature:
            </li>
            <li>
              Personal Information: When you register on the Platform as a
              member, update your information, purchase any goods or services,
              take part in promotions or send emails to Us, you provide Us with
              information that We store and process. Such information may
              include your name, address, phone number, email address, credit
              and debit card information, purchase and transaction history,
              interests, your image, your gender, age, location, nationality and
              other similar information.
            </li>
            <li>
              General Information: We also receive and/or automatically collect
              more general information from and about you as a result of your
              visits to, and use of, the Platform. This general information can
              include information such as your IP address, the date and time you
              access the Platform, length of time you spend on the Platform,
              your browsing history (recorded by the text and graphics files
              that compose that page), the Internet address of the website from
              which you linked directly to Our Platform, and other similar
              information.
            </li>
          </ol>
        </li>

        <li>
          COLLECTING AND USING PERSONAL INFORMATION
          <ol>
            <li>
              The Personal Information We collect from you in the course of
              rendering Our services will be processed for the purposes set out
              below.
            </li>
            <li>
              We collect your credit/debit card information to allow you to make
              purchases of goods / services on the Platform. All credit/debit
              cards details and Personal Information will NOT be stored, sold,
              shared, rented or leased to any third parties.
            </li>
            <li>
              We collect your image and voice for the purposes of promoting you
              as the winner of any Prize Draws.
            </li>
            <li>
              Most of the Platform can be used without giving Us any
              information. However, certain services may involve the collection
              of information from you. We use this information to provide you
              with a better service, and in particular to: keep internal
              records; improve Our products, services and the Platform;
              communicate with you if you have ordered, purchased or
              participated in anything on the Platform; contact you for market
              research purposes; and provide you with information about new
              opportunities, promotions, special offers and other information
              that We may feel is relevant to you. If you contact Us via the
              Platform, or via email to the contacts set out on the Platform, We
              may keep a record of that correspondence.
            </li>
            <li>
              We collect general information to help Us make the Platform more
              useful to users (including to help Us assess content of interest
              to visitors), and for other purposes such as determining the
              Platform's technical design specifications and identifying system
              performance or problem areas, for tracking activity on the
              Platform, preparing reports, assessing trends, and otherwise
              monitoring the way in which the Platform is being used none of
              which would be in a way that personally identifies any users.
            </li>
            <li>
              Some of the advertisements you see on the Platform are selected
              and delivered by third parties, such as ad networks, advertising
              agencies, advertisers, and audience segment providers. These third
              parties may collect information about you and your online
              activities, either on the Platform or on other websites, through
              cookies, web beacons, and other technologies in an effort to
              understand your interests and deliver to you advertisements that
              are tailored to your interests. Please remember that We do not
              have access to, or control over, the information these third
              parties may collect. The information practices of these third
              parties are not covered by this privacy policy and you should
              check the privacy policies of these third parties.
            </li>
          </ol>
        </li>
        <li>
          NON-DISCLOSURE OF PERSONAL INFORMATION
          <ol>
            <li>
              You agree that We may share your Personal Information within Our
              group entities and subsidiaries (together the "Company"). We will
              not share, sell or rent your Personal Information in any manner to
              any third parties outside of Our Company except when We have your
              consent under this privacy policy; or if required to do so by law
              or any governing authority. For example, We may share your
              Personal Information with Our third party service providers, with
              brands for advertising and marketing purposes, and with third
              parties for data storage purposes, and within Our Company.
            </li>
            <li>
              We may transfer the Personal Information you provide to Us to
              another country. For example, we may store your Personal
              Information in a data centre in France. When we transfer your
              Personal Information to another country, we will take all
              reasonable steps to ensure that your Personal Information is
              protected in accordance with this privacy policy and applicable
              data protection laws, including through using legally recognised
              data transfer mechanisms, such as appropriate standard contractual
              clauses where required.
            </li>
          </ol>
        </li>
        <li>
          SECURITY
          <ol>
            <li>
              Keeping your Personal Information secure and preventing
              unauthorized access is of utmost priority to Us, and We take all
              steps reasonably necessary to protect your Personal Information
              against any unauthorised access, use, alteration, disclosure or
              destruction. Whilst We have put in place physical, electronic and
              managerial procedures to secure and safeguard your Personal
              Information, We will not be held responsible for any unauthorised
              access by third parties and We cannot guarantee that the Personal
              Information provided by you or that is transmitted via the
              Platform or by e-mail is totally secure and safe.
            </li>
            <li>
              We encourage you to keep any passwords you use confidential and to
              be careful to avoid "phishing" scams where someone may send you an
              electronic message that appears to be from Us asking for your
              Personal Information. We will not request your ID or password
              through electronic messages.
            </li>
          </ol>
        </li>
        <li>
          USE OF COOKIES
          <ol>
            <li>
              Like many websites, the Platform uses cookies, which are files
              that store information on your hard drive. Cookies allow the
              Platform to identify account holders, track new users, record
              session information, and generally to optimise your experience.
              Most browsers accept cookies automatically but if you choose, you
              can disable the cookies from your browser. For online advertising
              campaigns, cookies are automatically deleted after one (1) month.
            </li>
            <li>
              We recommend that you leave cookies "turned on" so that you can
              enjoy a better experience on the Platform. You may occasionally
              get cookies from companies advertising on Our behalf or on behalf
              of Our Company. We do not control these cookies, and these cookies
              are not subject to this Privacy Policy.
            </li>
          </ol>
        </li>
        <li>
          USE OF GOOGLE ANALYTICS AND GOOGLE TRACKING PIXELS
          <ol>
            <li>
              Our Platform uses Google Analytics, a web analytics service of
              Google Inc. ("Google"). Google Analytics uses cookies and/or text
              files to analyze website traffic. Information generated by cookies
              with your details (including your IP address) is transferred to a
              Google server in the United States of America. Google processes
              this information to evaluate your use of the Platform, compile
              reports about the Platform's activity on Our behalf, and deliver
              other related services regarding website and Internet use. Google
              may also share this information with third parties insofar as this
              is necessary or if these third parties process the information on
              behalf of Google.
            </li>
            <li>
              You may disable the cookies from your browser by adjusting your
              browser settings. However, in this scenario, you may not be able
              to make full use of all the functions available on the Platform.
              By using the Platform, you agree to Google's processing of the
              type of information discussed in this section in the manner
              contemplated in this Privacy Policy. Us, and Our respective
              suppliers, and any of Our shareholders, our Company, and any of
              Our and their officers, directors, managers, members, agents, and
              employees, are not liable for any direct, indirect, punitive,
              incidental, special, or consequential damages or loss (including,
              without limitation, incidental and consequential damages, lost
              profits, or damages resulting from lost data or business
              interruption) arising out of, or in way connected with, the use of
              the type of information discussed in this section.
            </li>
          </ol>
        </li>
        <li>
          SOCIAL MEDIA FEATURES
          <ol>
            <li>
              1. Facebook, Instagram and Google tracking pixels are used on the
              Platform. This means that third parties may use cookies, web
              beacons, and similar technologies to collect or receive
              information from the Platform and elsewhere on the internet and
              use that information to provide measurement services and targeted
              adverts. If you wish to opt out of the use of these tracking
              pixels, please adjust your browser settings.
            </li>
          </ol>
        </li>
        <li>
          YOUR MARKETING PREFERENCES
          <ol>
            <li>
              1. You may choose to receive or not receive marketing
              communications from Us by indicating your preferences.
            </li>
            <li>
              You can click "unsubscribe" in any marketing electronic
              communications We send you, or by sending Us an email at{" "}
              <a href="mailto:hello@lankadealz.com">hello@lankadealz.com</a>.
            </li>
            <li>
              On the Platform, you can choose whether you wish to receive
              marketing communications from Us.
            </li>
            <li>
              Any marketing by Us, or any third parties on Our behalf, will be
              conducted in accordance with applicable laws and include (where
              applicable) methods to allow you to express your preferences
              (including the possibility of being removed from Our advertising
              and marketing lists as set out above).
            </li>
          </ol>
        </li>
        <li>
          YOUR RIGHTS
          <ol>
            <li>
              Where the processing of your Personal Information is subject to
              data protection laws, you have certain data subject rights.
              Depending on the applicable law, these include:
            </li>
            <li>
              Access: you have the right to request information about how We
              process your Personal Information.
            </li>
            <li>
              Rectification: you have the right to request the rectification of
              inaccurate Personal Information about you and for any incomplete
              Personal Information about you to be completed.
            </li>
            <li>
              Transfer: you have the right to request a transfer of your
              Personal Information.
            </li>
            <li>
              Erasure: you have the right to have your Personal Information
              erased.
            </li>
            <li>
              Restriction: you have the right to restrict the processing of your
              Personal Information in some cases.
            </li>
            <li>
              Objection: you have the right to object to processing in some
              circumstances, including for automated processing.
            </li>
            <li>
              In addition to the above, you have the right to withdraw your
              consent to the processing of your Personal Information at any
              time. Such withdrawal has no effect on any processing of your
              Personal Information that has occurred prior to the withdrawal of
              your consent.
            </li>
            <li>
              If you have any questions about the type of Personal Information
              We hold about you or wish to exercise any of your data subject
              rights, please send a written request to{" "}
              <a href="mailto:hello@lankadealz.com">hello@lankadealz.com</a>.
            </li>
          </ol>
        </li>
        <li>
          HOW LONG WE KEEP YOUR PERSONAL INFORMATION
          <ol>
            <li>
              We will retain your Personal Information for the length of time
              needed to fulfil the purposes outlined in this privacy policy
              unless a longer retention period is required or permitted by law.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
