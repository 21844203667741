import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { logout, showAuth } from "features/auth/auth-slice";
import { toast } from "react-toastify";
import { RootState } from "./store";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 401) {
        if ((api.getState() as RootState).auth.token) {
          api.dispatch(logout());
          window.location.replace("/");
        } else {
          toast.warning("You need to be logged in.", {
            toastId: "success1",
          });
        }
      } else {
        toast.warning(action.payload?.data?.message || action.payload);
      }
    }

    return next(action);
  };
