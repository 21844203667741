import "./styles.scss";

export const UserAgreement = () => {
  return (
    <div className="user-agreement">
      <h1>User Agreement</h1>
      <p>Last updated 15th September 2023</p>

      <p>
        <b>
          Apple is not a sponsor nor is it involved in the activities of Lanka
          Dealz campaign draws in any manner.
        </b>
      </p>
      <p>
        This User Agreement for our Services (as defined below) is divided into
        multiple sections. Please read this Agreement carefully before
        accepting.
      </p>
      <p>
        The 'Lanka dealz App' and website www.lankadealz.com is operated by
        Prime AEX (Pvt) Ltd (<b>"Lanka dealz App"</b>), owned by Prime AEX (Pvt)
        Ltd registered in Level 35, West Tower - World Trade Center, Colombo 01,
        Sri Lanka. Together referred to as (
        <b>“Prime AEX” , "Lanka dealz", "we", "us", "our"</b>). You agree to
        review this User Agreement in its entirety, where the terms of this User
        Agreement may be applicable to your use of one or both of the Lanka
        Dealz App and Website.
      </p>
      <p>
        You, the user (<b>"user", "you", "your"</b>) agree and understand that
        by clicking and accepting this User Agreement, you confirm to have read,
        understood, and acknowledged to be bound by our terms to access and use
        our Lanka dealz website and Lanka dealz App (together the{" "}
        <b>"Platform"</b>).
      </p>

      <p>
        This User Agreement and Privacy Policy (together the <b>"Terms"</b>) set
        out the basis upon which you can access and use the Platform. The Terms
        apply regardless of how you access the Platform and regardless of the
        technologies or devices by which the Platform is made available to you.
        Other terms and conditions may be applicable to certain specific
        products, services, offers or contest draws ("<b>Services</b>") made
        available on our Platform, either directly or through third-party sites.
      </p>
      <p>
        Nothing in these Terms affects your statutory rights as a consumer under
        applicable laws.
      </p>
      <ol>
        <li>
          LANKA DEALZ APP / Website
          <ol>
            <li>
              The following provisions apply with respect to your use of any
              version of the Lanka dealz App.
              <ol>
                <li>
                  By creating an account on the Platform, you have access to the
                  Lanka dealz App and Website where you may purchase goods and
                  Services.
                </li>
                <li>
                  In order to enter a Draw on the Platform, you will need to
                  purchase a product available on the Lanka dealz App. Once you
                  have purchased a product, you will receive a Ticket to enter a
                  Draw. Depending on the outcome of the Draw, you may win
                  Prizes. Please refer to the Draw Terms in this User Agreement
                  for further details of how our Draws work.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          PRIVACY POLICY
          <ol>
            <li>
              The personal information you provide in the course of using the
              Platform will be used in accordance with our Privacy Policy, the
              terms of which are hereby incorporated into and form part of the{" "}
              <a href="https://www.lankadealz.com/privacy-policy">Terms</a>.
            </li>
          </ol>
        </li>
        <li>
          ELIGIBILITY FOR MEMBERSHIP
          <ol>
            <li>
              It is imperative for Lanka dealz to ensure that users of the Lanka
              dealz App and Website are able to enter into legally binding
              contracts and that minors do not purchase, use, or have access to
              unsuitable content. Membership and/or use of the Platform is not
              available to persons under the age of 18 years old or the legal
              age applicable in your jurisdiction to enter into a contract, if
              different. By using the Platform, you represent and warrant that
              you are of the correct legal age to enter into this User Agreement
              in the jurisdiction you are in, and that all information you
              submit is accurate and truthful when registering any information
              with the Platform or providing any information to us.
            </li>
            <li>
              The Platform is not available to users whose membership has been
              refused, limited, suspended or withdrawn by Lanka dealz.
            </li>
            <li>
              No user may register on the Platform as a member more than once.{" "}
            </li>
          </ol>
        </li>
        <li>
          REGISTRATION AND MEMBERSHIP OBLIGATIONS
          <ol>
            <li>
              In order to access the Services and utilise the Platform, you will
              be required to create an account and register information with us
              in order to create a membership ("Membership"). You agree that any
              information provided to us will be complete and accurate, that you
              will not register under the name of, nor attempt to enter the
              Membership under the name of, another person, and that you will
              not adopt a username that we in our sole discretion, deem
              offensive. We reserve the right to refuse a Membership, or limit,
              suspend or withdraw access to the Platform to any person at any
              time and for any reason.
            </li>
            <li>
              You are responsible for promptly updating details of your
              Membership such that the details are at all times considered true,
              accurate, current, and complete. If you provide any information
              that is untrue, inaccurate, not current or incomplete or if Lanka
              dealz suspects in our sole discretion that such information is
              untrue, inaccurate, not current or incomplete, or not in
              accordance with this User Agreement, we have the right to suspend
              indefinitely, limit or withdraw your Membership and/or your access
              to the Platform.
            </li>
            <li>
              When registering your account on the Platform in order to create
              your Membership, you will be required to provide certain
              information and register a username and password for use of the
              Platform. To keep your Platform account secure you have to protect
              your account through reasonable means, and you are responsible at
              all times for maintaining the confidentiality of, and restricting
              access to and use of, your Platform account and password.
            </li>
            <li>
              You agree to accept responsibility for all activities that occur
              under your Platform account. If someone other than you uses the
              Platform with your login information you will be held accountable
              for all actions taken in your name.
            </li>
            <li>
              You may not, at any point in time, use any other person's
              Membership. You agree to reimburse Lanka dealz in full for any
              costs, expenses and damages caused by improper, unauthorized or
              illegal use of your Membership and password by you or any person
              obtaining access to the Platform.
            </li>
            <li>
              You may not transfer or sell your Membership or account details to
              another party and you undertake that you are solely responsible
              for all activity conducted on the Platform through the use of your
              Membership.
            </li>
          </ol>
        </li>
        <li>
          ELECTRONIC COMMUNICATION
          <ol>
            <li>
              To fulfil our obligations to you under these Terms, we may wish to
              communicate with you by email, by other forms of electronic
              messaging and/or by posting notices on the Platform. You agree to
              receive communications from us electronically and these electronic
              communications will satisfy any legal requirement for
              communications which need to be in writing.
            </li>
          </ol>
        </li>
        <li>
          YOUR USE OF THE PLATFORM
          <ol>
            <li>
              1. You may not access or use the Platform or the Services for any
              purpose other than the purpose for which we make the Platform or
              the Services, as applicable, available to you and you may not use
              either the Platform or the Services in a way that infringes the
              rights of anyone else or that restricts or inhibits anyone else's
              enjoyment of the Platform.
            </li>
            <li>
              Without limiting the generality of clause 7.1, when accessing the
              Platform, or using our Services, you should not:
              <ol>
                <li>
                  post or transmit false, inaccurate, misleading, defamatory, or
                  libellous content (including but not limited to during the
                  registration process);
                </li>
                <li>
                  violate any third-party rights, including intellectual
                  property rights;
                </li>
                <li>
                  advertise to, or solicit, any user to buy or sell any
                  Services, except with the express written approval of us;
                </li>
                <li>transmit chain letters or junk email to other users;</li>
                <li>
                  use any information obtained from the Services in order to
                  contact, advertise to, solicit or sell any products or
                  services to any user without their prior explicit consent;
                </li>
                <li>attempt to impersonate another user or person;</li>
                <li>use the username or account of another user;</li>
                <li>
                  use any information obtained from the Services and Platform in
                  order to harass, abuse or harm another user or person;
                </li>
                <li>
                  accepting payment of anything of value from a third person in
                  exchange for your performance of any commercial activity on or
                  through the Services on behalf of that person;
                </li>
                <li>
                  use the Platform in a manner inconsistent with any and all
                  applicable laws and regulations; and
                </li>
                <li>otherwise, incur any liability to the Platform or us.</li>
              </ol>
            </li>
            <li>
              You may not copy, modify, distribute, sell, or lease any part of
              the Platform. Unless law prohibits such restriction or you have
              our written permission, you may not reverse engineer or attempt to
              extract the source code of the Platform. You may only access the
              Platform through the interfaces that we provide for that purpose
              (for example, you may not "scrape" the Platform through automated
              means or "frame" any part of the Platform), and you may not
              interfere or attempt to disrupt the Platform.
            </li>
            <li>
              You may not attempt to gain unauthorized access to any portion or
              feature of the Platform, or any other systems or networks
              connected to the Platform, or to any of the Services offered on or
              through the Platform, by hacking, password "mining" or any other
              illegitimate means.
            </li>
            <li>
              You agree that you will not take any action that imposes an
              unreasonable or disproportionately large load on the
              infrastructure of the Platform or our systems or networks, or any
              systems or networks connected to the Platform or to us.
            </li>
            <li>
              You agree not to use any device or software to interfere or
              attempt to interfere with the proper working of the Platform or
              any transaction being conducted on the Platform or with any other
              person's use of the Platform.
            </li>
          </ol>
        </li>
        <li>
          PAYMENT AND REFUND
          <ol>
            <li>
              If you make a payment for one of our Services available on our
              Platform, the details you are asked to submit will be provided
              directly to our payment provider via a secured connection. Please
              ensure you have read the applicable terms and conditions provided
              by your bank prior to the transaction.
            </li>
            <li>
              Multiple transactions by you on our Platform may result in
              multiple postings to your monthly credit or debit card statement.
            </li>
            <li>
              The product prices displayed on our Platform are inclusive of VAT.
              You may be charged in LKR (Sri Lankan Rupee) or US Dollar (United
              States Dollar) depending on where you are using the Platform from
              and what type of credit or debit card you are using. In such
              cases, there may be slight discrepancies in values due to changes
              in foreign exchange rates. We are not responsible and shall not
              compensate you for any loss that occurs due to these rate
              fluctuations. Lanka dealz gives no warranties or representations
              whatsoever in relation to the exchange rates it provides for any
              transaction and is under no obligation to provide the best or most
              competitive exchange rates available.
            </li>
            <li>
              For any defective products sold by Lanka dealz, we will offer you
              a remedy in compliance with applicable laws. You acknowledge and
              agree that any refunds requested by you may affect your
              participation in Draws and chance to win Prizes (as set out in
              more detail in the Draw Terms). This is because your entry into
              the Draws are directly correlated to your purchase of the products
              and Services available on the Platform.
            </li>
          </ol>
        </li>
        <li>
          INTELLECTUAL PROPERTY RIGHTS
          <ol>
            <li>
              The Platform and the materials on the Platform, including without
              limitation, the text, software, scripts, graphics, photos, sounds,
              music, videos, interactive features and the like ("Materials") and
              the trademarks, service marks and logos contained therein
              ("Marks"), are owned by or licensed to Prime AEX, and are subject
              to copyright and other intellectual property rights under UAE and
              foreign laws and international conventions.
            </li>
            <li>
              In connection with the Services, the Platform may display certain
              content belonging to third parties. Use of this content may be
              subject to a license granted by third parties to Lanka dealz. You
              shall, in no event, reverse engineer, decompile, or disassemble
              such content and nothing herein shall be construed to grant you
              any right in relation to such content. Materials on the Platform
              are provided to you "as is" for your information and personal use
              only and may not be used, copied, reproduced, distributed,
              transmitted, broadcasted, displayed, sold, licensed, or otherwise
              exploited for any other purposes whatsoever without the prior
              written consent of the respective owners.
            </li>
            <li>
              Lanka dealz reserves all rights not expressly granted herein to
              the Platform and the Materials. You agree to not engage in the
              use, copying, or distribution of any of the Materials other than
              as expressly permitted herein, including any use, copying, or
              distribution of Materials of third parties obtained through the
              Platform for any commercial purposes. You agree not to circumvent,
              disable or otherwise interfere with security-related features of
              the Platform or features that prevent or restrict use or copying
              of any Materials or enforce limitations on the use of the Platform
              or the Materials therein.
            </li>
            <li>
              Content displayed on or through the provision of the Services are
              protected by copyright as a collective work and/or compilation,
              pursuant to copyrights laws, other laws, and international
              conventions. Any reproduction, modification, creation of
              derivative works from or redistribution of the Platform, the
              Materials, or the collective work or compilation is expressly
              prohibited. Copying or reproducing the Platform, the Materials, or
              any portion thereof for further reproduction or redistribution is
              expressly prohibited.
            </li>
            <li>
              You will be exposed to Materials from a variety of sources, and,
              as far as the applicable law allows, Lanka dealz is not
              responsible for the accuracy, usefulness, safety, or intellectual
              property rights of or relating to such Materials, and you agree
              and assume all liability for your use of any such Materials.
            </li>
          </ol>
        </li>
        <li>
          DISCLAIMER FOR THIRD-PARTY APPLICATIONS
          <ol>
            <li>
              The use of any third-party application is subject to any terms and
              conditions provided with such third-party application and is not
              governed by us. Lanka dealz is not responsible for any third-party
              applications, and you acknowledge that such applications may be
              modified or removed by their original publisher and/or respective
              rights owner at any time. You assume all responsibility and risk
              of use of any third-party applications (including any content
              therein) and Lanka dealz hereby disclaims any and all liability to
              you or any third-party related thereto.
            </li>
            <li>
              Lanka dealz does not have any obligation to examine or scan
              third-party applications, for any purpose, and is not responsible
              for the accuracy, completeness, appropriateness or legality of any
              third-party applications. The fact that a third-party application
              is available via any online platform or otherwise is not an
              endorsement, authorization or representation of Lanka dealz's
              affiliation with any third-party, nor is it an endorsement of such
              third-party application and you hereby waive any legal or
              equitable rights or remedies you have or may have against Lanka
              dealz with respect thereto. Lanka dealz exercises no control over
              third-party applications, products, services, software or any
              online platform and we are not responsible for their performance,
              do not endorse them, and are not responsible or liable for any
              content, advertising, or other materials available through any
              third-party applications, products, services, software and any
              online platform. We are not responsible or liable, directly or
              indirectly, for any damage or loss caused to you by your use of or
              reliance on any goods or services available through the
              third-party applications, products, services, software and any
              online platform.
            </li>
          </ol>
        </li>
        <li>
          DISCLAIMER OF WARRANTY
          <ol>
            <li>
              To offer a reliable and enjoyable service we need to fix bugs,
              install updates and perform general diagnosis and maintenance. We
              cannot warrant that the Platform and Services will always be
              uninterrupted, problem-free, free of omission, or error-free. To
              the extent allowed by law, the Materials and function of the
              Platform, and Services, are provided on an "as is" basis without
              warranties of any kind, whether expressed or implied.
            </li>
            <li>
              Information obtained on the Platform may not always be accurate.
              We use other providers to provide data on which we base the
              information detailed on the Platform. We try to make sure that the
              data is correct and up to-date together with our partners, but we
              cannot guarantee that it will always be.
            </li>
          </ol>
        </li>
        <li>
          LIMITATIONS OF LIABILITY
          <ol>
            <li>
              To the maximum extent permissible by applicable law, we, our group
              entities, subsidiaries and affiliates ("<b>Companies</b>"), and
              their respective suppliers, and any of their owners, officers,
              directors, managers, members, agents, and employees:
              <ol>
                <li>
                  shall not be responsible for any infringement or transaction
                  dispute, including, but not limited to, transaction disputes
                  about quality, safety, breach of warranty, and the payment by
                  you;
                </li>
                <li>
                  are not liable for any direct, indirect, punitive, incidental,
                  special, or consequential damages or loss (including, without
                  limitation, incidental and consequential damages, loss of
                  revenue or anticipated profits, loss of goodwill, loss of
                  business or damages resulting from lost data or business
                  interruption) arising out of, or in a way connected with the
                  use or inability to use the Platform and the Services, whether
                  based on warranty, contract, tort (including negligence), or
                  any other legal theory, and whether or not we advised of the
                  possibility of such damages;
                </li>
                <li>
                  exclude any and all liability for damages caused by any
                  problems or technical malfunction of any telephone network or
                  lines, computer online systems, servers or providers, computer
                  equipment, software, failure of any communications due to
                  technical problems or traffic congestion on the internet or on
                  the Platform or combination thereof, including any injury or
                  damage to any users or to any person's computer related to or
                  resulting from use of the Platform; and
                </li>
                <li>
                  expressly disclaim all warranties and conditions of any kind,
                  whether express or implied, including, but not limited to the
                  implied warranties and conditions of merchantability, fitness
                  for a particular purpose and non-infringement.
                </li>
              </ol>
            </li>
            <li>
              In conjunction with the limitation of warranties as explained
              herein, you expressly understand and agree that our maximum
              liability for any claim against us shall be limited to an amount
              of LKR 10000.00, for use of our Platform and/or Services.
            </li>
          </ol>
        </li>
        <li>
          INDEMNITY
          <ol>
            <li>
              1. You agree to defend, indemnify and hold harmless Lanka dealz
              and its Companies, and its and their officers, directors,
              shareholders, successors, assigns, agents, service providers,
              suppliers and employees, from and against any and all claims,
              damages, obligations, losses (whether direct, indirect or
              consequential), liabilities, costs or debt, and expenses
              (including but not limited to legal fees) arising from: (i) your
              breach of any provision of this User Agreement; (ii) your breach
              of any third-party right, including without limitation any
              copyright, trademark, trade secret or other intellectual property,
              or privacy right; (iii) your use of the Platform or the Services,
              or (iv) any claim that the Platform, Services or any Materials
              caused damage to you or a third-party. This defence and
              indemnification obligation will survive termination, modification
              or expiration of this User Agreement and your use of the Services
              and the Platform.
            </li>
          </ol>
        </li>
        <li>
          LIMITATION AND RERMINATION OF SERVICES
          <ol>
            <li>
              Lanka dealz may establish limits from time to time concerning use
              of the Services, including among others, the maximum number of
              days that content will be maintained or retained by the Service,
              the maximum number and size of postings, e-mail messages, or other
              content that may be transmitted or stored by the Services, and the
              frequency with which you may access the Services or the Platform.
            </li>
            <li>
              Lanka dealz reserves the right at any time to modify or
              discontinue the Service on the Platform (or any part thereof) with
              or without notice (without the need for a court order), and that
              Lanka dealz shall not be liable to you or to any third-party for
              any such modification, suspension or discontinuance of the
              Service.
            </li>
            <li>
              In the event of termination, you will no longer be authorized to
              access the Platform or your Membership, and we reserve the right
              to use any means possible to enforce this termination. You
              undertake to immediately notify Lanka dealz of any unauthorized
              use of your Membership or any other security breach.
            </li>
            <li>
              Termination or limitation of your access or use will not waive or
              affect any other right or relief to which we may be entitled at
              law.
            </li>
          </ol>
        </li>
        <li>
          FORCE MAJEURE
          <ol>
            <li>
              Under no circumstances will we be held liable for any delay or
              failure or disruption of the Services delivered through the
              Platform or any disruption to the Platform itself resulting
              directly or indirectly from acts of nature, forces or causes
              beyond our reasonable control, including, without limitation,
              Internet failures, computer, telecommunications or any other
              equipment failures, electrical power failures, strikes, labour
              disputes, riots, insurrections, civil disturbances, shortages of
              labour or materials, epidemics, pandemics, fires, floods, storms,
              explosions, acts of God, war, governmental actions, orders of
              domestic or foreign courts or tribunals or non-performance by
              third parties.
            </li>
          </ol>
        </li>
        <li>
          GENERAL INFORMATION
          <ol>
            <li>
              Severability: If any provision of these Terms is held by a court
              of competent jurisdiction to be invalid, illegal or unenforceable
              and can be deleted without altering the essence of these Terms,
              the invalid, illegal or unenforceable provision will be severed
              and the remaining provisions will remain in full force or effect.
            </li>
            <li>
              No Waiver: Any failure by us to enforce any terms set out in these
              Terms or other policies with you is not a waiver of our rights to
              enforce those terms.
            </li>
            <li>
              Assignment and third-party rights: You may not assign or
              sub-contract any of your rights or obligations under these Terms
              to any third-party unless we agree in writing. We may assign,
              transfer or sub-contract any of our rights or obligations under
              these Terms to any third-party at our discretion and without the
              need for your consent or the requirement to provide you with
              notice.
            </li>
          </ol>
        </li>
        <li>
          CONTACT
          <br />
          <br />
          <ol>
            <li>
              You can contact us at:
              <a href="mailto:hello@Lankadealz.com">hello@Lankadealz.com.</a>.
            </li>
          </ol>
        </li>
        <li>
          LANKADEALZ DRAW TERMS ("DRAW TERMS")
          <br />
          <br />
          <ol>
            <li>
              In addition to your agreement with the Terms, the following
              provisions apply with respect to you participating in our contest
              draws ("<b>Draw(s)</b>") and winning the prizes ("<b>Prize(s)</b>
              ").
            </li>
            <li>
              We may amend these Draw Terms at any point in time without notice
              and the amendments will take effect once they have been displayed
              on the Platform. You acknowledge and agree that it is your
              responsibility to review these Draw Terms periodically to
              familiarize yourself with any modifications. Your continued use of
              the Platform and/or entry into Draws following any amendments
              shall be construed as acceptance of those amendments.
            </li>
            <li>
              Draws will be approved by the relevant authorities in the Sri
              Lanka and are in accordance with the guidelines (as such they may
              be amended from time to time). A valid permit number will be
              issued for each respective Draw. Where Draws are being run from
              any other jurisdiction outside the Sri Lanka where we operate
              from, Lanka dealz will obtain the necessary regulatory
              requirements and permits as stipulated by the law in such
              jurisdiction.
            </li>
            <li>
              Only users with a valid Membership to the Platform that satisfies
              all the Terms are eligible to enter into our Draws.
            </li>
            <li>
              The types of Draws vary on the Platform. Each product purchased on
              the Lanka dealz App and Lanka Dealz Website in accordance with
              these Terms is associated with a specific type of Draw whereby a
              user is awarded at least a single complimentary ticket ("
              <b>Ticket</b>") to that specified Draw after purchasing the
              product or viewing the Advert.
            </li>
            <li>
              To collect your product, you must visit our premises within
              fifteen (15) calendar days from purchasing the product and present
              your valid identification (such as your national identification
              card, passport, driver's license, etc.) as may be required in our
              sole discretion. Lanka dealz may, at its sole discretion, allow an
              individual who has been given a written, valid and effective Power
              of Attorney (POA) by you to collect the product, subject to them
              presenting the necessary identification. Lanka dealz will evaluate
              any such reliance on a POA on a case-by-case basis and cannot
              guarantee that an individual with a POA from you will be allowed
              to collect the product on your behalf. Failure to collect the
              purchased product within this time frame will result in such
              product being contributed to SOS children's village.
            </li>
            <li>
              Lanka dealz reserves the right, without prior notice, to consider
              any unutilized credit in your Wallet waived by you after a
              reasonable amount of time.
            </li>
            <li>
              Ticket numbers are computer generated and may not be sequential to
              the order in which the products they are associated with are
              purchased. You cannot choose Ticket numbers as they are generated
              only upon checkout and completion of purchase of the associated
              products.
            </li>
            <li>
              Tickets awarded to you through your use of our Platform are
              neither refundable nor transferable and are owned solely and
              exclusively by you.
            </li>
            <li>
              The length of each Draw varies and the date when the Prizes are
              awarded can occur on any day of the week for the Draws that have
              'sold out' a minimum of 24 hours prior to the day of the Draw. The
              Draw shall occur in the presence of a Prime AEX official
              representative, unless the Prime AEX authority approves otherwise,
              and as such the results of any Draw are not subject to
              negotiation, interference, challenge, or argument by a user. The
              Draw date will be subject to the Prime AEX representative's
              availability.
            </li>
            <li>
              The deadline to enter any Draw using your Ticket is prior to the
              sell-out of all products specified within the Draw, or prior to
              the closure of the Draw (which happens before the Draw date); or,
              in the case of a timed Draw, prior to the end of the timer
              displayed on the campaign box of the specific Draw. The period of
              the Draws can be extended at Lanka dealz's sole discretion, and we
              will notify you of any such extension.
            </li>
            <li>
              Winners of the Draws are selected by the Prime AEX representative
              and will be notified on the day the Draw occurs. The names of
              winners may also be announced on the Platform and our associated
              social (online) media profiles, unless you specifically advise us
              otherwise in writing prior to the Draw. Unless otherwise
              specified, the number of winners per Draw will always be one (1).
            </li>
            <li>
              On the Draw day, an email will be sent to the registered email on
              the Platform detailing the next steps and how you can claim the
              Prize, which may include the following:
              <ol>
                <li>
                  In the event the winner is based in Sri Lanka, then he/she
                  needs to collect the Prize from our premises within sixty (60)
                  calendar days and present valid identification as may be
                  required, in our sole discretion, in order to collect the
                  Prize. Failure to collect the Prize within this time period
                  WILL result in us canceling the draw results with notification
                  on the Platform and our associated social (online) media
                  profiles, after which Lanka dealz will have no further
                  responsibility with respect to the Prize.
                </li>
                <li>
                  In the event a winner does not reside in Sri Lanka, we will
                  either courier the Prize to the winner by air or land, or ship
                  the Prize to the winner’s country of residence (nearest
                  seaport as selected by us in our sole discretion) at the cost
                  of the winner. We can also insure the shipment of the Prize at
                  the cost of the winner. Collection from the port and clearance
                  through the port and customs shall be the responsibility, and
                  at the cost, of the winner. Accordingly, all local taxes,
                  custom duties, and any other form of expense beyond shipping
                  and delivery at the port will be paid by the winner.
                </li>
              </ol>
            </li>
            <li>
              Once a Prize is handed to/collected by the courier/handler for
              international delivery, risk and responsibility will transfer to
              the winner and we no longer hold claim nor responsibility for any
              damage/injury incurred in transit, or upon delivery.
            </li>
            <li>
              By entering the Draw, all winners consent to the use of any and
              all content made by or provided to us. This includes but is not
              limited to the use of their name, photographs, videos, call
              recordings and other material created during the Draw, in the
              local and regional press, on the Platform and on any social
              (online) media profile associated with us. You may choose to
              withdraw your consent at any time, but this may affect your
              entrance into a Draw.
            </li>
            <li>
              All Prizes displayed on our Platform will be awarded, as
              displayed, with only minor (if any) physical changes.
            </li>
            <li>
              Where the registration or transfer of ownership of a Prize is
              necessary, such registration or transfer of ownership will be made
              against the name of the winner only, as it appears on the winning
              ticket. By entering the Draw of a Prize where registration or
              transfer of ownership is necessary, you agree that you meet the
              required government and regulatory criteria to receive such
              registration or transfer of ownership.
            </li>
            <li>
              If any winner is subsequently found ineligible to participate in
              the Draw, we may at our sole discretion reclaim the Prize or
              dispose of the same in such a manner and to such person as we deem
              fit. All such decisions will be in line with applicable laws.
            </li>
            <li>
              We will not be responsible for arranging any insurance, including
              travel insurance, medical insurance, transport, meals, or any
              other expenses of a personal nature incurred by the winner in the
              collection of the Prize, unless otherwise agreed by us in writing.
            </li>
            <li>
              We will not be responsible for claims, damages, or expenses of any
              nature whatsoever for any loss, illness, bodily injury, including
              death, of or to any winner and/or any third-party during and/or in
              course of usage of any Prize or due to any defects in any Prizes.
            </li>
            <li>
              Winners are responsible for any and all tax liability where
              applicable21. Winners are responsible for any and all tax
              liability where applicable.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
