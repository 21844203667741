import { UserRegistration } from "models/AuthUser";
import { Form, Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  StyledButton,
  StyledModal,
} from "./common-styled-components/styled-components";
import { colors } from "./variables";
import { useRequestPasswordResetMutation } from "features/auth/auth-slice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

const StyledBackToLoginText = styled.p`
  cursor: pointer;
`;

export const ForgotPasswordComponent = (
  props: {
    registrationPayload: UserRegistration;
    onHide: () => void;
    onToggle: () => void;
  } & ModalProps
) => {
  const { registrationPayload, ...modalProps } = props;
  const { formState, register, handleSubmit } = useForm<{ email: string }>();
  const [resetPassword] = useRequestPasswordResetMutation();
  const { t } = useTranslation();

  const onSubmit = async (data: { email: string }) => {
    await resetPassword(data.email).unwrap();
    toast.success("Password reset email sent to your email");
    props.onHide();
  };

  return (
    <StyledModal
      {...modalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="fw-semibold">{t(Translations.ResetPassword)}</h5>

        <p>{t(Translations.ResetPasswordDescription)}</p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="otp">
            <StyledFormControl
              type="email"
              placeholder={t(Translations.Email)}
              {...register("email", {
                required: true,
              })}
            />
          </Form.Group>
          <div className="form-group submit">
            <StyledButton
              type="submit"
              color={colors.white}
              backgroundColor={colors.colorMain}
              borderRadius="10px"
              disabled={!formState.isValid}
            >
              {t(Translations.SendLink)}
            </StyledButton>
          </div>

          <StyledBackToLoginText className="link-login text-center mt-3 mb-0 fw-semibold">
            <span
              className="link"
              onClick={() => {
                props.onToggle();
              }}
            >
              {t(Translations.BackToLogin)}
            </span>
          </StyledBackToLoginText>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
};
