import { useTranslation } from "react-i18next";
import "./styles.scss";
import aboutUsBg from "assets/images/about-us/about-bg.jpg";
import { Translations } from "i18n/i18n";

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="main-about mt-5 mb-5">
      <h1>{t(Translations.AboutUs)}</h1>
      <p>{t(Translations.TransformedShoppingExperience)}</p>
      <p>{t(Translations.WinFantasticPrizes)}</p>
      <img src={aboutUsBg} />
    </div>
  );
};
