import { MaskedImage } from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { NavLinkItem } from "models/NavLinkItem";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavigationWrapper = styled.div`
  background-color: ${colors.white};
  box-shadow: ${styles.boxShadow};
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 3rem;

  .link {
    text-align: left;
    padding: 18px 20px;
    border-radius: 12px;
    margin-bottom: 5px;
    display: block;
    text-decoration: none;
    color: ${colors.black};
    font-weight: 500;
    display: flex;
    transition: all 0.2s linear;
    cursor: pointer;
    font-family: "Montserrat", sans-serif !important;

    .mask-container {
      background-color: ${colors.black};
      margin-right: 0.5rem;
    }

    &.active {
      background-color: ${colors.lightYellow};
      color: ${colors.white};

      .mask-container {
        background-color: ${colors.white};
      }
    }
  }
`;

export const UserNavigation = (props: {
  items: NavLinkItem[];
  className?: string;
}) => {
  return (
    <StyledNavigationWrapper className={props.className}>
      {props.items.map((item, index) => {
        if (!item.onClick && item.to) {
          return (
            <NavLink
              to={item.to}
              className={({ isActive }) => (isActive ? "active link" : "link")}
              key={index}
            >
              {" "}
              <MaskedImage src={item.icon}></MaskedImage>
              {item.title}
            </NavLink>
          );
        }
        return (
          <div className="link" onClick={item.onClick} key={index}>
            <MaskedImage src={item.icon}></MaskedImage>
            {item.title}
          </div>
        );
      })}
    </StyledNavigationWrapper>
  );
};
