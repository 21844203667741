import { CardProduct } from "components/card-product";
import { StyledCarouselWrapper } from "components/common-styled-components/styled-components";
import { ProductDetailsModalComponent } from "components/product-details-modal";
import { colors, sliderResponsiveBreakPoints } from "components/variables";
import { useFetchProductsQuery } from "features/api-slice";
import { Translations } from "i18n/i18n";
import { Product } from "models/Product";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styled from "styled-components";

const StyledOurProductsWrapper = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  border-radius: 20px;

  .top-row {
    color: ${colors.black};

    .actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 1rem;

      .action {
        width: 50px;
        height: 50px;
        border: 1px solid rgb(0 0 0 / 10%);
        border-radius: 10px;
        padding: 1.2rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          border: 1px solid rgb(0 0 0 / 30%);
        }
        &.disabled {
          cursor: not-allowed;
        }

        &.left {
          > div {
            transform: rotate(315deg);
            left: 2px;
          }
        }
        &.right {
          > div {
            transform: rotate(135deg);
            right: 2px;
          }
        }

        > div {
          width: 100%;
          height: 100%;
          border-top: 2px solid ${colors.accent};
          border-left: 2px solid ${colors.accent};
          position: relative;
        }
      }
    }
  }

  .carousel-wrapper {
    padding: 0;
  }
`;

export const OurProducts = (props: { background: string }) => {
  const [sliderRef, setSliderRef] = useState<Slider>(null);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const { t } = useTranslation();

  const sliderSettings = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    beforeChange: (current, next) => {
      const lastPage =
        sliderRef.innerSlider.state.slideCount -
        sliderRef.innerSlider.state.lazyLoadedList?.length;
      if (next === lastPage) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    },
    responsive: sliderResponsiveBreakPoints,
  };

  const { data = [], isFetching } = useFetchProductsQuery();
  const [selectedItem, setSelectedItem] = useState<Product | null>(null);
  const handleItemClick = (itemId: number) => {
    const clickedProduct =
      (data || []).find((item) => item.id === itemId) || null;
    setSelectedItem(clickedProduct);
  };

  return (
    <StyledOurProductsWrapper background={props.background}>
      <div className="top-row d-flex">
        <div className="title text-start">
          <h3 className="main-title">{t(Translations.OurProducts)}</h3>
        </div>
        <div className="actions d-none d-md-flex">
          <div className="action left" onClick={sliderRef?.slickPrev}>
            <div></div>
          </div>
          <div
            className={`action right ${isLastSlide ? "disabled" : ""}`}
            onClick={isLastSlide ? null : sliderRef?.slickNext}
          >
            <div></div>
          </div>
        </div>
      </div>

      <StyledCarouselWrapper>
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <Slider ref={setSliderRef} {...sliderSettings}>
            {data.map((card, index) => (
              <CardProduct
                key={"product-card-container" + index}
                item={{
                  id: card.id,
                  image: `${process.env.REACT_APP_API_URL}/image/${card.imageName}`,
                  title: card.productName,
                  titleSi: card.productNameSi,
                  titleTa: card.productNameTa,
                  total: card.price,
                }}
                onClick={handleItemClick}
              ></CardProduct>
            ))}
          </Slider>
        )}
      </StyledCarouselWrapper>

      {!!selectedItem ? (
        <ProductDetailsModalComponent
          show={!!selectedItem}
          product={selectedItem!}
          onHide={() => setSelectedItem(null)}
        ></ProductDetailsModalComponent>
      ) : null}
    </StyledOurProductsWrapper>
  );
};
