import { useEffect, useState } from "react";
import { Form, Modal, ModalProps } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  StyledButton,
  StyledModal,
} from "./common-styled-components/styled-components";
import { colors } from "./variables";

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

export type ChangePhoneProps = {
  verify: (form: { otp: string }) => void;
};

export const VerifyPhoneChangeOTPComponent = (
  props: { changePhoneProps: ChangePhoneProps } & ModalProps
) => {
  const { changePhoneProps, ...modalProps } = props;
  let [showResend, setShowResend] = useState(false);
  const { formState, register, handleSubmit } = useForm<{ otp: string }>();

  useEffect(() => {
    setTimeout(() => {
      setShowResend(true);
    }, 60000);
  }, []);

  return (
    <StyledModal
      {...modalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="fw-semibold">Verify Mobile Number</h5>

        <p>
          Please enter the 6 digit code sent to your mobile number. The code
          will expire in 5 minutes
        </p>

        <Form onSubmit={handleSubmit(changePhoneProps.verify)}>
          <Form.Group className="mb-3" controlId="otp">
            <StyledFormControl
              type="text"
              placeholder="OTP"
              maxLength={6}
              {...register("otp", {
                required: true,
                minLength: 6,
                maxLength: 6,
              })}
            />
          </Form.Group>
          <div className="form-group submit">
            <StyledButton
              type="submit"
              color={colors.white}
              backgroundColor={colors.colorMain}
              borderRadius="10px"
              disabled={!formState.isValid}
            >
              Verify OTP
            </StyledButton>
          </div>
        </Form>

        {showResend ? (
          <p className="link-login">
            Didn't receive the code? <span className="link">Resend</span>
          </p>
        ) : null}
      </Modal.Body>
    </StyledModal>
  );
};
