import { useAppDispatch } from "app/hooks";
import { AuthModal } from "components/auth-modal";
import { StyledButton } from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { hideAuth } from "features/auth/auth-slice";
import { useState } from "react";
import styled from "styled-components";

const MobileAuthWrapper = styled.div`
  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .contact-methods {
    button {
      box-shadow: ${styles.boxShadow};
    }
  }
`;

export const MobileAuth = (props: {
  onMenuToggle: (opened: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const [showLogin, setLogin] = useState(false); // Show login modal
  const [showRegister, setRegister] = useState(false); // Show register modal
  const toggleAuthModal = (isLogin: boolean) => {
    setLogin(isLogin);
    setRegister(!isLogin);
  };

  return (
    <MobileAuthWrapper className="p-3">
      <h4 className="title mb-0">Welcome to BuyDealz.lk,</h4>
      <h4 className="title mb-3">Login or Register to get started</h4>

      <StyledButton
        className="btn btn-reg mb-3"
        border={`1px solid ${colors.colorMain}`}
        color={colors.colorMain}
        borderRadius="10px"
        onClick={() => setRegister(true)}
      >
        Register
      </StyledButton>
      <StyledButton
        className="btn btn-login"
        backgroundColor={colors.colorMain}
        borderRadius="10px"
        color={colors.white}
        onClick={() => setLogin(true)}
      >
        Login
      </StyledButton>

      <p className="mt-4">Need help?</p>

      <div className="contact-methods container">
        <div className="row">
          <div className="col-6">
            <StyledButton
              className="btn btn-reg mb-3"
              color={colors.black}
              borderRadius="10px"
              lineHeight="1"
              backgroundColor={colors.white}
            >
              Call us
            </StyledButton>
          </div>
          <div className="col-6">
            <StyledButton
              className="btn btn-reg mb-3"
              color={colors.black}
              borderRadius="10px"
              lineHeight="1"
              backgroundColor={colors.white}
            >
              Email us
            </StyledButton>
          </div>
        </div>
      </div>

      {showLogin || showRegister ? (
        <AuthModal
          isLogin={showLogin}
          show={showLogin || showRegister}
          onHide={() => {
            setLogin(false);
            setRegister(false);
            dispatch(hideAuth());
            props.onMenuToggle(false);
          }}
          onToggle={(isLogin: boolean) => toggleAuthModal(isLogin)}
        ></AuthModal>
      ) : null}
    </MobileAuthWrapper>
  );
};
