import { colors } from "components/variables";
import { Translations } from "i18n/i18n";
import { useTranslation } from "react-i18next";
import { getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";

const StyledTotalsWrapper = styled.div`
  .t-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    &:not(:last-child) {
      border-bottom: 2px solid #f2f5f8;
    }

    .desc-container {
      display: flex;
      flex-direction: column;
      text-align: left;

      &.multi {
        line-height: 1;
      }

      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .value {
      &.total {
        letter-spacing: -1px;
        font-size: 28px !important;
        font-weight: 600 !important;
      }
    }
  }

  .promo {
    color: ${colors.promoGreen};
  }
`;

export interface TotalsProps {
  total: number;
  subtotal: number;
}

export const Totals = ({ total, subtotal }: TotalsProps) => {
  const { t } = useTranslation();

  return (
    <StyledTotalsWrapper>
      <div className="t-row p-3">
        <div className="desc-container multi">
          <div className="title">{t(Translations.Price)}</div>
          <div className="subtitle">{/*t(Translations.InclusiveOfVAT)*/}</div>
        </div>
        <div className="value total">{getPriceFromNumber(total)}</div>
      </div>
      <div className="t-row p-3 pb-2">
        <div className="desc-container">
          <div className="subtitle">{t(Translations.Subtotal)}</div>
        </div>
        <div className="value">{getPriceFromNumber(subtotal)}</div>
      </div>
      {/* <div className="t-row p-3">
        <div className="desc-container">
          <div className="subtitle">VAT</div>
        </div>
        <div className="value">{getPriceFromNumber(155500)}</div>
      </div>
      <div className="t-row promo pt-3 px-3">
        <div className="desc-container">
          <div className="subtitle">Promo Applied</div>
        </div>
        <div className="value">{getPriceFromNumber(155500)}</div>
      </div> */}
    </StyledTotalsWrapper>
  );
};
