import { Col, Nav, Row, Tab } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "./variables";

const StyledTabWrapper = styled.div`
  .tab-container {
    > div {
      &:first-child {
        border-radius: 25px 0 0 25px;
        overflow: hidden;
      }
      &:last-child {
        border-radius: 0 25px 25px 0px;
        overflow: hidden;
      }
    }
  }
`;
const StyledNavItem = styled(Nav.Item)`
  background-color: ${colors.darkGrey};
  line-height: 2;

  .nav-link {
    color: ${colors.black} !important;

    &.active {
      background-color: ${colors.accent};
    }
  }
`;

export type Tab = {
  title: string;
  child: any;
  classNames: string;
};
export const TabView = (props: { tabs: Tab[]; activeTabIndex: number }) => {
  return (
    <StyledTabWrapper>
      <Tab.Container defaultActiveKey={props.activeTabIndex}>
        <Row className="mb-3">
          <Nav variant="pills" className="flex-row tab-container">
            {props.tabs.map((tab, index) => (
              <Col className={tab.classNames} key={index}>
                <StyledNavItem>
                  <Nav.Link
                    className="fw-semibold text-center"
                    eventKey={index}
                  >
                    {tab.title}
                  </Nav.Link>
                </StyledNavItem>
              </Col>
            ))}
          </Nav>
        </Row>
        <Row>
          <Tab.Content>
            {props.tabs.map((tab, index) => (
              <Tab.Pane eventKey={index} key={index}>
                {tab.child}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Row>
      </Tab.Container>
    </StyledTabWrapper>
  );
};
