import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import {
  apiSlice,
  useAddToCartMutation,
  useFetchCartQuery,
  useRemoveFromCartMutation,
} from "features/api-slice";
import { useFirstRender } from "hooks/useFirstRender";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import cartImg from "../assets/images/icons/cart.svg";
import {
  FullWidthImage,
  StyledButton,
} from "./common-styled-components/styled-components";
import { colors, styles } from "./variables";
import { ItemCounter } from "./item-counter";
import { getPriceFromNumber } from "shared/utils/utils";
import { useTranslation } from "react-i18next";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { Campaign } from "models/Campaign";

const StyledCartWrapper = styled.div<{ show: boolean }>`
  position: relative;
  :hover {
    .cart-items-container {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s linear;
    }
  }

  .cart-icon-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: ${colors.cartGrey};
    padding: 1rem;
    cursor: pointer;
    position: relative;

    .total-count {
      position: absolute;
      top: 0;
      right: 0;
      background-color: ${colors.orange};
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.white};
      font-size: 0.8rem;
    }
  }

  .cart-items-container {
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.2s linear;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 375px;
    width: 90vw;
    background-color: #ffffff;
    box-shadow: ${styles.boxShadow};
    border-radius: 10px;
    padding: 1rem;

    .text-left {
      text-align: left;
    }

    .cart-content {
      max-height: 500px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .item-list {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
      }

      .item {
        font-size: 0.85rem;
        display: flex;
        justify-content: space-between;

        .image-container {
          flex: 0 0 40%;
          border-radius: 10px;
          overflow: hidden;
          width: 135px;
          height: 85px;
        }

        .details-container {
          flex: 0 0 40%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .text-left {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .totals {
        font-size: 0.75rem;
        padding: 0.4rem;
        background-color: ${colors.white};
        border-radius: 5px;

        .col {
          display: flex;
          justify-content: space-between;

          &:first-child {
            border-bottom: 1px solid ${colors.accent};
          }
        }
      }

      .checkout {
        a {
          text-decoration: none;
        }

        .checkout-btn {
          padding: 0.4rem;
        }
      }

      .price {
        color: ${colors.colorMain};
      }
    }
  }
`;

export const TranslatedCampaignTitle = (props: { campaign: Campaign }) => {
  const campaignTitle = useTranslatedLabel(props.campaign, "campaignTitle");
  return (
    <h6 className="text-left fw-bold" title={campaignTitle}>
      {campaignTitle}
    </h6>
  );
};

export const CartComponent = () => {
  const [addedToCart, setAddedToCart] = useState(false);

  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: cartCampaigns } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [_, { isLoading: isAddingToCart, isSuccess: addToCartSuccess, reset }] =
    useAddToCartMutation({ fixedCacheKey: "shared-cart" });
  const [cartTotal, setCartTotal] = useState(0);
  const firstRender = useFirstRender();
  const { t } = useTranslation();

  useEffect(() => {
    if (!firstRender && addToCartSuccess) {
      setAddedToCart(true);
      toast.success("Item added to cart");
      const timeout = setTimeout(() => {
        setAddedToCart(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [addToCartSuccess]);

  useEffect(() => {
    const total = cartCampaigns?.reduce((aggr, cart) => {
      return aggr + (cart.total || 0);
    }, 0);
    setCartTotal(total || 0);
  }, [cartCampaigns]);

  return (
    <StyledCartWrapper show={addedToCart}>
      <div className="cart-icon-container">
        <FullWidthImage src={cartImg} className="cart-icon"></FullWidthImage>

        {!!cartCampaigns?.length ? (
          <div className="total-count">
            <span>{cartCampaigns?.length}</span>
          </div>
        ) : null}
      </div>

      <div className="cart-items-container">
        <div className="cart-content">
          <h6 className="text-left mb-3 fw-semibold">
            Shopping Cart Product(s)
          </h6>

          <div className="item-list">
            {(cartCampaigns || []).map((cart) => (
              <div className="item mb-3" key={cart.id}>
                <div className="image-container">
                  <FullWidthImage
                    src={
                      process.env.REACT_APP_API_URL +
                      "/image/" +
                      cart.campaign.campaignImage
                    }
                  ></FullWidthImage>
                </div>
                <div className="details-container">
                  <TranslatedCampaignTitle
                    campaign={cart.campaign}
                  ></TranslatedCampaignTitle>
                  <p className="text-left price fw-bold">
                    {getPriceFromNumber(cart.total)}
                  </p>
                </div>
                <ItemCounter cartItem={cart}></ItemCounter>
              </div>
            ))}
          </div>

          <div className="totals mb-3">
            <div className="col pb-2">
              <div className="title">Total number of Product(s)</div>
              <div className="value">{cartCampaigns?.length}</div>
            </div>
            <div className="col fw-bold mt-2">
              <div className="title">Total Cost</div>
              <div className="value">{getPriceFromNumber(cartTotal)}</div>
            </div>
          </div>
          <div className="checkout">
            <Link to="checkout">
              <StyledButton
                className="checkout-btn"
                backgroundColor={colors.colorMain}
                color={colors.white}
                borderRadius="5px"
              >
                Continue to Checkout
              </StyledButton>
            </Link>
          </div>
        </div>
      </div>
    </StyledCartWrapper>
  );
};
