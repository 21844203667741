import {
  ContainerCard,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { PasswordField } from "components/password-field";
import { colors } from "components/variables";
import { apiSlice, useChangeUserPasswordMutation } from "features/api-slice";
import { logout } from "features/auth/auth-slice";
import { Translations } from "i18n/i18n";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledSettingsWrapper = styled(ContainerCard)`
  h4 {
    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 600;
  }
  input {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 14px;
    height: 50px;
  }

  input::placeholder {
    font-weight: 500;
  }

  .form-label {
    color: ${colors.lightGrey};
    margin: 0;
    font-size: 12px;
  }
`;

type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const Settings = () => {
  const { register, handleSubmit } = useForm<ChangePasswordForm>();
  const [changePassword, { isSuccess: passwordChangeSuccess }] =
    useChangeUserPasswordMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChangePassword = (data: ChangePasswordForm) => {
    changePassword(data)
      .unwrap()
      .then((fullfilled) => {
        toast.success("Password changed! Please login again");
        dispatch(apiSlice.util.invalidateTags(["SoldOut"]));
        dispatch(logout());
        navigate("/");
      })
      .catch((error) => {
        toast.error("An error occurred while changing your password");
      });
  };

  return (
    <StyledSettingsWrapper className="p-4">
      <h4 className="text-start fw-bolder mb-3">
        {t(Translations.ChangePassword)}
      </h4>

      <Form onSubmit={handleSubmit(onChangePassword)}>
        <div className="container text-start p-0 mb-2">
          <div className="row">
            <div className="col-12 col-md-6">
              <PasswordField
                label={t(Translations.CurrentPassword)}
                register={register("currentPassword", {
                  required: true,
                  minLength: 8,
                })}
              ></PasswordField>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <PasswordField
                label={t(Translations.NewPassword)}
                register={register("newPassword", {
                  required: true,
                  minLength: 8,
                })}
              ></PasswordField>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <PasswordField
                label={t(Translations.ConfirmNewPassword)}
                register={register("confirmNewPassword", {
                  required: true,
                  minLength: 8,
                })}
              ></PasswordField>
            </div>
          </div>
        </div>

        <div className="container mt-5 pt-5 mb-2">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 form-btn">
              <StyledButton
                type="submit"
                color={colors.white}
                borderRadius="10px"
              >
                {t(Translations.UpdatePassword)}
              </StyledButton>
            </div>
          </div>
        </div>
      </Form>
    </StyledSettingsWrapper>
  );
};
