import instance from "axiosClient";
import { City } from "models/City";

export const searchCities = (start: number, count: number, term = "*") => {
  return instance.post<City[]>("/city", {
    term,
    start,
    count,
  });
};
