const colors = {
  colorMain: "#4628F9",
  accent: "#DFDFDF",
  darkGrey: "#EEEEEE",
  white: "#FFF",
  black: "#000",
  backgroundColor: "#F2F5F8",
  lightYellow: "#F3A833",
  orange: "#D79007",
  lightRed: "#FF5F51",
  darkRed: "#C53535",
  darkerRed: "#FF0000",
  lightPurple: "#7B4DFD",
  lightGreen: "#42CB2B",
  checkoutGreen: "#04A828",
  promoGreen: "#008000",
  cartGrey: "#283947",
  lightGrey: "#757575",
  subtleGrey: "#F8F8F8",
  bluishGrey: "#E3E9EE",
  linkBlue: "#1310B0",
  BuyRed: "#ED1717",
  mainRed: "#9A0000",
};

const styles = {
  boxShadow: `10px 2px 15px -5px ${colors.accent}`,
  breakpoints: {
    medium: "768px",
    large: "992px",
    Xlarge: "1200px",
  },
};

const sliderResponsiveBreakPoints = [
  {
    breakpoint: 1920,
    settings: {
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2,
    },
  },
];

export { colors, styles, sliderResponsiveBreakPoints };
