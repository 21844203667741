import { CardSoldOut } from "components/card-sold-out";
import { StyledCarouselWrapper } from "components/common-styled-components/styled-components";
import {
  colors,
  sliderResponsiveBreakPoints,
  styles,
} from "components/variables";
import {
  useFetchCampaignsQuery,
  useFetchSoldOutCampaignsQuery,
} from "features/api-slice";
import { Translations } from "i18n/i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styled from "styled-components";

const StyledSoldOutWrapper = styled.div<{ background: string }>`
  border-radius: 20px;

  @media screen and (min-width: ${styles.breakpoints.medium}) {
    background-color: ${(props) => props.background};
    overflow: hidden;
  }

  .top-row {
    color: ${colors.white};
    background-color: ${colors.lightRed};
    padding-bottom: 6rem;
    width: 100vw;
    margin-left: -4vw;

    @media screen and (min-width: ${styles.breakpoints.medium}) {
      padding-bottom: 0;
      width: auto;
      margin-left: 0;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 1rem;

      .action {
        width: 50px;
        height: 50px;
        border: 1px solid rgb(255 255 255 / 40%);
        border-radius: 10px;
        padding: 1.2rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          border: 1px solid ${colors.white};
        }
        &.disabled {
          cursor: not-allowed;
        }

        &.left {
          > div {
            transform: rotate(315deg);
            left: 2px;
          }
        }
        &.right {
          > div {
            transform: rotate(135deg);
            right: 2px;
          }
        }

        > div {
          width: 100%;
          height: 100%;
          border-top: 2px solid ${colors.white};
          border-left: 2px solid ${colors.white};
          position: relative;
        }
      }
    }
  }

  .carousel-wrapper {
    padding: 0;
  }

  .slick-arrow {
    display: none !important;
  }
`;

const SoldOutMobileWrapper = styled.div`
  margin-top: -6rem;

  > div {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;

export const SoldOut = (props: { background: string }) => {
  const [sliderRef, setSliderRef] = useState<Slider>(null);
  const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
  const { t } = useTranslation();

  const sliderSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    beforeChange: (current, next) => {
      const lastPage =
        sliderRef.innerSlider.state.slideCount -
        sliderRef.innerSlider.state.lazyLoadedList?.length;
      if (next === lastPage) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    },
    responsive: sliderResponsiveBreakPoints,
  };

  const { data = [], isFetching } = useFetchSoldOutCampaignsQuery();

  return (
    <StyledSoldOutWrapper background={props.background}>
      <div className="top-row d-flex pt-4 px-4">
        <div className="title text-start">
          <h3 className="main-title mb-0">{t(Translations.SoldOut)}</h3>
          <p className="title-paragraph">{t(Translations.SoldOutCampaigns)}</p>
        </div>
        <div className="actions d-none d-md-flex">
          <div className="action left" onClick={sliderRef?.slickPrev}>
            <div></div>
          </div>
          <div
            className={`action right ${isLastSlide ? "disabled" : ""}`}
            onClick={isLastSlide ? null : sliderRef?.slickNext}
          >
            <div></div>
          </div>
        </div>
      </div>

      {/* Carousel on desktop */}
      <StyledCarouselWrapper className="px-4 pb-4 d-none d-md-block">
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <Slider {...sliderSettings} ref={setSliderRef}>
            {/* filter by item limit */}
            {data
              .filter((c) => c.type === "1")
              .map((campaign, index) => (
                <CardSoldOut
                  key={"product-card-container" + index}
                  campaign={campaign}
                ></CardSoldOut>
              ))}
          </Slider>
        )}
      </StyledCarouselWrapper>

      {/* Column on mobile */}
      <SoldOutMobileWrapper className="d-flex flex-column d-md-none">
        {data
          .filter((c) => c.type === "1")
          .map((campaign, index) => (
            <CardSoldOut
              key={"product-card-container" + index}
              campaign={campaign}
            ></CardSoldOut>
          ))}
      </SoldOutMobileWrapper>
    </StyledSoldOutWrapper>
  );
};
