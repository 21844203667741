import { ReactComponent as Plus } from "assets/images/icons/plus.svg";
import { AddToCartButton } from "components/add-to-cart-btn";
import { ClosingTimer } from "components/closing-timer";
import {
  FullWidthImage,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { LinearProgress } from "components/progress-linear/progress-linear";
import { colors, styles } from "components/variables";
import useCountDown from "hooks/useCountdown";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { Campaign } from "models/Campaign";
import { useState } from "react";
import { getPeriodInTwoDigits } from "shared/utils/utils";
import styled from "styled-components";

const StyledClosingTimer = styled.div`
  background-color: ${colors.BuyRed};
  border-radius: 36px;
  color: ${colors.white};
  font-size: 12px;
  padding: 0.2rem 0;

  .timer {
    font-weight: 500;
  }
`;

const CardWrapper = styled.div`
  padding: 15px 20px;
  border-radius: 20px;
  background: #fff;
  border: none;
  box-shadow: ${styles.boxShadow};
  margin: 10px 0 10px 0;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

  &:hover {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  .closing-indicator {
    height: 2rem;
  }

  .name {
    @media screen and (max-width: ${styles.breakpoints.medium}) {
      max-width: 150px;
    }

    @media screen and (min-width: ${styles.breakpoints.medium}) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .name {
      text-align: left;
      margin-bottom: 1rem;
    }
  }

  .progress-container {
    width: 80%;
    margin: auto;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      width: auto;
      margin: auto 0 0;
      padding: 0;
    }

    .linear-progress {
      > div {
        height: 6px;
      }
    }
  }

  .image-container {
    @media screen and (max-width: ${styles.breakpoints.medium}) {
      width: auto;
      margin-bottom: 1rem;
      margin-top: 0 !important;
      position: relative;

      .add-to-cart-plus-mobile {
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        padding: 4px;
        background-color: ${colors.colorMain};
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
      }
    }
  }

  .buy-link {
    font-style: italic;
    font-weight: 600;
    margin-bottom: -4px;
    margin-left: 2px;
    color: ${colors.BuyRed};
    font-family: "Rubik", sans-serif;
    letter-spacing: -2px;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      margin-right: 5px;
      display: inline-block;
      font-size: 1.2rem;
    }
  }

  .last-chance {
    justify-content: center;
    display: flex;
    align-items: flex-end;

    .action {
      font-size: 1.6rem;
      display: flex;
      justify-content: end;
    }
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledAddToCartBtn = styled(AddToCartButton)`
  button {
    padding: 0.5rem 1rem !important;
    width: auto;

    &.count {
      &:hover {
        background-color: ${colors.accent} !important;
        color: ${colors.black};
      }
    }
  }

  .add-btn {
    background-color: transparent;
    color: ${colors.black};
    border: 1px solid ${colors.accent};
    line-height: 1;
    width: 100%;
  }
`;

export const ProductCardSmall = (props: {
  campaign: Campaign;
  inCartCount: number;
  addToCart: (id: number) => void;
  removefromCart: (id: number) => void;
}) => {
  const campaignTitle = useTranslatedLabel(props.campaign, "campaignTitle");

  const timeReamaining = useCountDown(props.campaign.closingDate);
  const days = getPeriodInTwoDigits(timeReamaining.days);
  const hours = getPeriodInTwoDigits(timeReamaining.hours);
  const minutes = getPeriodInTwoDigits(timeReamaining.minutes);
  const seconds = getPeriodInTwoDigits(timeReamaining.seconds);

  return (
    <CardWrapper>
      <div className="closing-soon-item">
        <div className="closing-indicator d-none d-md-block">
          {props.campaign.type === "1" ? (
            <>
              <p className="progress-text d-none d-md-block">
                {" "}
                {props.campaign.soldItemNumber || 0} sold out of{" "}
                {props.campaign.totalItemNumber}
              </p>
              <div className="progress-container d-none d-md-block">
                <LinearProgress
                  progress={
                    props.campaign.soldItemNumber /
                    props.campaign.totalItemNumber
                  }
                ></LinearProgress>
              </div>
            </>
          ) : (
            <StyledClosingTimer>
              Closing in{" "}
              <span className="timer">
                {days}:{hours}:{minutes}:{seconds}
              </span>
            </StyledClosingTimer>
          )}
        </div>
        <div className="image-container mt-2">
          <FullWidthImage
            src={`${process.env.REACT_APP_API_URL}/image/${props.campaign.campaignImage}`}
          ></FullWidthImage>
          <div
            className="add-to-cart-plus-mobile d-flex d-md-none"
            onClick={() =>
              props.addToCart && props.addToCart(props.campaign.id)
            }
          >
            <Plus></Plus>
          </div>
        </div>
        <div className="last-chance d-none d-md-flex">
          <div className="text d-block d-md-none">
            <span>Last chance to</span>
          </div>
          <div className="image-container d-block d-md-none">
            <FullWidthImage
              src={`${process.env.REACT_APP_API_URL}/image/${props.campaign.campaignImage}`}
            ></FullWidthImage>
          </div>
          <div className="last-chance mt-1 d-none d-md-flex">
            <div className="text">
              <span>Last chance to</span>
            </div>
            <div className="action">
              <span className="buy-link">Win</span>
            </div>
          </div>
          <h5 className="name d-block d-md-none" title={campaignTitle}>
            {campaignTitle}
          </h5>
          <div className="add-to-cart my-2 d-block d-md-none">
            <StyledButton
              border={`1px solid ${colors.black}`}
              borderRadius="15px"
              onClick={() =>
                props.addToCart && props.addToCart(props.campaign.id)
              }
            >
              Add to cart
            </StyledButton>
          </div>
        </div>
        <h5 className="name mt-1 fw-bold" title={campaignTitle}>
          <span className="buy-link d-inline-block d-md-none">Win</span>
          {campaignTitle}
        </h5>
        <div className="progress-container d-block d-md-none">
          {props.campaign.type === "1" ? (
            <>
              <LinearProgress
                progress={
                  props.campaign.soldItemNumber / props.campaign.totalItemNumber
                }
              ></LinearProgress>
              <div className="d-flex mt-1">
                <span className="fw-bold">{props.campaign.soldItemNumber}</span>
                /{props.campaign.totalItemNumber}
              </div>
            </>
          ) : (
            <StyledClosingTimer>
              Closing in{" "}
              <span className="timer">
                {days}:{hours}:{minutes}:{seconds}
              </span>
            </StyledClosingTimer>
          )}
        </div>
        <div className="add-to-cart my-2 d-none d-md-block">
          <StyledAddToCartBtn
            count={props.inCartCount}
            onAddToCart={() => props.addToCart(props.campaign.id)}
            onRemoveFromCart={() => props.removefromCart(props.campaign.id)}
          ></StyledAddToCartBtn>
        </div>
      </div>
    </CardWrapper>
  );
};
