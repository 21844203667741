import useCountDown from "hooks/useCountdown";
import styled from "styled-components";
import { colors } from "./variables";
import { getPeriodInTwoDigits } from "shared/utils/utils";

const TimerWrapper = styled.div`
  margin-top: auto;
  color: #ff0000;

  .closing-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: "Rubik", sans-serif;
    font-size: 12px;

    .time {
      display: flex;

      .section {
        width: 2rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
          width: 1.2rem;
          color: ${colors.BuyRed};
        }

        .title {
          font-size: 0.5rem;
        }

        .value {
          font-family: "Rubik", sans-serif;
          font-weight: 500;
          font-size: 1.1rem;
          line-height: 1.2;
          display: flex;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }

          &:not(.last)::after {
            content: ":";
            display: block;
            margin-left: 2px;

            @media screen and (max-width: 768px) {
              margin-left: 1px;
            }
          }
        }
      }
    }
  }
`;

export const ClosingTimer = (props: {
  closingDate: string;
  title: string;
  className?: string;
}) => {
  const timeRemaining = useCountDown(props.closingDate);

  const seconds = getPeriodInTwoDigits(timeRemaining.seconds);
  const minutes = getPeriodInTwoDigits(timeRemaining.minutes);
  const hours = getPeriodInTwoDigits(timeRemaining.hours);
  const days = getPeriodInTwoDigits(timeRemaining.days);

  if (
    timeRemaining.seconds +
      timeRemaining.minutes +
      timeRemaining.hours +
      timeRemaining.days <=
    0
  ) {
    return (
      <TimerWrapper className={props.className}>
        Campaign has ended
      </TimerWrapper>
    );
  }

  return (
    <TimerWrapper className={props.className}>
      <div className="closing-time">
        <div className="title-deal fw-bold">{props.title}</div>
        <div className="time">
          <div className="section">
            <div className="value">{days}</div>
          </div>
          <div className="section">
            <div className="value">{hours}</div>
          </div>
          <div className="section">
            <div className="value">{minutes}</div>
          </div>
          <div className="section">
            <div className="value last">{seconds}</div>
          </div>
        </div>
      </div>
    </TimerWrapper>
  );
};
