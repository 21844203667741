import { Campaign } from "models/Campaign";
import { apiSlice } from "../api-slice";
import { Order } from "models/Oder";

const baseUrl = "/order";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrderDetails: builder.query<Order, number>({
      query(orderID) {
        return {
          url: `${baseUrl}/${orderID}`,
          method: "GET",
        };
      },
    }),
    fetchAllOrders: builder.query<Order[], void>({
      query() {
        return {
          url: `${baseUrl}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useFetchOrderDetailsQuery, useFetchAllOrdersQuery } =
  extendedApiSlice;
