import { DateTime } from "luxon";
import { Campaign } from "models/Campaign";

const toSentenceCase = (text: string) =>
  `${(text || "").charAt(0).toUpperCase()}${(text || "").substring(
    1,
    text?.length
  )}`;

const priceFormatter = new Intl.NumberFormat("en-LK", {
  style: "currency",
  currency: "LKR",
  currencyDisplay: "narrowSymbol",
});
const getPriceFromNumber = (amount: number) => {
  if (isNaN(amount)) {
    return "";
  }
  return priceFormatter.format(amount);
};

const getPriceFromString = (amount: string) => {
  if (amount === "" || amount === undefined || amount === null) {
    return "";
  }

  const price = Number.parseFloat(amount);

  if (isNaN(price)) {
    return "";
  }

  return getPriceFromNumber(price);
};

const getImagePath = (imageName: string) => {
  if (!imageName) {
    return "";
  }
  return `${process.env.REACT_APP_API_URL}/image/${imageName}`;
};

const getPeriodInTwoDigits = (period: number) => {
  if (period <= 9) {
    return `0${period}`;
  }
  if (period > 99) {
    return "99";
  }
  return period.toString();
};

export {
  priceFormatter,
  toSentenceCase,
  getPriceFromString,
  getPriceFromNumber,
  getImagePath,
  getPeriodInTwoDigits,
};
