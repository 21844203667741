import check from "assets/images/icons/check-success.svg";
import styled from "styled-components";
import {
  ContainerCard,
  FullWidthImage,
} from "components/common-styled-components/styled-components";
import { ProductList } from "./product-list";
import { useParams } from "react-router-dom";
import { useFetchOrderDetailsQuery } from "features/order/order-slice";
import { colors, styles } from "components/variables";
import { getPriceFromNumber } from "shared/utils/utils";
import { TicketCard, TicketCardProps } from "components/ticket-card";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledOrderWrapper = styled.div``;

const StyledContainerCard = styled(ContainerCard)`
  width: 40%;
  margin: auto;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    width: auto;
  }

  .check-order {
    width: 85px;
    height: 85px;
  }

  .shipping {
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${colors.accent};
  }

  .total {
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .row-2 {
      font-weight: 500;
    }
  }

  &.tickets {
    background-color: ${colors.backgroundColor};
    box-shadow: 0px 4px 31px 6px rgb(0 0 0 / 6%);
  }
`;

const StyledProductList = styled(ProductList)`
  .product-table {
    width: 100%;
    text-align: left;

    tr {
      border-bottom: 1px solid ${colors.accent};
      > * {
        &:first-child {
          width: 50%;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  th,
  td {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .product-body {
    font-weight: 500;
  }
`;

export const OrderConfirmation = () => {
  const { orderId } = useParams();
  const { data: order, isLoading: campaignsLoading } =
    useFetchOrderDetailsQuery(Number(orderId));
  const { t } = useTranslation();

  let purchaseCampaigns: TicketCardProps[] = [];
  if (order?.cart) {
    purchaseCampaigns = order.cart.map((c) => {
      const product = c.campaign.product;
      const offer = c.campaign.mainOffer;

      const tickets = order.tickets.filter(
        (t) => t.campaignId === c.campaignId
      );

      return {
        product,
        tickets,
        offer,
      };
    });
  }

  return (
    <StyledOrderWrapper>
      <StyledContainerCard className="mt-5 p-4 mb-3">
        <FullWidthImage
          src={check}
          className="mb-4 check-order"
        ></FullWidthImage>
        <h5 className="fw-bold">{t(Translations.OrderCompletionMessage)}</h5>
        <p className="text-secondary">
          {t(Translations.ConfirmationEmailMessage)}
        </p>
      </StyledContainerCard>

      {campaignsLoading ? null : (
        <StyledContainerCard className="mt-5 p-4 mb-3">
          <StyledProductList
            pruchasedProducts={(order?.cart || []).map((c) => ({
              numberOfUnits: c.numberOfUnits,
              products: c.campaign.product,
            }))}
            total={order?.total || 0}
          ></StyledProductList>
          <div className="shipping d-flex justify-content-between">
            <span className="title">Shipping</span>
            <span className="value">Store Pickup</span>
          </div>
          <div className="total d-flex justify-content-between">
            <div className="title text-start">
              <div className="row-1 fw-bold fs-5">Total</div>
              <div className="row-2">{/*t(Translations.InclusiveOfVAT)*/}</div>
            </div>
            <div className="value d-flex align-self-center fw-bold fs-3">
              {getPriceFromNumber(order?.total || 0)}
            </div>
          </div>
        </StyledContainerCard>
      )}

      <StyledContainerCard className="mt-5 p-4 mb-3 tickets">
        <h4 className="fw-bolder mb-3">{t(Translations.PurchasedTickets)}</h4>
        {purchaseCampaigns.map((p, i) => (
          <TicketCard
            className="mb-5"
            product={p.product}
            tickets={p.tickets}
            offer={p.offer}
            key={i}
          ></TicketCard>
        ))}
      </StyledContainerCard>
    </StyledOrderWrapper>
  );
};
