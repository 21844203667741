import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ProtectedPage = ({ children }) => {
  const token = useSelector((state: RootState) => state.auth.token);

  if (!token) {
    toast.warning("You must be logged in to do that.");

    return <Navigate to="/" replace></Navigate>;
  }
  return children;
};
