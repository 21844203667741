import styled from "styled-components";
import { colors, styles } from "./variables";
import { Product } from "models/Product";
import { Ticket } from "models/Ticket";
import { FullWidthImage } from "./common-styled-components/styled-components";
import logo from "assets/images/Logo.svg";
import cash from "assets/images/cash.png";
import { getImagePath, getPriceFromNumber } from "shared/utils/utils";
import { DateTime } from "luxon";
import { Offer } from "models/Offer";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";

const StyledTicketWrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 15px;
  position: relative;

  .summary {
    padding: 15px 35px;
    position: relative;

    .logo {
      width: 145px;
      height: 40px;

      img {
        object-fit: contain;
      }
    }

    .cash-img {
      width: 130px;
      height: 75px;
    }

    .price {
      font-size: 14px;
    }

    .title {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 25ch;
    }
  }

  .ticket-list {
    font-size: 0.8rem;

    @media screen and (max-width: ${styles.breakpoints.medium}) {
      font-size: 11px;
    }

    .details {
      background-color: ${colors.white};
      border-radius: 0 0 15px 15px;
      box-shadow: rgba(0, 0, 0, 0.23) 0px 7px 6px -5px;
      margin-top: 7px;

      &:first-child {
        margin-top: 0;
        border-top: 2px dashed ${colors.accent};
      }

      .ticket-number {
        font-size: 14px;
      }
    }
  }

  .cutout {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background-color: ${colors.backgroundColor};
    border-radius: 50%;

    &.left {
      left: 0;
      transform: translateX(-50%);
    }

    &.right {
      right: 0;
      transform: translateX(50%);
    }
  }
`;

export type TicketCardProps = {
  product: Product;
  tickets: Ticket[];
  offer: Offer;
};

export const TicketCard = (props: TicketCardProps & { className?: string }) => {
  const { t: trans } = useTranslation();
  const offerTitle = useTranslatedLabel(props.offer, "offerTitle");

  return (
    <StyledTicketWrapper className={props.className}>
      <div className="summary d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column text-start">
          <div className="logo mb-2">
            <FullWidthImage src={logo}></FullWidthImage>
          </div>
          <h3 className="price mb-1 fw-bold">
            {getPriceFromNumber(props.product.price)} Cash
          </h3>
          <div className="title" title={offerTitle}>
            {offerTitle}
          </div>
        </div>
        <div className="cash-img">
          <FullWidthImage
            src={getImagePath(props.offer.offerImage)}
          ></FullWidthImage>
        </div>

        <div className="left cutout"></div>
        <div className="right cutout"></div>
      </div>
      <div className="ticket-list">
        {props.tickets.map((t, i) => (
          <div
            className="details d-flex justify-content-between align-items-center pt-1 py-2 px-3"
            key={i}
          >
            <div className="left text-start">
              <div className="stat">
                <span className="fw-bold">
                  {trans(Translations.IssuedOn)}:{" "}
                </span>
                <span>
                  {DateTime.fromISO(t.createdDate).toFormat("hh:mm dd.ll.yy")}
                </span>
              </div>
              <div className="stat">
                <span className="fw-bold"> {trans(Translations.DrawOn)}: </span>
                <span>
                  {DateTime.fromISO(t.drawDate).toFormat("dd LLLL, yyyy")}
                </span>
              </div>
            </div>
            <div className="right text-start">
              <div className="stat">
                <span className="fw-bold">
                  {" "}
                  {trans(Translations.TicketNumber)}.{" "}
                </span>
              </div>
              <div className="stat">
                <span className="ticket-number">{t.ticketNumber}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </StyledTicketWrapper>
  );
};
