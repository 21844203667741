import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { Translations } from "i18n/i18n";
import { Product } from "models/Product";
import { useTranslation } from "react-i18next";
import { getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";

const StyledProductList = styled.div``;

type ProductListProps = {
  pruchasedProducts: {
    products: Product;
    numberOfUnits: number;
  }[];
  total: number;
};

export const ProductList = (
  props: ProductListProps & { className?: string }
) => {
  const { t } = useTranslation();

  return (
    <StyledProductList className={props.className}>
      <table className="product-table">
        <thead className="header-row">
          <tr>
            <th>{t(Translations.Product)}</th>
            <th>{t(Translations.Quantity)}</th>
            <th>{t(Translations.Subtotal)}</th>
          </tr>
        </thead>

        <tbody className="product-body">
          {props.pruchasedProducts.map((item, index) => (
            <tr key={index}>
              <ProductItemComponent
                numberOfUnits={item.numberOfUnits}
                product={item.products}
              ></ProductItemComponent>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledProductList>
  );
};

const ProductItemComponent = ({
  product,
  numberOfUnits,
}: {
  product: Product;
  numberOfUnits: number;
}) => {
  const productName = useTranslatedLabel(product, "productName");
  return (
    <>
      <td>{productName}</td>
      <td>
        Rs {product.price} x {numberOfUnits}
      </td>
      <td>{getPriceFromNumber(numberOfUnits * product.price)}</td>
    </>
  );
};
