import { ContainerCard } from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import { useFetchAllOrdersQuery } from "features/order/order-slice";
import styled from "styled-components";
import { UserTicketAccordion } from "./user-ticket-accordion";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledContainerCard = styled(ContainerCard)`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    padding: 0 0.5rem !important;
    background-color: transparent;
    box-shadow: none;
  }

  .container {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }
`;

const StyledTicketAccordion = styled(UserTicketAccordion)`
  margin-bottom: 1rem;
`;

export const UserTickets = () => {
  const { data: orders } = useFetchAllOrdersQuery();
  const { t } = useTranslation();

  return (
    <StyledContainerCard className="p-4">
      <h4 className="text-start fw-bolder mb-3">
        {t(Translations.PaymentsActiveTickets)}
      </h4>

      <div className="container px-0">
        {orders?.map((o, i) =>
          o.cart.map((c, j) => (
            <StyledTicketAccordion
              order={o}
              cartId={c.id}
              key={c.id}
            ></StyledTicketAccordion>
          ))
        )}
      </div>
    </StyledContainerCard>
  );
};
