import {
  ContainerCard,
  StyledButton,
  StyledModal,
  SyledFormCheckOrRadio,
} from "components/common-styled-components/styled-components";
import { colors, styles } from "components/variables";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as GreenTick } from "assets/images/icons/green-tick.svg";
import { Address } from "models/Address";
import {
  useAddUserAddressMutation,
  useDeleteUserAddressMutation,
  useFetchUserAddressesQuery,
  useFetchUserDetailsQuery,
  useUpdateUserAddressMutation,
} from "features/api-slice";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { DropdownItem, DropdownSearch } from "components/dropdown-search";
import { searchCities } from "api/cities";
import useDebounce from "hooks/useDebounce";

const StyledAddressCardContainer = styled.div<{ isSelected: boolean }>`
  padding: 1.25rem;
  border: 1px solid
    ${(props) => (props.isSelected ? colors.lightGreen : colors.accent)};
  border-radius: 20px;
  text-align: left;
  flex: 1;
  cursor: pointer;

  h4 {
    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 600;
  }
  input {
    font-weight: 600;
    color: #282828;
    padding: 12px 15px;
    font-size: 15px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 0.875rem;
    }
  }

  .address-container {
    font-size: 0.875rem;
  }

  .actions-container {
    font-size: 0.625rem;

    button {
      all: unset;
      text-decoration: underline;
      color: ${colors.linkBlue};
    }
  }

  .form-label {
    color: ${colors.lightGrey};
    margin: 0;
    font-size: 12px;
  }
`;

export const AddressCard = (props: {
  address: Address & { name: string };
  isSelected: boolean;
  isEditing: boolean;
  onEdit: (address: Address) => void;
  onSelect: (address: Address) => void;
  onDelete: (address: Address) => void;
  onCancelEdit: () => void;
}) => {
  const onSelectAddress = () => {
    props.onSelect(props.address);
  };
  const onEditAddress = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onEdit(props.address);
  };

  const onCancelEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onCancelEdit();
  };

  const onDeleteAddress = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onDelete(props.address);
  };

  return (
    <StyledAddressCardContainer
      onClick={onSelectAddress}
      isSelected={props.isSelected}
    >
      <div className="title-container">
        <h6 className="fw-semibold name">{props.address.name}</h6>

        {props.address.type === "1" ? <GreenTick /> : null}
      </div>

      <div className="address-container mb-1">
        <p className="mb-0">{props.address.apartment}</p>
        <p className="mb-0">{props.address.streetName}</p>
        <p className="mb-0">{props.address.area}</p>
        <p className="mb-0">{props.address.city}</p>
        <p className="mb-0">{props.address.country}</p>
      </div>
      <div className="actions-container">
        <button
          className="me-3 action"
          onClick={(e) =>
            props.isEditing ? onCancelEdit(e) : onEditAddress(e)
          }
        >
          {props.isEditing ? "Cancel" : "Edit"}
        </button>
        <button className="action" onClick={(e) => onDeleteAddress(e)}>
          Delete
        </button>
      </div>
    </StyledAddressCardContainer>
  );
};

const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Addresses = () => {
  const { register, handleSubmit, setValue, reset, control, formState } =
    useForm<Address>({
      mode: "onTouched",
      defaultValues: {
        city: "",
        apartment: "",
        area: "",
        streetName: "",
        type: "0",
      },
    });
  const [isAddAddress, setIsAddAddress] = useState(true);
  const [editAddress, setEditAddress] = useState<Address | null>(null);
  const [mainAddress, setMainAddress] = useState<Address | null>(null);
  const { data: user, isLoading: userLoading } = useFetchUserDetailsQuery();
  const { data: addresses, isLoading: addressesLoading } =
    useFetchUserAddressesQuery();
  const [addAddress] = useAddUserAddressMutation();
  const [updateAddress] = useUpdateUserAddressMutation();
  const [deleteAddress] = useDeleteUserAddressMutation();
  const [selectedCity, setSelectedCity] = useState<DropdownItem | null>(null);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [cities, setCities] = useState<DropdownItem[]>([]);
  const [hasMoreCities, setHasMoreCities] = useState(true);
  const citiesToShow = 10;
  const searchQuery = useDebounce(searchText);

  const onAddressSubmit = async (form: Address) => {
    if (isAddAddress) {
      const payload = {
        ...form,
        type: form.isPrimaryAddress ? "1" : "2",
      };
      await addAddress(payload).unwrap();
    } else {
      await updateAddress({
        ...form,
        id: editAddress?.id!,
        type: form.isPrimaryAddress ? "1" : "2",
      }).unwrap();
    }
    reset();
    setEditAddress(null);
  };

  const onEditAddress = (form: Address) => {
    setValue("apartment", form.apartment);
    setValue("area", form.area);
    setValue("city", form.city);
    setValue("streetName", form.streetName);
    setValue("isPrimaryAddress", form.type === "1");
    setIsAddAddress(false);
    setEditAddress(form);
    setSelectedCity({ id: 1, text: form.city });
  };

  const onCancelEditing = () => {
    reset();
    setIsAddAddress(true);
    setEditAddress(null);
    setSelectedCity(null);
  };

  const onSelectAddress = (address: Address) => {
    setMainAddress(address);
    handleShow();
  };

  const makePrimaryAddress = () => {
    if (mainAddress) {
      const editedAddressPayload = { ...mainAddress, type: "1" };
      updateAddress({
        ...editedAddressPayload,
      });
      reset();
      setMainAddress(null);
    }
    handleClose();
  };

  const onDeleteAddress = (address: Address) => {
    if (address.type === "1") {
      toast.warn("Cannot delete primary address.");
    } else {
      deleteAddress(address.id!);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const getCities = (start: number, count: number, text = "*") => {
    return searchCities(start, count, text).then((citiesRes) => {
      const citiesMapped = (citiesRes?.data || []).map((c) => ({
        id: c.id,
        text: c.city,
      }));
      return citiesMapped;
    });
  };

  useEffect(() => {
    if (selectedCity) {
      setValue("city", selectedCity?.text);
    }
  }, [selectedCity]);

  useEffect(() => {
    getCities(0, citiesToShow, searchQuery || "*").then((cities) => {
      setHasMoreCities(cities?.length >= citiesToShow);
      setCities(cities);
    });
  }, [searchQuery]);

  const fetchNext = (page: number) => {
    getCities(page * citiesToShow, citiesToShow, searchQuery || "*").then(
      (citiesRes) => {
        setHasMoreCities(citiesRes?.length >= citiesToShow);
        setCities([...cities, ...citiesRes]);
      }
    );
  };

  return (
    <ContainerCard className="p-4">
      <h4 className="text-start fw-bolder mb-3">My Shipping Addresses</h4>

      <AddressWrapper className="">
        {(addresses || []).map((address, index) => {
          return (
            <AddressCard
              address={{
                ...address,
                name: `${user?.firstName} ${user?.lastName}`,
              }}
              isEditing={editAddress?.id === address.id}
              isSelected={address.type === "1"}
              key={index}
              onEdit={onEditAddress}
              onSelect={onSelectAddress}
              onDelete={onDeleteAddress}
              onCancelEdit={onCancelEditing}
            />
          );
        })}
      </AddressWrapper>

      <Form onSubmit={handleSubmit(onAddressSubmit)}>
        <fieldset>
          <div className="container text-start p-0 mb-2 mt-3">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label>Apartment or Villa Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Apartment or Villa Name"
                    {...register("apartment", {
                      required: "Field is required",
                    })}
                  />
                </Form.Group>
                {formState.errors.apartment && (
                  <Form.Text className="text-danger">
                    {formState.errors.apartment.message}
                  </Form.Text>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="lName">
                  <Form.Label>Street Name or Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Street Name or Number"
                    {...register("streetName", {
                      required: "Field is required",
                    })}
                  />
                  {formState.errors.streetName && (
                    <Form.Text className="text-danger">
                      {formState.errors.streetName.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="container text-start p-0 mb-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="fName">
                  <Form.Label>Area</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Area"
                    {...register("area", { required: "Field is required" })}
                  />
                  {formState.errors.area && (
                    <Form.Text className="text-danger">
                      {formState.errors.area.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="lName">
                  <Form.Label>City</Form.Label>

                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <DropdownSearch
                        count={citiesToShow}
                        hasMore={hasMoreCities}
                        items={cities}
                        selectedItem={selectedCity}
                        searchText={searchText}
                        placeholder="City"
                        fetchNext={fetchNext}
                        setSearchText={setSearchText}
                        setSelectedItem={setSelectedCity}
                        {...field}
                      ></DropdownSearch>
                    )}
                  ></Controller>

                  {formState.touchedFields.city && !selectedCity && (
                    <Form.Text className="text-danger">
                      Field is required
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="container text-start p-0 mb-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="isPrimaryAddress">
                  <SyledFormCheckOrRadio
                    type="checkbox"
                    id="isPrimaryAddressChk"
                    label="Set as default address"
                    {...register("isPrimaryAddress")}
                  ></SyledFormCheckOrRadio>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="container mt-5 pt-5 mb-2">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 form-btn">
                <StyledButton
                  color={colors.white}
                  borderRadius="7px"
                  className="fw-bold"
                  disabled={
                    (isAddAddress && (addresses?.length || 0) > 2) ||
                    !formState.isValid ||
                    !selectedCity
                  }
                >
                  {isAddAddress ? "Add Address" : "Update Address"}
                </StyledButton>
              </div>
            </div>
          </div>
        </fieldset>
      </Form>

      <StyledModal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <h5 className="mb-4">Confirm Primary Address</h5>

          <p className="mb-4">
            Are you sure to make this your primary address?
          </p>

          <div className="container">
            <div className="row justify-content-end">
              <div className="col-4">
                <StyledButton
                  color={colors.white}
                  borderRadius="7px"
                  backgroundColor={colors.colorMain}
                  onClick={makePrimaryAddress}
                >
                  Confirm
                </StyledButton>
              </div>
              <div className="col-4">
                <StyledButton
                  color={colors.colorMain}
                  borderRadius="7px"
                  border={`1px solid ${colors.colorMain}`}
                  onClick={handleClose}
                >
                  Cancel
                </StyledButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </StyledModal>
    </ContainerCard>
  );
};
