import { ClosingTimer } from "components/closing-timer";
import {
  AppSpinner,
  ContainerCard,
  FullWidthImage,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { ImageComponent } from "components/image";
import { ProgressRound } from "components/progress-round";
import { colors, styles } from "components/variables";
import { Icon } from "components/icon";
import {
  useAddToCartMutation,
  useAddToWishlistMutation,
  useFetchCampaignQuery,
  useFetchCartQuery,
  useFetchWishlistQuery,
  useRemoveFromCartMutation,
  useRemoveWishlistMutation,
} from "features/api-slice";
import { useParams } from "react-router-dom";
import { getImagePath, getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";
import { ReactComponent as Heart } from "assets/images/icons/heart.svg";
import { ReactComponent as Share } from "assets/images/icons/share.svg";
import { CampaignDisplayImage } from "components/campaign-display-image";
import { DateTime } from "luxon";
import { TabView } from "components/tab-view";
import { Offer } from "models/Offer";
import { Product } from "models/Product";
import { AddToCartButton } from "components/add-to-cart-btn";
import { Helmet, HelmetProvider, HelmetData } from "react-helmet-async";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";

const StyledCampaignPageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .campaign-content {
    display: flex;
    gap: 2.5rem;
    flex: 1;

    .offer-and-product {
      flex-basis: 65%;
      background-color: ${colors.white};
      border-radius: 10px;
      box-shadow: ${styles.boxShadow};

      .buy-placeholder {
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          font-size: 1rem;
        }
      }

      .price {
        color: ${colors.colorMain};
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          font-size: 1rem;
        }
      }

      .offer-image {
        img {
          object-fit: cover;
        }
        @media screen and (max-width: ${styles.breakpoints.medium}) {
          max-height: 100px;
        }

        > div {
          border-radius: 15px;
          overflow: hidden;
        }
      }

      .draw-date {
        font-size: 0.75rem;
        @media screen and (min-width: ${styles.breakpoints.medium}) {
          max-width: 50%;
        }

        @media screen and (max-width: ${styles.breakpoints.medium}) {
          font-size: 0.5rem;
        }
      }
    }

    .description {
      flex: 1;

      .wrapper {
        background-color: ${colors.white};
        border-radius: 10px;
        box-shadow: ${styles.boxShadow};
        text-align: left;

        .button-container {
          line-height: 3rem;
          button {
            font-weight: 500;
            font-size: 15px;
          }
        }

        .price-container {
          h5 {
            font-size: 22px;
            letter-spacing: -0.5px;
          }
          .price {
            letter-spacing: -1px;
            font-size: 30px !important;
            font-weight: 600 !important;
          }
        }

        .remaining-container {
          display: flex;
          justify-content: center;
          flex-direction: row;
          border-bottom: 2px solid #f2f5f8;
          padding-bottom: 20px;
          margin-bottom: 10px;
        }
      }
    }

    .icon-container {
      gap: 1rem;
    }
  }
`;

const StyledAddToCartButton = styled(AddToCartButton)`
  button {
    line-height: 1 !important;
  }
`;

export const CampaignPage = () => {
  const { id } = useParams();
  const { data: campaign, isFetching } = useFetchCampaignQuery(Number(id));
  const productName = useTranslatedLabel(campaign?.product, "productName");
  const [addToCart, { isLoading: addingToCart }] = useAddToCartMutation({
    fixedCacheKey: "shared-cart",
  });
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const [removeFromCart, { isLoading: removingFromCart }] =
    useRemoveFromCartMutation();
  const { data: cart = [] } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: wishlist } = useFetchWishlistQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [addToWishlist, { isLoading: addingToWishlist }] =
    useAddToWishlistMutation();
  const [removeWishlist, { isLoading: removingFromWishlist }] =
    useRemoveWishlistMutation();
  const { t } = useTranslation();

  const isWishlisted = (campaignId: number) => {
    return wishlist?.find((w) => w.campaignId === campaignId);
  };

  const onClickAddToWishlist = (id: number) => {
    if (!(addingToWishlist || removingFromWishlist)) {
      if (isWishlisted(id)) {
        const wishlistToRemove = wishlist?.find((w) => w.campaignId === id);
        if (wishlistToRemove) {
          removeWishlist(wishlistToRemove.id);
        }
      } else {
        addToWishlist(id);
      }
    }
  };

  const helmetData = new HelmetData({});

  return (
    <StyledCampaignPageWrapper className="mt-5">
      {isFetching ? (
        <AppSpinner show={true} />
      ) : (
        <div className="campaign-content mb-5 d-flex flex-column flex-md-row">
          <Helmet helmetData={helmetData}>
            <meta property="og:title" content={campaign?.product.productName} />
            <meta
              property="og:image"
              content={`${getImagePath(campaign?.campaignImage || "")}`}
            />
            <meta property="og:url" content="https://lankadealz.com" />
            <meta property="og:type" content="website" />
          </Helmet>

          <div className="offer-and-product p-4">
            <CampaignDisplayImage
              campaign={campaign!}
              className="mb-4"
            ></CampaignDisplayImage>

            <div className="details container text-start">
              <div className="row">
                <div className="col-8 col-md-9 d-flex flex-column justify-content-center">
                  <h4 className="buy-placeholder fw-bold mb-1">
                    Buy: {productName}
                  </h4>
                  <h4 className="price fw-bold mb-2">
                    {getPriceFromNumber(campaign?.total!)}
                  </h4>
                  <p className="mb-0 draw-date">
                    Draw date{" "}
                    <span className="fw-semibold">
                      {DateTime.fromISO(campaign?.closingDate!).toLocaleString(
                        DateTime.DATE_FULL
                      )}
                    </span>{" "}
                    or earlier if the campaign is sold out
                  </p>
                </div>
                <div className="col-4 col-md-3 offer-image">
                  <ImageComponent
                    className="d-flex flex-column justify-content-start"
                    src={getImagePath(campaign?.product.imageName!)}
                  ></ImageComponent>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-9">
                  <TabView
                    activeTabIndex={0}
                    tabs={[
                      {
                        title: "Offer Details",
                        classNames: "col-6 col-md-4",
                        child: (
                          <OfferDetails
                            offer={campaign?.mainOffer}
                          ></OfferDetails>
                        ),
                      },
                      {
                        title: "Product Details",
                        classNames: "col-6 col-md-4",
                        child: (
                          <ProductDetails
                            product={campaign?.product}
                          ></ProductDetails>
                        ),
                      },
                    ]}
                  ></TabView>
                </div>
              </div>
            </div>
          </div>
          <div className="description">
            <div className="wrapper p-4">
              <div className="remaining-container">
                {campaign?.type === "1" ? (
                  <ProgressRound
                    total={campaign.totalItemNumber || 0}
                    sold={campaign.soldItemNumber || 0}
                  ></ProgressRound>
                ) : (
                  <ClosingTimer
                    closingDate={campaign?.closingDate!}
                    title="Deal closing in"
                  ></ClosingTimer>
                )}
              </div>

              <div className="price-container d-flex justify-content-between align-items-center mt-3">
                <div className="title">
                  <h5 className="fw-bolder m-0">{t(Translations.Price)}</h5>
                  <p className="m-0">{/*t(Translations.InclusiveOfVAT)*/}</p>
                </div>
                <div className="price fs-4">
                  {getPriceFromNumber(campaign?.total!)}
                </div>
              </div>

              <div className="button-container mt-4">
                <StyledAddToCartButton
                  count={
                    cart.find((c) => c.campaignId === campaign?.id)
                      ?.numberOfUnits || 0
                  }
                  onAddToCart={() =>
                    addToCart({
                      campaignId: campaign?.id!,
                      numberOfUnits: 1,
                    })
                  }
                  onRemoveFromCart={() => removeFromCart(campaign?.id!)}
                ></StyledAddToCartButton>
              </div>
            </div>

            <div className="d-none d-md-flex justify-content-center icon-container mt-4">
              <Icon
                isActive={
                  !!wishlist?.find((w) => w.campaignId === campaign?.id)
                }
                onClick={() => onClickAddToWishlist(campaign?.id!)}
              >
                <Heart></Heart>
              </Icon>
              <Icon hoverColor={colors.colorMain}>
                <Share></Share>
              </Icon>
            </div>
          </div>
        </div>
      )}
    </StyledCampaignPageWrapper>
  );
};

const SytledOfferAndDetailsWrapper = styled.div`
  .title {
    font-size: 1.2rem;
  }
`;

const OfferDetails = (props: { offer?: Offer; className?: string }) => {
  const offerTitle = useTranslatedLabel(props.offer, "offerTitle");
  const description = useTranslatedLabel(props.offer, "description");

  return (
    <SytledOfferAndDetailsWrapper
      className={`${props.className ?? ""} offer-details`}
    >
      <h4 className="title fw-bold">{offerTitle}</h4>
      <p className="details">{description}</p>
    </SytledOfferAndDetailsWrapper>
  );
};

const ProductDetails = (props: { product?: Product; className?: string }) => {
  const productName = useTranslatedLabel(props.product, "productName");
  const productDescription = useTranslatedLabel(props.product, "description");

  return (
    <SytledOfferAndDetailsWrapper
      className={`${props.className ?? ""} offer-details`}
    >
      <h4 className="title fw-bold">{productName}</h4>
      <p className="details">{productDescription}</p>
    </SytledOfferAndDetailsWrapper>
  );
};
