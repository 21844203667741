import styled from "styled-components";
import UserImg from "assets/images/user-avatar.svg";
import { FullWidthImage } from "./common-styled-components/styled-components";
import { colors } from "./variables";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";

const StyledUserAvatarWrapper = styled(NavLink)`
  all: unset;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  background-color: ${colors.subtleGrey};
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    color: inherit;
  }

  .name-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    .welcome {
      font-size: 0.7rem;
    }

    .name {
      font-family: "Rubik", sans-serif;
      color: ${colors.colorMain};
      font-weight: 500;
      max-width: 20ch;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .image {
    .user-image-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
`;

export const UserAvatar = (props: { userName: string; userImage: string }) => {
  const { t } = useTranslation();
  return (
    <StyledUserAvatarWrapper to="user">
      <div className="name-container">
        <h6 className="welcome mb-0 text-end">{t(Translations.Welcome)},</h6>
        <p className="name mb-0"> {props.userName} </p>
      </div>
      <div className="image ms-2">
        <div className="user-image-wrapper">
          <FullWidthImage src={props.userImage || UserImg}></FullWidthImage>
        </div>
      </div>
    </StyledUserAvatarWrapper>
  );
};
