import { apiSlice } from "features/api-slice";

const baseUrl = "/user";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postFeedack: builder.mutation<
      void,
      { email: string; name: string; message: string }
    >({
      query(feedback) {
        return {
          url: `${baseUrl}/feedback`,
          method: "POST",
          body: feedback,
        };
      },
    }),
    changePhone: builder.mutation<void, { otp: string; phone: string }>({
      query(payload) {
        return {
          url: `${baseUrl}/change-phone`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const { usePostFeedackMutation, useChangePhoneMutation } =
  extendedApiSlice;
