import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { CampaignDetailsModalComponent } from "components/campaign-details-modal";
import { ProductCardLarge } from "components/product-card-large/product-card-large";
import {
  useAddToCartMutation,
  useAddToWishlistMutation,
  useFetchCampaignsQuery,
  useFetchCartQuery,
  useFetchWishlistQuery,
  useRemoveFromCartMutation,
  useRemoveWishlistMutation,
} from "features/api-slice";
import { Campaign } from "models/Campaign";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductCardWraper = styled.div`
  margin-bottom: 10px;
`;

const StyledProductCard = (props: {
  item: Campaign;
  isWishlisted: boolean;
  numberOfUnitsInCart: number;
  onAddToCart: (id: number, numUnits: number) => void;
  onRemoveFromCart: (id: number) => void;
  onAddToWishList: (id: number) => void;
  onItemDetails: (id: number) => void;
}) => {
  const navigate = useNavigate();

  const gotoCampaign = () => {
    navigate(`/campaign/${props.item.id}`);
  };

  return (
    <div>
      {/* Desktop */}
      <ProductCardWraper className="d-none d-md-block">
        <ProductCardLarge {...props}></ProductCardLarge>
      </ProductCardWraper>
      {/* Mobile View  */}
      <ProductCardWraper className="d-block d-md-none" onClick={gotoCampaign}>
        <ProductCardLarge {...props}></ProductCardLarge>
      </ProductCardWraper>
    </div>
  );
};

export const GreatDeals = () => {
  const { data = [], isFetching } = useFetchCampaignsQuery();
  const [modalShow, setModalShow] = useState(false);
  const [campaignSelected, setCampaignSelected] = useState<Campaign | null>(
    null
  );
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: wishlist } = useFetchWishlistQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [addToCart, { isLoading: addingToCart }] = useAddToCartMutation({
    fixedCacheKey: "shared-cart",
  });
  const [removeFromCart, { isLoading: removingFromCart }] =
    useRemoveFromCartMutation();
  const [addToWishlist, { isLoading: addingToWishlist }] =
    useAddToWishlistMutation();
  const [removeWishlist, { isLoading: removingFromWishlist }] =
    useRemoveWishlistMutation();
  const { data: cart, isLoading: cartLoading } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });
  // CampaignId, number of units in cart
  const [campaignsInCart, setCampaignsInCart] = useState<Map<number, number>>(
    new Map<number, number>([])
  );

  useEffect(() => {
    const map = new Map<number, number>();
    cart?.forEach((item) => {
      map.set(item.campaignId, item.numberOfUnits);
    });
    setCampaignsInCart(map);
  }, [cart]);

  const openItemDetails = (id: number) => {
    const campaign = data.find((d) => d.id === id)!;
    setCampaignSelected(campaign);
    setModalShow(true);
  };

  const hideModal = () => {
    setCampaignSelected(null);
    setModalShow(false);
  };

  const isWishlisted = (campaignId: number) => {
    return wishlist?.find((w) => w.campaignId === campaignId);
  };

  const onClickAddToWishlist = (id: number) => {
    if (!(addingToWishlist || removingFromWishlist)) {
      if (isWishlisted(id)) {
        const wishlistToRemove = wishlist?.find((w) => w.campaignId === id);
        if (wishlistToRemove) {
          removeWishlist(wishlistToRemove.id);
        }
      } else {
        addToWishlist(id);
      }
    }
  };

  const onAddToCart = (id: number, numOfUnits: number) => {
    if (!(addingToCart || removingFromCart)) {
      addToCart({ campaignId: id, numberOfUnits: numOfUnits || 1 });
    }
  };

  const onRemoveFromCart = (id: number) => {
    removeFromCart(id);
  };

  return (
    <ColumnWrapper>
      {isFetching ? (
        <div>Loading ... </div>
      ) : (
        data.map((item, index) => (
          <StyledProductCard
            key={index}
            item={item}
            numberOfUnitsInCart={campaignsInCart.get(item.id) || 0}
            isWishlisted={!!isWishlisted(item.id)}
            onAddToWishList={onClickAddToWishlist}
            onRemoveFromCart={onRemoveFromCart}
            onAddToCart={onAddToCart}
            onItemDetails={openItemDetails}
          ></StyledProductCard>
        ))
      )}
      {campaignSelected ? (
        <CampaignDetailsModalComponent
          campaign={campaignSelected}
          show={modalShow}
          onHide={hideModal}
        ></CampaignDetailsModalComponent>
      ) : null}
    </ColumnWrapper>
  );
};
