import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as Close } from "assets/images/icons/close-x.svg";
import { ReactComponent as Heart } from "assets/images/icons/favorite.svg";
import { ReactComponent as FullScreen } from "assets/images/icons/full-screen.svg";
import { ReactComponent as Share } from "assets/images/icons/share.svg";
import {
  useAddToCartMutation,
  useAddToWishlistMutation,
  useFetchCartQuery,
  useFetchWishlistQuery,
  useRemoveFromCartMutation,
} from "features/api-slice";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";
import { Campaign } from "models/Campaign";
import { Offer } from "models/Offer";
import { Product } from "models/Product";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";
import { AddToCartButton } from "./add-to-cart-btn";
import { Icon } from "./icon";
import { ImageComponent } from "./image";
import { TabView } from "./tab-view";
import { colors } from "./variables";

const StyledModalContainer = styled(Modal.Body)`
  --border-radius: 7px;

  .campaign-container {
    position: relative;
    min-height: 250px;

    .image-container {
      width: 100%;
      max-width: 550px;
      margin: auto;
      height: 300px;
      overflow: hidden;
      border-radius: 15px;
      .image {
        object-fit: cover !important;
      }
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      align-items: end;
      gap: 10px;

      .action {
        cursor: pointer;
      }
    }
  }
  .offer-and-product {
    .nav-tabs {
      border: 1px solid ${colors.darkGrey};
      border-radius: var(--border-radius);
      padding: 5px;
      display: flex;
      gap: 10px;

      .nav-item {
        flex: 1;
        .nav-link {
          border: none;
          color: ${colors.black};
          width: 100%;
          font-weight: 500;
          border-radius: var(--border-radius);

          &.active {
            background-color: ${colors.darkGrey};
          }
        }
      }
    }

    .tab-content {
      .offer-details-tab {
        .closing-info {
          width: 15rem;
          display: flex;
          align-items: center;
          border: 1px solid ${colors.accent};
          border-radius: 10px;

          .image-container {
            width: 30px;
            height: 30px;
          }

          .info-container {
            .title {
              font-size: 0.7rem;
              font-weight: 500;
            }
            .date {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;

const StyledModalFooter = styled(Modal.Footer)`
  background-color: ${colors.darkGrey};
  border-top: none;
  display: flex;
  align-items: stretch;

  .campaign-details {
    display: flex;
    flex-direction: column;
    flex: 1;

    .total {
      color: ${colors.colorMain};
      margin-top: 5px;
    }
  }

  .add-to-cart-container {
    flex: 1;
  }
`;

const StyledAddToCartButton = styled(AddToCartButton)`
  button {
    line-height: normal !important;
    &.mini {
      border: 1px solid ${colors.accent};
    }
  }
`;

const OfferDetails = (props: { offer: Offer }) => {
  const offerTitle = useTranslatedLabel(props.offer, "offerTitle");
  const description = useTranslatedLabel(props.offer, "description");

  return (
    <>
      <p className="mb-1">Get a chance to win</p>
      <h4 className="mb-2 fw-bolder">{offerTitle}</h4>
      <p className="mb-4">{description}</p>
    </>
  );
};

const ProductDetails = (props: { product: Product }) => {
  const productName = useTranslatedLabel(props.product, "productName");
  const description = useTranslatedLabel(props.product, "description");

  return (
    <>
      <h4 className="mb-2 fw-bolder">{productName}</h4>
      <p className="mb-4">{description}</p>
    </>
  );
};

export const CampaignDetailsModalComponent = (props: {
  campaign: Campaign;
  show: boolean;
  onHide: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<string>("offer");
  const [displayImage, setDisplayImage] = useState(
    props.campaign.mainOffer?.offerImage
  );
  const navigate = useNavigate();

  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const { data: wishlist = [] } = useFetchWishlistQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [addToWishlist] = useAddToWishlistMutation();
  const { data: cart = [] } = useFetchCartQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [addToCart] = useAddToCartMutation({
    fixedCacheKey: "shared-cart",
  });
  const [removeFromCart] = useRemoveFromCartMutation();
  const productTitle = useTranslatedLabel(
    props.campaign.product,
    "productName"
  );

  useEffect(() => {
    if (selectedTab === "offer") {
      setDisplayImage(props.campaign.mainOffer.offerImage);
    } else {
      setDisplayImage(props.campaign.product.imageName);
    }
  }, [selectedTab]);

  const handleFullScreen = () => {
    navigate(`/campaign/${props.campaign.id}`);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      className="campaign-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledModalContainer>
        <div className="campaign-container mb-3">
          <div className="image-container">
            <ImageComponent
              src={`${process.env.REACT_APP_API_URL}/image/${displayImage}`}
            ></ImageComponent>
          </div>

          <div className="actions">
            <div className="fs action" onClick={handleFullScreen}>
              <Icon hoverColor={colors.colorMain}>
                <FullScreen></FullScreen>
              </Icon>
            </div>
            <div className="close action" onClick={props.onHide}>
              <Icon hoverColor={colors.colorMain}>
                <Close></Close>
              </Icon>
            </div>
            <div
              className="add-to-wishlist"
              onClick={() => addToWishlist(props.campaign.id)}
            >
              <Icon
                isActive={
                  !!wishlist.find((w) => w.campaignId === props.campaign.id)
                }
              >
                <Heart></Heart>
              </Icon>
            </div>
            <div className="share action">
              <Icon hoverColor={colors.colorMain}>
                <Share></Share>
              </Icon>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 tab-custom">
            <TabView
              activeTabIndex={0}
              tabs={[
                {
                  title: "Offer Details",
                  classNames: "col-6",
                  child: (
                    <OfferDetails
                      offer={props.campaign?.mainOffer}
                    ></OfferDetails>
                  ),
                },
                {
                  title: "Product Details",
                  classNames: "col-6",
                  child: (
                    <ProductDetails
                      product={props.campaign?.product}
                    ></ProductDetails>
                  ),
                },
              ]}
            ></TabView>
          </div>
        </div>
      </StyledModalContainer>
      <StyledModalFooter>
        <div className="campaign-details">
          <h6 className="title mb-0">Buy {productTitle}</h6>
          <h4 className="total mb-0 fw-bolder">
            {getPriceFromNumber(props.campaign.total)}
          </h4>
        </div>
        <div className="add-to-cart-container campaign-footer">
          <StyledAddToCartButton
            onAddToCart={() =>
              addToCart({ campaignId: props.campaign.id, numberOfUnits: 1 })
            }
            onRemoveFromCart={() => removeFromCart(props.campaign.id)}
            count={
              cart.find((c) => c.campaignId === props.campaign.id)
                ?.numberOfUnits || 0
            }
          ></StyledAddToCartButton>
        </div>
      </StyledModalFooter>
    </Modal>
  );
};
