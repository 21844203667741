import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "axiosClient";
import { apiSlice } from "features/api-slice";
import { AuthUser, UserRegistration } from "models/AuthUser";
import { ResetPassword } from "models/ResetPassword";

const UserTokenStorageKey = "userToken";

interface AuthState {
  token: string | null;
  isLoggedIn: boolean;
  error: any;
  success: boolean;
  loading: boolean;
  showAuthModal: boolean;
}

const initialState: AuthState = {
  token: localStorage.getItem(UserTokenStorageKey) || null,
  isLoggedIn: !!localStorage.getItem(UserTokenStorageKey) || false,
  error: null,
  success: false,
  loading: false,
  showAuthModal: false,
};

export const registerUser = createAsyncThunk<any, UserRegistration>(
  "auth/register",
  async (
    { firstName, lastName, emailAddress, password, phone, nic },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await instance.post<{ token: string }>(
        `/auth/register`,
        { firstName, lastName, emailAddress, password, phone, nic },
        config
      );
      return data;
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loginUser = createAsyncThunk<any, AuthUser>(
  "auth/login",
  async ({ emailAddress, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await instance.post<{ token: string }>(
        `/auth/login`,
        { emailAddress, password },
        config
      );
      localStorage.setItem(UserTokenStorageKey, data.token);
      return data;
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      state.error = null;
      state.isLoggedIn = false;
      localStorage.removeItem(UserTokenStorageKey);
      return state;
    },
    showAuth: (state) => {
      state.showAuthModal = true;
    },
    hideAuth: (state) => {
      state.showAuthModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.isLoggedIn = true;
      state.token = payload.token;
      state.showAuthModal = false;
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      state.success = false;
    });
  },
});

export const { logout, showAuth, hideAuth } = authSlice.actions;
export default authSlice.reducer;

const baseUrl = "/auth";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyRegistrationOTP: builder.mutation<
      boolean,
      { email: string; OTP: string }
    >({
      query(payload) {
        return {
          url: `${baseUrl}/verify-registration-otp`,
          method: "POST",
          params: {
            email: payload.email,
            OTP: payload.OTP,
          },
        };
      },
    }),
    requestPasswordReset: builder.mutation<void, string>({
      query(email) {
        return {
          url: `${baseUrl}/forgot-password`,
          method: "POST",
          params: {
            email,
          },
        };
      },
    }),
    validateResetPasswordToken: builder.query<
      { valid: boolean },
      { email: string; token: string }
    >({
      query(payload) {
        return {
          url: `${baseUrl}/validate-token`,
          method: "POST",
          body: payload,
        };
      },
    }),
    resetPassword: builder.mutation<void, ResetPassword>({
      query(payload) {
        return {
          url: `${baseUrl}/reset-password`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useVerifyRegistrationOTPMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useValidateResetPasswordTokenQuery,
} = extendedApiSlice;
