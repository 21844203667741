import calendar from "assets/images/calendar.svg";
import { ReactComponent as Heart } from "assets/images/icons/favorite.svg";
import { ReactComponent as Share } from "assets/images/icons/share.svg";
import { AddToCartButton } from "components/add-to-cart-btn";
import { BuyText } from "components/buy";
import { Icon } from "components/icon";
import { ProgressRound } from "components/progress-round";
import { colors, styles } from "components/variables";
import { Campaign } from "models/Campaign";
import { getPeriodInTwoDigits, getPriceFromNumber } from "shared/utils/utils";
import styled from "styled-components";
import {
  FullWidthImage,
  StyledButton,
} from "../common-styled-components/styled-components";
import useCountDown from "hooks/useCountdown";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Translations } from "i18n/i18n";
import { useTranslatedLabel } from "hooks/useTranslatedLabel";

const ProductCardWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: ${colors.white};
  box-shadow: ${styles.boxShadow};
  border-radius: 20px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-top: 15px;

  &:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    overflow: visible;
  }

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    flex-direction: column;
    padding: 0 !important;
    border-radius: 20px;
    background-color: ${colors.darkRed};
    overflow: hidden;
  }

  .buy {
    font-size: 3rem;
    font-style: italic;
    margin: 0;
    font-weight: 800;
    color: ${colors.BuyRed};
    letter-spacing: -0.8px;
  }
`;

const CardTimeRemainingWrapper = styled.div`
  position: relative;
  background-color: ${colors.darkRed};
  color: ${colors.white};
`;

const ProductImageWraper = styled.div`
  width: 420px;
  max-width: 420px;
  height: 300px;
  min-height: 300px;
  border-radius: 20px;
  overflow: hidden;

  @media screen and (max-width: ${styles.breakpoints.Xlarge}) {
    max-width: 280px;
  }
  @media screen and (max-width: ${styles.breakpoints.large}) {
    width: 300px;
    max-width: 100%;
    margin-right: 50px !important;
  }

  @media screen and (max-width: ${styles.breakpoints.medium}) {
    width: 100%;
    max-width: 100%;
    height: auto;
    overflow: visible;
    position: relative;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    margin-right: 0 !important;

    img {
      filter: brightness(0.8);
    }

    .image-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(
        0deg,
        rgb(0 0 0 / 75%) 0%,
        rgb(0 0 0 / 11%) 78%,
        rgb(255 255 255 / 0%) 100%
      );
      color: ${colors.white};
    }
  }
`;

const ProductDetailsWraper = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;

  .product-name {
    font-weight: 600;
  }

  .price {
    color: ${colors.colorMain};
    font-weight: 600;
  }

  .actions {
    display: flex;
    margin-top: auto;
    > div {
      /*width: 33.3%;*/
    }

    .closing-info {
      display: flex;
      align-items: center;
      border: 1px solid ${colors.accent};
      border-radius: 10px;

      .image-container {
        width: 28px;
        height: 28px;
      }

      .info-container {
        .title {
          font-size: 0.65rem;
          font-weight: 700;
          line-height: 1.5;
        }
        .date {
          font-size: 0.65rem;
          line-height: 1;
          font-weight: 500;
        }
      }
    }
  }
`;

const ProductActionsAndStatus = styled.div`
  display: flex;
  flex-direction: column;

  .closing-time {
    .title-deal {
      font-family: "Rubik", sans-serif;
      color: ${colors.orange};
      font-weight: 600;
      letter-spacing: 0.17rem;
    }

    .time {
      display: flex;

      .section {
        margin-right: 10px;
        width: 2rem;

        .title {
          font-size: 0.5rem;
        }

        .value {
          font-family: "Rubik", sans-serif;
          font-weight: 600;
          font-size: 1.4rem;
          position: relative;
          line-height: 1.2;

          &:not(.last)::after {
            content: ":";
            display: block;
            position: absolute;
            top: 0;
            left: calc(100% + 2px);
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    align-items: end;
    gap: 10px;

    .action {
      /*border: 1px solid ${colors.accent};
      border-radius: 10px;*/
      padding: 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      width: 60px;
      height: 60px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 50%;
      -webkit-box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
      box-shadow: -4.33091px 4.33091px 25.9855px rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #0071e0;
        border: 1px solid #0071e0;
        svg {
          filter: brightness(110);
        }
      }
    }
  }
`;

const StyledProductDetailsMobile = styled.div`
  background-color: ${colors.white};

  .product-wrapper {
    > div {
      flex: 1 50%;
      align-items: flex-start;
    }

    .buy-text {
      font-size: 12px;
      letter-spacing: -0.2px;
      font-weight: 600 !important;
      text-align: left;
    }

    .price {
      justify-content: center;
      .buy-text {
        font-size: 22px;
        letter-spacing: -0.2px;
        font-weight: 600 !important;
        line-height: 26px;
        margin-bottom: 5px;
      }
      .price-text {
        color: ${colors.colorMain};
        font-size: 22px;
        letter-spacing: -0.2px;
        font-weight: 600 !important;
      }
    }
  }

  .image-container {
    .wrapper {
      width: 80%;
      height: 80%;
      margin: auto;
      margin-right: 0;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid ${colors.accent};
    }
  }

  .closing-date {
    display: flex;
    font-size: 0.725rem;
    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .add-to-cart-mobile {
    .cart-section {
      width: 90%;

      .btn-container {
        .add-btn {
          border-radius: 10px 0 0 10px;
        }
      }
    }
  }
`;

const StyledAddToCartBtn = styled(AddToCartButton)`
  .mini {
    padding: 0.5rem 0 !important;
  }
`;

const StyledMobileIcon = styled(Icon)<{ $isActive: boolean }>`
  width: auto;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  border-radius: 0 10px 10px 0;
  border: 1px solid ${colors.accent};
`;

export const ProductCardLarge = (props: {
  item: Campaign;
  isWishlisted: boolean;
  numberOfUnitsInCart: number;
  onAddToCart: (id: number, numUnits: number) => void;
  onRemoveFromCart: (id: number) => void;
  onAddToWishList: (id: number) => void;
  onItemDetails: (id: number) => void;
}) => {
  const { item } = props;
  const campaignTitle = useTranslatedLabel(item, "campaignTitle");
  const campaignDescription = useTranslatedLabel(item, "campaignDescription");

  const countdown = useCountDown(props.item.closingDate);
  const timeRemaining = {
    days: getPeriodInTwoDigits(countdown.days),
    hours: getPeriodInTwoDigits(countdown.hours),
    minutes: getPeriodInTwoDigits(countdown.minutes),
    seconds: getPeriodInTwoDigits(countdown.seconds),
  };
  const { t } = useTranslation();

  const copyLinkToClipboard = () => {
    const url = `${process.env.REACT_APP_HOSTED_URL}/campaign/${props.item.id}`;
    navigator.clipboard.writeText(url);
    toast.success("Share link copied to clipboard");
  };

  return (
    <ProductCardWrapper className="p-4">
      {item.type === "2" ? (
        <CardTimeRemainingWrapper className="d-flex d-md-none justify-content-center pt-3 pb-2">
          <h1 className="mobile-timer">
            Closing in{" "}
            <b>
              {timeRemaining.days} : {timeRemaining.hours} :{" "}
              {timeRemaining.minutes} : {timeRemaining.seconds}
            </b>
          </h1>
        </CardTimeRemainingWrapper>
      ) : null}

      <ProductImageWraper className="me-5">
        <FullWidthImage
          src={`${process.env.REACT_APP_API_URL}/image/${item.campaignImage}`}
        ></FullWidthImage>
        <div className="image-overlay d-flex d-md-none ps-3">
          <div className="buy-container d-flex">
            <h1 className="buy">Win</h1>
          </div>
          <h1 className="product-name">{campaignTitle}</h1>
        </div>
      </ProductImageWraper>

      <ProductDetailsWraper className="deal-centerBody d-none d-md-flex">
        <BuyText className="d-none d-md-flex"></BuyText>
        <h2 className="product-name">{campaignTitle}</h2>
        <h4>{campaignDescription}</h4>
        <h4 className="price">{getPriceFromNumber(item.total)}</h4>
        <div className="actions">
          {!props.numberOfUnitsInCart ? (
            <div className="item-details-container me-3">
              <StyledButton
                border={`1px solid ${colors.black}`}
                borderRadius="10px"
                className="py-2 px-4"
                onClick={() => props.onItemDetails(item.id)}
              >
                {t(Translations.ItemDetails)}
              </StyledButton>
            </div>
          ) : null}

          <div className="add-to-cart-container me-3">
            <AddToCartButton
              count={props.numberOfUnitsInCart}
              onRemoveFromCart={() => props.onRemoveFromCart(item.id)}
              onAddToCart={() => props.onAddToCart(item.id, 1)}
            ></AddToCartButton>
          </div>
          <div className="closing-info me-3 px-3">
            <div className="info-container">
              <div className="title">{t(Translations.MaxClosingDate)}</div>
              <div className="date">
                {DateTime.fromISO(item.closingDate).toLocaleString(
                  DateTime.DATE_HUGE
                )}
              </div>
            </div>
          </div>
        </div>
      </ProductDetailsWraper>

      <ProductActionsAndStatus className="d-none d-md-flex">
        {/* 1 Item limit , 2 time limit */}
        {item.type === "1" ? (
          <ProgressRound
            total={item.totalItemNumber || 0}
            sold={item.soldItemNumber || 0}
          ></ProgressRound>
        ) : (
          <div className="closing-time">
            <div className="title-deal">{t(Translations.DealClosingIn)}</div>
            <div className="time">
              <div className="section">
                <div className="title">Days</div>
                <div className="value">{timeRemaining.days}</div>
              </div>
              <div className="section">
                <div className="title">Hours</div>
                <div className="value">{timeRemaining.hours}</div>
              </div>
              <div className="section">
                <div className="title">Minutes</div>
                <div className="value">{timeRemaining.minutes}</div>
              </div>
              <div className="section">
                <div className="title">Seconds</div>
                <div className="value last">{timeRemaining.seconds}</div>
              </div>
            </div>
          </div>
        )}

        <div className="actions">
          <div
            className="add-to-wishlist"
            title="Add to Wishlist"
            onClick={() => props.onAddToWishList(props.item.id)}
          >
            <Icon isActive={!!props.isWishlisted}>
              <Heart></Heart>
            </Icon>
          </div>
          <div
            className="share action"
            title="Share"
            onClick={copyLinkToClipboard}
          >
            <Share />
          </div>
        </div>
      </ProductActionsAndStatus>

      <StyledProductDetailsMobile className="d-block d-md-none px-3">
        <div className="product-wrapper d-flex mt-2">
          <div className="price d-flex flex-column">
            <div className="fw-bold buy-text">Buy: {campaignTitle}</div>
            <div className="price-text fw-bold">
              {getPriceFromNumber(item.total)}
            </div>

            {item.type === "1" ? (
              <ProgressRound
                total={item.totalItemNumber || 0}
                sold={item.soldItemNumber || 0}
              ></ProgressRound>
            ) : null}
          </div>
          <div className="image-container d-flex">
            <div className="wrapper">
              <FullWidthImage
                src={`${process.env.REACT_APP_API_URL}/image/${props.item.product.imageName}`}
              ></FullWidthImage>
            </div>
          </div>
        </div>
        <div className="d-flex add-to-cart-mobile">
          <div className="row mt-2 justify-content-between cart-section">
            <StyledAddToCartBtn
              count={props.numberOfUnitsInCart}
              onAddToCart={() => props.onAddToCart(props.item.id, 1)}
              onRemoveFromCart={() => props.onRemoveFromCart(props.item.id)}
            ></StyledAddToCartBtn>
          </div>
          <div
            className="mt-2 flex-grow-1 wishlist-section"
            title="Add to Wishlist"
            onClick={(event) => {
              props.onAddToWishList(props.item.id);
              event.stopPropagation();
            }}
          >
            <StyledMobileIcon isActive={!!props.isWishlisted}>
              <Heart></Heart>
            </StyledMobileIcon>
          </div>
        </div>
        <div className="closing-date my-3">
          <div className="icon">
            <div className="image-container me-2">
              <FullWidthImage src={calendar}></FullWidthImage>
            </div>
          </div>
          <div className="title me-2 fw-bold">
            {t(Translations.MaxClosingDate)}:
          </div>
          <div className="date">
            {DateTime.fromISO(item.closingDate).toLocaleString(
              DateTime.DATE_HUGE
            )}
          </div>
        </div>
      </StyledProductDetailsMobile>
    </ProductCardWrapper>
  );
};
